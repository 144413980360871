import ApiService, { apiServiceSignUp } from "../../services/_apiservices";
import { toastError, toastErrors } from "../../components/ui-elements/_Toastify";
// import {
//   loadingFailedAction,
//   loadingSuccessAction
// } from "../../store/actions/_commonActions";

export const checkExpirationOfToken = (code) => {
  if (code === 401) return true;
  else return false;
};

export const handleGetuserFromApi = async (url, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await ApiService.get(url);
    } else {
      // dispatch(loadingSuccessAction());
      res = await ApiService.get(url);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};
export const handleGetDataFromApi = async (url, id, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await ApiService.get(url);
    } else {
      // dispatch(loadingSuccessAction());
      res = await ApiService.get(url);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};
export const handleGetDataFromQueryParamsApi = async (url, queryParams, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await ApiService.get(url, queryParams);
    } else {
      // dispatch(loadingSuccessAction());
      res = await ApiService.get(url);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handleGetDataFromQuerySeachParamsApi = async (url, querry, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await ApiService.get(url, { search: querry });
    } else {
      // dispatch(loadingSuccessAction());
      res = await ApiService.get(url);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handleGetDataForFile = async (url, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await ApiService.get(url);
    } else {
      // dispatch(loadingSuccessAction());
      res = await ApiService.get(url);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handlePostDataFromApi = async (url, body, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await ApiService.post(url, body);
    } else {
      // dispatch(loadingSuccessAction());
      res = await ApiService.post(url, body);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handlePostDataFromApiLogin = async (url, body, header = { "Content-Type": "application/json" }, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await apiServiceSignUp.post(url, body, header);
    } else {
      // dispatch(loadingSuccessAction());
      res = await apiServiceSignUp.post(url, body, header);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handlePostDataWithTokenFromApi = async (url, body, header, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await ApiService.post(url, body, header);
    } else {
      // dispatch(loadingSuccessAction());
      res = await ApiService.post(url, body, header);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handleSignUpAPI = async (url, body, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await apiServiceSignUp.post(url, body);
    } else {
      // dispatch(loadingSuccessAction());
      res = await apiServiceSignUp.post(url, body);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};
export const handlePutAPIwithout_token = async (url, body, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await apiServiceSignUp.put(url, body);
    } else {
      // dispatch(loadingSuccessAction());
      res = await apiServiceSignUp.put(url, body);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};
export const handlePostAPIwithout_token = async (url, body, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await apiServiceSignUp.post(url, body);
    } else {
      // dispatch(loadingSuccessAction());
      res = await apiServiceSignUp.post(url, body);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};
export const handleGetDataWithoutAuth = async (url, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await apiServiceSignUp.get(url);
    } else {
      // dispatch(loadingSuccessAction());
      res = await apiServiceSignUp.get(url);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handlePutDataFromApi = async (url, body, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await ApiService.put(url, body);
    } else {
      // dispatch(loadingSuccessAction());
      res = await ApiService.put(url, body);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handlePatchDataFromApi = async (url, body, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await ApiService.patch(url, body);
    } else {
      // dispatch(loadingSuccessAction());
      res = await ApiService.patch(url, body);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }
  return res;
};
export const handleDeleteDataFromApi = async (url, dispatch) => {
  let res;
  try {
    if (!dispatch) {
      res = await ApiService.delete(url);
    } else {
      // dispatch(loadingSuccessAction());
      res = await ApiService.delete(url);
      // dispatch(loadingFailedAction());
    }
  } catch (err) {
    console.log(err);
  }

  return res;
};

export const handleAPIErrors = (res) => {
  try {
    const { hints, reason } = res;

    if (hints && Object.keys(hints)?.length) {
      toastErrors(hints);
    } else if (reason && reason?.length) {
      toastError(reason);
    } else {
      toastError('INTERNAL_SERVER_ERROR');
    }
  } catch (error) {
    toastError('INTERNAL_SERVER_ERROR');
    console.log(error);
  }
};
