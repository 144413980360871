import axios from 'axios';
import siteConfig from '@/services/_siteConfig';

import { ENUM_API_STATUS } from '@/utils/_gConstant';
import { objectToQueryString } from '@/pages/Admin/_request';

import {
  handleDeleteDataFromApi,
  handleGetDataFromApi,
  handlePatchDataFromApi,
  handlePostDataFromApi,
} from '@/utils/_gFunctions/_handleAPI';

import { toastErrors, toastSuccess } from '@/components/ui-elements/_Toastify'; // Alphabetized

export const dashboardDataGetRequest = async () => {
  try {
    const res = await handleGetDataFromApi(siteConfig?.DISTRIBUTION_PARTNER_DASHBOARD);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in fetching Dashboard details:', error);
  }
};

export const newsAPIgetRequest = async () => {
  try {
    const res = await handleGetDataFromApi(siteConfig?.NEWS_API);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in fetching news:', error);
  }
};

export const getDPuserRequest = async (queryParams, isLoading) => {
  const url = `${siteConfig.DISTRIBUTION_PARTNER_USERS}?${objectToQueryString(queryParams)}`;
  try {
    const res = await handleGetDataFromApi(url, isLoading);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.log('Error in fetching DP users ', error);
  }
};

export const postDPuserRequest = async (body) => {
  try {
    const res = await handlePostDataFromApi(siteConfig?.DISTRIBUTION_PARTNER_USERS, body);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_POST_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.log('Error in fetching DP users ', error);
  }
};
export const patchDPuserRequest = async (id, body) => {
  try {
    const res = await handlePatchDataFromApi(
      siteConfig?.DISTRIBUTION_PARTNER_USERS + `${id}/`,
      body
    );
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_PATCH_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.log('Error in updating DP users ', error);
  }
};

export const deleteDPuserRequest = async (id) => {
  try {
    const res = await handleDeleteDataFromApi(`${siteConfig?.DISTRIBUTION_PARTNER_USERS}${id}/`);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_DELETE_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.log('Error in deleting the dp user', error);
  }
};

export const handleChangeStatus = async (id) => {
  try {
    const res = await handlePatchDataFromApi(
      `${siteConfig?.DISTRIBUTION_PARTNER_USERS}${id}/change-status/`
    );
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_PATCH_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.log('Error in changing the status', error);
  }
};

export const getDPLeadsRequest = async (queryParams) => {
  const url = `${siteConfig.DISTRIBUTION_PARTNER_LEADS}?${objectToQueryString(queryParams)}`;
  try {
    const res = await handleGetDataFromApi(url);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.log('Error in fetching the leads of DP', error);
  }
};

export const getServicesRequest = async (clientType) => {
  try {
    const res = await axios.get(
      `${siteConfig.BASE_URL + siteConfig?.SERVICES}?category=${clientType}`,
      {
        headers: { 'Accept-Language': 'de' },
      }
    );
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in fetching tags:', error);
  }
};

export const createOrderPostRequest = async (data) => {
  try {
    const res = await handlePostDataFromApi(siteConfig.CREATE_ORDER, data);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_POST_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in creating user');
  }
};

export const getOrderDetailsRequest = async (id) => {
  try {
    const res = await handleGetDataFromApi(`${siteConfig?.GET_ORDER_DETAIL}${id}/`);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in fetching order details:', error);
  }
};

export const getIndividualDpUserData = async (id) => {
  try {
    const res = await handleGetDataFromApi(
      `${siteConfig?.DISTRIBUTION_PARTNER_USERS}${id}/dashboard/`
    );
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in fetching individual DP user data:', error);
  }
};

export const getValidatePriceRequest = async (value, category) => {
  try {
    const res = await handleGetDataFromApi(
      `${siteConfig?.GET_VALIDATE_PRICE}?residential_units=${value}&category=${category}`
    );
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in validating price', error);
  }
};

export const cancelOrderRequest = async (orderId) => {
  try {
    const res = await handleDeleteDataFromApi(`${siteConfig?.CREATE_ORDER}${orderId}/`);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in cancelling order', error);
  }
};

export const calculatePricePostRequest = async (body) => {
  try {
    const res = await handlePostDataFromApi(siteConfig?.POST_CALCULATE_PRICE, body);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.log('Error in fetching DP users ', error);
  }
};

export const getCountriesListRequest = async (language) => {
  try {
    const res = await handleGetDataFromApi(`${siteConfig?.GET_COUNTRIES}?lang=${language}`);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in validating price', error);
  }
};

export const getCitiesListRequest = async (language, countryId) => {
  try {
    const res = await handleGetDataFromApi(
      `${siteConfig?.GET_CITIES}?lang=${language}&country_id=${countryId}`
    );
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in validating price', error);
  }
};

export const referralUserCalc = async () => {
  try {
    const res = await handleGetDataFromApi(siteConfig?.USER_REFERRAL_CALC);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in fetching Referral Calulation:', error);
  }
};

export const referralUserList = async () => {
  try {
    const res = await handleGetDataFromApi(siteConfig?.USER_REFERRAL_LIST);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      // toastErrors(res?.data);
      console.log(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in fetching Referral List:', error);
  }
};

export const deleteReferralUser = async (id) => {
  try {
    const url = `${siteConfig?.USER_REFERRAL_DELETE}${id}`;
    const res = await handleDeleteDataFromApi(url); // Assuming this is your DELETE helper function
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_DELETE_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in deleting Referral User:', error);
    throw error; // Optional: Rethrow the error if you want it to be handled elsewhere
  }
};

export const inviteReferralUser = async (data) => {
  try {
    const res = await handlePostDataFromApi(siteConfig.USER_REFERRAL_INVITATION, data);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_POST_API) {
      toastSuccess(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in inviting user');
  }
};

export const referralUserDetails = async (id) => {
  try {
    const res = await handleGetDataFromApi(`${siteConfig?.USER_REFERRAL_DETAILS}${id}`);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in fetching Referral List:', error);
  }
};