import { NavLink, Outlet} from "react-router-dom";
import { useEffect, useState } from "react";
import BellIcon from "../dashboard/icon/BellIcon";
import ChangePassword from "../auth/component/ChangePassword";
import MyProfile from "../auth/component/MyProfile";
import UserIcon from "../icon/UserIcon";
import logo from "../../assets/img/ikosia-logo.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import user from "../../assets/icons/user.svg";

import { getListNotificationread, getnotifications } from "../../pages/Admin/_request";
import {
  toastError,
  toastSuccess,
} from "../ui-elements/_Toastify";
import dayjs from "dayjs";
import { formatDate } from "../../utils/_gFunctions/_handleDateFormat";
import { leadStageColors } from "../../utils/_gConstant";
import loaderd from "../../assets/icons/loading.gif";

const ConsultantHeader = () => {
  // My Profile
  const [myProfileVisible, setMyProfileVisible] = useState(false);
  const [notification, setNotification] = useState([]);
  const [loader,setLoader] = useState(false)
  const toggleMyProfile = () => {
    setMyProfileVisible(!myProfileVisible);
  };
  const dispatch = useDispatch();
  // Change   Password
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const toggleChangePassword = () => {
    setChangePasswordVisible(!changePasswordVisible);
  };
  const navigate = useNavigate();
  const [isProfileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const [isnotificationDropdown, setnotificationDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setProfileDropdownVisible(!isProfileDropdownVisible);
    setnotificationDropdown(false);
  };

  const notificationDropdown = () => {
    setLoader(true)
    getnotifications()
    .then((data) => {
      if (data.data.status) {
        setNotification(data.data.data);
      }
    })
    .catch((err) => {
      console.log("Errrorkof", err);
    }).finally(()=>{
      setLoader(false)
    });
    setnotificationDropdown(!isnotificationDropdown);
    setProfileDropdownVisible(false);
  };

  useEffect(() => {
    getnotifications()
      .then((data) => {
        if (data.data.status) {
          setNotification(data.data.data);
        }
      })
      .catch((err) => {
        console.log("Errrorkof", err);
      });
  }, []);

  useEffect(() => {
    // document.title = props.title;
    const handleOutsideClick = (event) => {

      
      if (!event.target.closest("#profile-container")) {
        setProfileDropdownVisible(false);
      }
      if (!event.target.closest("#notification-container")) {
        setnotificationDropdown(false);
        
      }
    };
    window.addEventListener("mousedown", handleOutsideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handlenotificationread = (it)=>{
    const id = it.lead?.id
    if(it){
      getListNotificationread(it.id).then((data)=>{
         if(data.data.status){
            navigate(`/consultant/leads/details/${id}`)
         }
      }).catch((err)=>{
        console.log("fgfg", err)
      })
    }
  }
  return (
    <>
      <div className="admin-dashboard-wrapper">
        <header className="admin-dashboard__header">
          <div className="">
            <img src={logo} alt="logo" />
          </div>
          <nav className="admin-dashboard__nav">
            <NavLink
              className="admin-dashboard__nav__list"
              to={"/consultant/leads"}
            >
              <div className="admin-dashboard__nav__icon">
                <span className="admin-dashboard__nav__icon__color">
                  <UserIcon light />
                </span>
                <span className="admin-dashboard__nav__icon__default">
                  <UserIcon />
                </span>
              </div>
              <span className="admin-dashboard__nav__title">Leads</span>
            </NavLink>
          </nav>
          <div className="flex items-center">
            <div
              className="relative flex items-center justify-center ml-3 rounded-full cursor-pointer"
              id="notification-container"
              style={{ width: 36, height: 36, border: "1px solid #DFDFDF" }}
              onClick={notificationDropdown}
            >
              <BellIcon />
              <ul
                className={`dropdown-menu notify md w-full ${
                  isnotificationDropdown ? "show" : ""
                }`}
                style={{
                  width: 500,
                  left: "initial",
                  right: "-30px",
                  height: 500,
                  overflow: "auto",
                }}
              >
                {loader ? (<img
                    src={loaderd}
                    alt="loader"
                    style={{
                      width: "30px",
                      height: "30px",
                      margin: "10px",
                      position: 'absolute',
                      /* display: flex; */
                      /* justify-content: center; */
                      /* align-items: center; */
                      top: '45%',
                      left: '45%'
                    }}

                  />) : notification &&
                  notification.length > 0 &&
                  notification.map((it) => (
                    <li
                      key={it.id}
                      className="dropdown-menu__list"
                      style={{
                        borderBottom: "1px solid #E2E2E2",
                        marginBottom: 0,
                      }}
                      onClick={()=>handlenotificationread(it)}
                      >
                      <div
                        className="flex items-center justify-between tags rounded p-2"
                        style={leadStageColors[it.stage]}
                      >
                        <p className="text-md truncate">{it?.lead.full_name}</p>
                        <p className="text-md truncate">
                          {it?.created_at}
                        </p>
                      </div>
                      <p className="font-bold mt-2 px-2 text-secondary text-sm truncate">
                        {it.message}
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
            <div
              className="relative flex items-center justify-center ml-3 rounded-full cursor-pointer"
              id="profile-container"
              style={{ width: 36, height: 36, border: "1px solid #DFDFDF" }}
              onClick={toggleProfileDropdown}
            >
              <img src={user} alt="user-icon" className="" />
              <ul
                className={`dropdown-menu md w-full ${
                  isProfileDropdownVisible ? "show" : ""
                }`}
                style={{ width: 155, left: "initial", right: "-30px" }}
              >
                <li
                  className="dropdown-menu__list"
                  style={{ borderBottom: "1px solid #E2E2E2", marginBottom: 0 }}
                  onClick={toggleMyProfile}
                >
                  My Profile
                </li>
                <li
                  className="dropdown-menu__list"
                  style={{ borderBottom: "1px solid #E2E2E2", marginBottom: 0 }}
                  onClick={toggleChangePassword}
                >
                  Change Password
                </li>
                <li
                  className="dropdown-menu__list"
                  style={{ marginBottom: 0 }}
                  onClick={() => {
                    localStorage.clear();
                    dispatch({ type: "USER_LOGOUT" });
                    toastSuccess("Sie wurden abgemeldet!");
                    navigate("/");
                  }}
                >
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </header>
        <main className="consultant_main" style={{ paddingTop: 88 }}>
          <Outlet />
        </main>
      </div>
      {myProfileVisible && <MyProfile closeModal={toggleMyProfile} />}
      {changePasswordVisible && (
        <ChangePassword closeModal={toggleChangePassword} />
      )}
    </>
  );
};

export default ConsultantHeader;
