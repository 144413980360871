/* eslint-disable sort-imports */
import React, { useEffect, useState } from 'react';
import { ENUM_API_STATUS } from '@/utils/_gConstant';
import { referralUserCalc, referralUserList, deleteReferralUser, inviteReferralUser } from '../store/request';
import { toastSuccess, toastError } from '@/components/ui-elements/_Toastify';
/* eslint-enable sort-imports */


import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

import '../../../assets/styles/scss/pages/dp-dashboard.scss';

import icon01 from '../../../assets/icons/icon01.svg';
import icon02 from '../../../assets/icons/icon02.svg';
import icon03 from '../../../assets/icons/icon03.svg';
import icon04 from '../../../assets/icons/icon04.svg';
import icondrop from '../../../assets/icons/drop.svg';
import stopIcon from '../../../assets/icons/stop-icon.svg';
import user from '../../../assets/img/user.png';

const InfoIcon = ({ width = 16, height = 16, fill = "#778588", ...props }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 7.83333C0 3.5071 3.5071 0 7.83333 0C12.1596 0 15.6667 3.5071 15.6667 7.83333C15.6667 12.1596 12.1596 15.6667 7.83333 15.6667C3.5071 15.6667 0 12.1596 0 7.83333ZM14.6667 7.83333C14.6667 4.05939 11.6073 1 7.83333 1C4.05939 1 1 4.05939 1 7.83333C1 11.6073 4.05939 14.6667 7.83333 14.6667C11.6073 14.6667 14.6667 11.6073 14.6667 7.83333ZM7.83333 6.66667C8.08646 6.66667 8.29566 6.85477 8.32877 7.09882L8.33333 7.16667V11.1667C8.33333 11.4428 8.10948 11.6667 7.83333 11.6667C7.5802 11.6667 7.37101 11.4786 7.3379 11.2345L7.33333 11.1667V7.16667C7.33333 6.89052 7.55719 6.66667 7.83333 6.66667ZM7.83333 5.16667C8.20152 5.16667 8.5 4.86819 8.5 4.5C8.5 4.13181 8.20152 3.83333 7.83333 3.83333C7.46514 3.83333 7.16667 4.13181 7.16667 4.5C7.16667 4.86819 7.46514 5.16667 7.83333 5.16667Z"
            fill={fill}
            style={{ fill }}
        />
    </svg>
);

const DropButton = ({ id, onDelete }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleDelete = async () => {
        try {
            const res = await deleteReferralUser(id); // Call the DELETE API
            if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
                console.log('Delete successful');
                onDelete(id); // Notify parent component to remove the deleted record from the list
            } else {
                console.error('Failed to delete record');
            }
        } catch (error) {
            console.error('Error while deleting record:', error);
        }
    };

    return (
        <div className='drop-btn'>
            <button onClick={toggleDropdown}>
                <img src={icondrop} alt="Dropdown Icon" />
            </button>
            {isOpen && (
                <ul className='dropdown-menu-main'>
                    <li onClick={handleDelete}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 6.17647H20M10 16.7647V10.4118M14 16.7647V10.4118M16 21H8C6.89543 21 6 20.0519 6 18.8824V7.23529C6 6.65052 6.44772 6.17647 7 6.17647H17C17.5523 6.17647 18 6.65052 18 7.23529V18.8824C18 20.0519 17.1046 21 16 21ZM10 6.17647H14C14.5523 6.17647 15 5.70242 15 5.11765V4.05882C15 3.47405 14.5523 3 14 3H10C9.44772 3 9 3.47405 9 4.05882V5.11765C9 5.70242 9.44772 6.17647 10 6.17647Z" stroke="#972121"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Delete
                    </li>
                    {/* <li onClick={() => console.log('Disable link clicked')}><img src={stopIcon} /> Disable link</li> */}
                </ul>
            )}
        </div>
    );
};


const DPReferral = () => {
    console.log("hello");

    const [isLoading, setIsLoading] = useState(true);
    const [calcData, setCalcData] = useState([]);
    const [listData, setListData] = useState([]);

    const [referralCode, setReferralCode] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [validationErrors, setValidationErrors] = useState({});

    const [isPersonalise, setIsPersonalise] = useState(false);

    const togglePersonalise = () => {
        setIsPersonalise(!isPersonalise);
    };

    const validateFields = (fieldName, value, updateFunction, pattern = '') => {
        const errors = { ...validationErrors };
        const requiredFields = {
            email: true,
            firstName: isPersonalise,
            lastName: isPersonalise,
        };

        const required = requiredFields[fieldName];

        if (required && !value.trim()) {
            if(fieldName == 'email'){
                errors[fieldName] = 'Email is required';
            } else if(fieldName == 'firstName'){
                errors[fieldName] = 'First name is required';
            } else if(fieldName == 'lastName'){
                errors[fieldName] = 'Last name is required';
            }
        } else if (pattern && !pattern.test(value)) {
            errors[fieldName] = 'Invalid email format.';
        } else {
            errors[fieldName] = '';
        }
        setValidationErrors(errors);
        updateFunction(value);
    };

    const handleSubmit = async () => {

        // Explicitly validate each field on form submission
        const fieldsToValidate = ['email', ...(isPersonalise ? ['firstName', 'lastName'] : [])];
        const newValidationErrors = {};

        fieldsToValidate.forEach((field) => {
            const value =
                field === 'email'
                    ? email
                    : field === 'firstName'
                        ? firstName
                        : field === 'lastName'
                            ? lastName
                            : '';

            const isFieldRequired = field === 'email' || (isPersonalise && (field === 'firstName' || field === 'lastName'));

            if (isFieldRequired && !value.trim()) {
                newValidationErrors[field] = field + ' is required';
                if(field == 'email'){
                    newValidationErrors[field] = 'Email is required';
                } else if(field == 'firstName'){
                    newValidationErrors[field] = 'First name is required';
                } else if(field == 'lastName'){
                    newValidationErrors[field] = 'Last name is required';
                }
            } else if (field === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                newValidationErrors[field] = 'Invalid email format';
            }
        });

        setValidationErrors(newValidationErrors);

        // If there are any errors, stop submission
        if (Object.keys(newValidationErrors).length > 0) {
            // alert('Please correct the errors before submitting.');
            return;
        }

        const payload = {
            referral_code: referralCode,
            email,
            ...(isPersonalise && { first_name: firstName, last_name: lastName })
        };

        setIsLoading(true);

        try {
            const res = await inviteReferralUser(payload);
            setIsLoading(false);
            if (res?.status === 200) {
                toastSuccess(res?.data.message);
                setEmail('');
                setFirstName('');
                setLastName('');

                handleCalcData();
                handleListData();
            } else {
                toastError(res?.data.message);
            }
        } catch (error) {
            console.error('Error sending referral:', error);
            toastError('Something went wrong. Please try again.');
        }
    };

    const handleCalcData = async () => {
        const res = await referralUserCalc();
        if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
            setCalcData(res?.data.data);
            setReferralCode(res?.data.data.referral_code);
            setIsLoading(false);
        }
    };

    const handleListData = async () => {
        const res = await referralUserList();
        if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
            setListData(res?.data.data);
            setIsLoading(false);
        }
    };

    const handleDelete = (id) => {
        console.log(id);
        setListData((prevList) => prevList.filter((item) => item.id !== id));
    };

    useEffect(() => {
        handleCalcData();
        handleListData();
    }, []);

    return (
        <section className='page-bg'>
            <Header />
            <Sidebar />

            <div className='page-main'>
                <div className='max-container'>
                    <div className='referral-link-main'>
                        <h2>Referral Page</h2>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='referral-link-box'>
                                    <h4>Personal Referral Code 
                                        {/* <InfoIcon width={16} height={16} fill="#778588" /> */}
                                    </h4>
                                    <h2>{referralCode}</h2>
                                    <h6>Refer Ikosia to your clients</h6>
                                    <p>Teilen Sie den untenstehenden Link mit Ihren Kunden oder senden Sie ihn bequem per E-Mail. Ihr Kunde gelangt direkt zum Energie-Check und kann in kürzester Zeit unverbindlich prüfen, ob sich eine Sanierung für ihn lohnt. Durch die Nutzung dieses Links erhalten Ihre Kunden automatisch den bevorzugten Preis.</p>

                                    <div className='form-group-main'>
                                        <div className='form-group'>
                                            <label>Share Your Referral Link</label>
                                            <div className='link-copy'>
                                                <input type='text' value={`${process.env.REACT_APP_REFERRAL_URL}survey/true/0/${referralCode}`} readOnly />
                                                <button
                                                    className="copy-btn"
                                                    onClick={() =>
                                                        navigator.clipboard.writeText(
                                                            `${process.env.REACT_APP_REFERRAL_URL}survey/true/0/${referralCode}`
                                                        )
                                                        .then(() => toastSuccess('Referral link copied to clipboard!'))
                                                        .catch((err) => toastError('Failed to copy referral link'))
                                                    }
                                                >
                                                    Copy
                                                </button>
                                            </div>
                                            {/* <ul>
                                                <li><a href=''><img src={icon01} alt='icon' /></a></li>
                                                <li><a href=''><img src={icon02} alt='icon' /></a></li>
                                                <li><a href=''><img src={icon03} alt='icon' /></a></li>
                                                <li><a href=''><img src={icon04} alt='icon' /></a></li>
                                            </ul> */}
                                        </div>
                                        <div className='or-text'>
                                            <span>OR</span>
                                        </div>
                                        <div className='form-group'>
                                            
                                            <div className="mb-4">
                                                <label className='d-flex justify-content-between w-100'>Share via Email <span onClick={togglePersonalise}>Personalise</span></label>

                                                {isPersonalise && (
                                                <>
                                                    <div className='first-last-name'>
                                                        <div className="mb-4">
                                                            <input
                                                                className={`input-field ${validationErrors.firstName ? 'border-red' : ''}`}
                                                                type="text"
                                                                placeholder="First Name"
                                                                value={firstName}
                                                                onChange={(e) => validateFields('firstName', e.target.value, setFirstName)}
                                                            />
                                                            {validationErrors.firstName && (
                                                                <span className="text-red text-sm">{validationErrors.firstName}</span>
                                                            )}
                                                        </div>
                                                        <div className="mb-4">
                                                            <input
                                                                className={`input-field ${validationErrors.lastName ? 'border-red' : ''}`}
                                                                type="text"
                                                                placeholder="Last Name"
                                                                value={lastName}
                                                                onChange={(e) => validateFields('lastName', e.target.value, setLastName)}
                                                            />
                                                            {validationErrors.lastName && (
                                                                <span className="text-red text-sm">{validationErrors.lastName}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            
                                                <input
                                                    className={`input-field ${validationErrors.email ? 'border-red' : ''}`}
                                                    type="text"
                                                    placeholder="E-mail"
                                                    value={email}
                                                    onChange={(e) =>
                                                        validateFields('email', e.target.value, setEmail, /^[^\s@]+@[^\s@]+\.[^\s@]+$/)
                                                    }
                                                />
                                                {validationErrors.email && (
                                                    <span className="text-red text-sm">{validationErrors.email}</span>
                                                )}
                                            </div>
                                            
                                            <div className='submit-btn'>
                                                <button type='button' onClick={handleSubmit}>Send</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <div className='you-earned-main'>
                                    <div className='you-earned-head'>
                                        <span>You earned</span>
                                        <h5>{calcData?.total_earned ?? 0}€</h5>
                                    </div>
                                    <div className='you-earned-content'>
                                        <div className='you-earned-list'>
                                            <span>
                                                Invited
                                                {/* <InfoIcon width={16} height={16} fill="#778588" /> */}
                                            </span>
                                            <b>{calcData?.total_invited ?? 0}</b>
                                        </div>
                                        <div className='you-earned-list'>
                                            <span>
                                                Waiting for sign up  (1/4)
                                                {/* <InfoIcon width={16} height={16} fill="#778588" /> */}
                                            </span>
                                            <b>{calcData?.total_signup ?? 0}</b>
                                        </div>
                                        <div className='you-earned-list'>
                                            <span>
                                                Energy-Check done  (2/4)
                                                {/* <InfoIcon width={16} height={16} fill="#778588" /> */}
                                            </span>
                                            <b>{calcData?.total_energycheck ?? 0}</b>
                                        </div>
                                        <div className='you-earned-list'>
                                            <span>
                                                Contract signed (3/4)
                                                {/* <InfoIcon width={16} height={16} fill="#778588" /> */}
                                            </span>
                                            <b>{calcData?.total_contractSigned ?? 0}</b>
                                        </div>
                                        <div className='you-earned-list'>
                                            <span>
                                                Invoice payed (4/4)
                                                {/* <InfoIcon width={16} height={16} fill="#778588" /> */}
                                            </span>
                                            <b>{calcData?.total_invoicePayed ?? 0}</b>
                                        </div>
                                        <div className='you-earned-list'>
                                            <span>
                                                Potential voucher value
                                                {/* <InfoIcon width={16} height={16} fill="#778588" /> */}
                                            </span>
                                            <b>EUR {calcData?.total_voucherValue ?? 0}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='sent-links'>
                        <h2>Sent links status</h2>
                        <div className='h-full pb-8 overflow-y-auto'>
                            <table className='table-styles'>
                                <thead>
                                    <tr>
                                        {/* <td></td> */}
                                        <td>name</td>
                                        <td>status</td>
                                        <td>email</td>
                                        <td>Date</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listData.length > 0 ? (
                                        listData.map((item) => (
                                            <tr key={item.id}>
                                                {/* <td>
                                                    <div className="profile-icon">
                                                        <img src={item.image ?? user} alt="User Icon" />
                                                    </div>
                                                </td> */}
                                                <td>{item.full_name || 'N/A'}</td>
                                                <td>{item.kind === 2 ? 'Active' : 'Pending'}</td>
                                                <td>{item.email}</td>
                                                <td>{new Date(item.created_at).toLocaleDateString()}</td>
                                                <td>
                                                    <DropButton id={item.id} onDelete={handleDelete} />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DPReferral;
