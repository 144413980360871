import '../../assets/styles/scss/pages/home-owner.scss';

import { QuesstionneirListPostRequest, getQuestionneirList } from './_request';
import React, { useEffect, useState } from 'react';
import { toastError, toastErrors } from '../../components/ui-elements/_Toastify';
import { updateLeadId, updateUserAnswers } from './state/_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ENUM_API_STATUS } from '../../utils/_gConstant';
import QuestionnaireSection from './QuestionnaireSection';
import backBtn from '../../assets/icons/circle-with-back-arrow.svg';
import clock from '../../assets/icons/clock.svg';
import homeOwnerBanner from '../../assets/img/home-owner-banner.svg';
import logo from '../../assets/img/ikosia-logo.svg';

const HomeOwner = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { leadId, questionIndex, referralCode, referralId } = useParams();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({ id: '', value: '' });
  const [questionsData, setQuestionsData] = useState([]);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [reachedLastQuestion, setReachedLastQuestion] = useState(false);
  const [showQna, setShowQna] = useState(true);
  const [currentQuestionSubIndex, setCurrentQuestionSubIndex] = useState(
    parseInt(questionIndex) || 0
  );
  const responseLeadId = useSelector((state) => state?.userAnswersReducer?.leadId);

  // console.log(referralCode);

  useEffect(() => {
    document.title = props.title;
    const fetchData = async () => {
      const res = await getQuestionneirList();
      if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
        setQuestionsData(res?.data);
      }
    };
    fetchData();
    if (leadId !== 'true') {
      dispatch(updateLeadId(leadId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.title, getQuestionneirList, setQuestionsData, leadId, dispatch]);

  useEffect(() => {
    localStorage.setItem('reachedLastQuestion', reachedLastQuestion);
  }, [reachedLastQuestion]);

  const handleSelectedOptionChange = (optionId) => {
    setSelectedOptionId(optionId);
  };

  const nextIndex = currentQuestionSubIndex + 1;

  const handleNext = async () => {
    const currentCategory = questionsData[currentQuestionIndex];
    const currentQuestion = currentCategory?.questions[currentQuestionSubIndex];

    if (currentQuestion?.id) {
      const answerToSend = userAnswers?.value;
      const answerToSendafterconvert = answerToSend?.replace(/,/g, '.');

      // const options = currentCategory?.questions[currentQuestionSubIndex]?.option || [];

      try {
        let res;
        if (currentQuestion?.option[0]?.kind === 1) {
          const optionId = currentQuestion?.option[0]?.id;
          if (leadId === 'true' && currentQuestionSubIndex === 0) {
            res = await QuesstionneirListPostRequest({
              question: currentQuestion.id,
              option_value: answerToSendafterconvert,
              option: optionId,
            });
            dispatch(updateLeadId(res?.data?.lead));
          } else {
            res = await QuesstionneirListPostRequest({
              lead: responseLeadId,
              question: currentQuestion.id,
              option_value: answerToSendafterconvert,
              option: optionId,
            });
          }

          dispatch(updateUserAnswers(currentQuestion?.id, answerToSend));
        } else if (currentQuestion?.option[0]?.kind === 2) {
          res = await QuesstionneirListPostRequest({
            lead: responseLeadId,
            question: currentQuestion.id,
            option: selectedOptionId,
          });
          dispatch(updateUserAnswers(currentQuestion.id, selectedOptionId));
        }

        setUserAnswers({ id: '', value: '' });
        setShowQna(true);

        // Check the response status after the API call
        if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API) {
          if(referralCode){
            if(referralId){
              navigate(`/survey/${leadId}/${nextIndex}/${referralCode}/${referralId}`);
            }else{
              navigate(`/survey/${leadId}/${nextIndex}/${referralCode}`);
            }
          }else{
            navigate(`/survey/${leadId}/${nextIndex}`);
          }
          if (
            currentCategory?.questions?.length > 0 &&
            nextIndex < currentCategory?.questions?.length
          ) {
            setCurrentQuestionSubIndex(nextIndex);
            setShowQna(true);
          } else {
            const nextCategoryIndex = questionsData?.findIndex(
              (category, index) => index > currentQuestionIndex && category?.questions?.length > 0
            );

            if (nextCategoryIndex !== -1) {
              setCurrentQuestionIndex(nextCategoryIndex);
              setCurrentQuestionSubIndex(0);
            } else {
              if (leadId === 'true') {
                setReachedLastQuestion(true);
              } else {
                setReachedLastQuestion(false);
                dispatch({ type: 'RESET_USER_ANSWERS' });
                navigate(`/homeowner/${responseLeadId}`);
              }
              setCurrentQuestionSubIndex(nextIndex);
            }
          }
        } else {
          if (res?.status === ENUM_API_STATUS?.ERROR_400_BAD_REQ) {
            toastErrors(res?.data);
          } else {
            toastError('Etwas ist schief gelaufen');
          }
        }
      } catch (error) {
        toastErrors(error);
      }
    }
  };

  const handleBack = () => {
    const previousIndex = currentQuestionSubIndex - 1;

    if (previousIndex >= 0) {
      setCurrentQuestionSubIndex(previousIndex);
      setReachedLastQuestion(false);
      setShowQna(true);
    } else {
      const previousCategoryIndex = questionsData?.findLastIndex(
        (category, index) => index < currentQuestionIndex && category?.questions?.length > 0
      );

      if (previousCategoryIndex !== -1) {
        const previousCategory = questionsData[previousCategoryIndex];
        const previousSubIndex = previousCategory?.questions?.length - 1;
        setCurrentQuestionIndex(previousCategoryIndex);
        setCurrentQuestionSubIndex(previousSubIndex);
      }
    }
    navigate(-1);
  };

  return (
    <section className='home-owner-module'>
      <div className='home-owner__container'>
        <main className='home-owner__main'>
          <div>
            <div className='flex items-center'>
              <img src={logo} alt='ikosia-logo' style={{ height: 32 }} />
            </div>
            <div className='flex items-center mt-8'>
              {currentQuestionSubIndex > 0 && (
                <div
                  className='flex items-center flex-shrink-0 mr-2 cursor-pointer fade-in'
                  onClick={() => {
                    setShowQna(false);
                    handleBack();
                  }}
                >
                  <img src={backBtn} alt='back-btn' />
                </div>
              )}
              <div
                className='inline-flex items-center bg-tertiary-brand py-2 pl-4 pr-10 transition-basic'
                style={{
                  borderRadius: 52,
                  boxShadow: '0px 0px 20px rgba(197, 195, 188, 0.1)',
                }}
              >
                <img src={clock} alt='clock' />
                <span className='text-15 font-medium leading4 ml-3'>
                  Es dauert nur 2 Minuten, diese Umfrage abzuschließen
                </span>
              </div>
            </div>
            <div className='mt-10'>
              {leadId === 'true' ? (
                <div className='split-progress-bar'>
                  {[...Array(questionsData[0]?.questions?.length + 1 || 0)].map((_, index) => (
                    <div
                      key={index}
                      className={`split-progress-bar__list ${
                        index < currentQuestionSubIndex + 1 ? 'active' : ''
                      }`}
                    ></div>
                  ))}
                </div>
              ) : (
                <div className='split-progress-bar'>
                  {[...Array(questionsData[0]?.questions?.length || 0)].map((_, index) => (
                    <div
                      key={index}
                      className={`split-progress-bar__list ${
                        index < currentQuestionSubIndex + 1 ? 'active' : ''
                      }`}
                    ></div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div>
            <QuestionnaireSection
              currentQuestionIndex={currentQuestionIndex}
              currentQuestionSubIndex={currentQuestionSubIndex}
              setUserAnswers={setUserAnswers}
              userAnswers={userAnswers}
              questionsData={questionsData}
              handleNext={handleNext}
              leadId={leadId}
              setSelectedOptionId={setSelectedOptionId}
              onSelectedOptionChange={handleSelectedOptionChange}
              reachedLastQuestion={reachedLastQuestion}
              showQna={showQna}
              setShowQna={setShowQna}
              responseLeadId={responseLeadId}
              referralCode={referralCode}
              referralId={referralId}
            />
          </div>
        </main>
        <div className='home-owner__banner'>
          <div className='w-full h-full rounded-2xl'>
            <img
              src={homeOwnerBanner}
              alt='banner'
              className='w-full h-full object-cover rounded-2xl'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeOwner;
