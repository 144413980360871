import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useCallback, useEffect, useState } from 'react';

import ConsultantAddEditLead from '../../components/consultant/ConsultantAddEditLead';
import ConsultantLeadinfo from '../../components/consultant/ConsultantLeadinfo';
import Document from '../Admin/Document';
import FileIcon from '../../components/dashboard/icon/FileIcon';
import FormAnswers from '../Admin/FormAnswers';
import { LeadListViewGetRequest } from '../Admin/_request';
import arrowLeft from '../../assets/icons/arrow-left.svg';
import close from '../../assets/icons/close.svg';
import editWhite from '../../assets/icons/edit-white.svg';
import emailIcon from '../../assets/icons/email-white.svg';
import { formatDate } from '../../utils/_gFunctions/_handleDateFormat';
import { getListDetailsGetRequest } from '../Admin/_request';
import phoneIcon from '../../assets/icons/phone-white.svg';

import { toastError, toastSuccess } from '../../components/ui-elements/_Toastify';
import { ENUM_API_STATUS } from '../../utils/_gConstant';
import { stageChangeapi } from '../Admin/_request';

import { buildingTypeOptions, leadStageOptions } from '../../utils/_gConstant';
import PageLoader from '../../components/dashboard/PageLoader';

const ConsultantDetails = () => {
  const [activeTab, setActiveTab] = useState('Lead Info');
  const [editModal, setEditModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedStages, setSelectedStages] = useState({});
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fileName, setFileName] = useState('');
  // const { state } = location;
  // const leadId = state?.id;
  const leadId = useParams()?.leadId;

  const [leadData, setLeadData] = useState();
  const [loader, setLoader] = useState(false);
  console.log('fgfgkfgkfgkfgfgkg', loader);
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const handleEditLeadModal = (data) => {
    setEditModal(data);
  };

  const getleads = useCallback((page, reset = false, searchd) => {
    // setLoader(true)
    if (page !== null) {
      LeadListViewGetRequest({ page, page_size: 20, search: searchd }, true)
        .then((res) => {
          if (res?.status === ENUM_API_STATUS.SUCCESS_GET_API) {
            const updatedResults = res.data?.results;
            const newData = updatedResults.find((it, index) => it.id == leadId);
            setLeadData(newData);
          }
        })
        .catch((err) => {
          console.log('Erredreir', err);
        });
    }
  }, []);

  const handleStageChange = useCallback(
    async (item, newStage) => {
      if (newStage == 5) {
        setShow(item);
      } else {
        setLoader(true);
        const data = {
          lead_id: item?.id,
          stage: newStage,
        };
        stageChangeapi(data)
          .then((res) => {
            if (res.status == ENUM_API_STATUS.SUCESS_POST_API_200) {
              toastSuccess(res.data.message);
              // setSelectedStages((prev) => ({ ...prev, [item.enq_id]: newStage }));
              setLeadData(prev=>({...prev,stages:newStage}));
              getleads(1, true, '');
            } else {
              toastError(res.data);
            }
          })
          .catch((err) => {
            toastError(err);
          })
          .finally(() => {
            setLoader(false);
            setLeadData(prev=>({...prev,stages:newStage}));
          });
      }
    },
    [selectedStages]
  );

  useEffect(() => {
    getleads(1, true, '');
  }, []);

  const getLeadData = async () => {
    setLoader(true);

    const res = await getListDetailsGetRequest(leadId);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) setLeadData(res?.data);
    setLoader(false);
  };

  useEffect(() => {
    if (leadId) {
      getLeadData();
    }
  }, [leadId]);

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size;

      // Check if the file is an image
      if (fileType.startsWith('image/')) {
        setErrorMessage('Images are not allowed. Please upload other file types.');
        setFileName('');
        return;
      }

      // Check if the file size is not more than 10MB
      if (fileSize > 10 * 1024 * 1024) {
        setErrorMessage('File size should not exceed 10MB.');
        setFileName('');
        return;
      }

      // If both checks pass, reset error message and set the file name
      setErrorMessage('');
      setFileName(file);
    }
  };

  const handleupload = () => {
    // setLoader(true)
    if (!fileName) {
      setErrorMessage('Please upload a file.');
      return;
    }
    setLoader(true);
    const formdata = new FormData();
    formdata.append('lead_id', show?.id);
    formdata.append('stage', 5);
    formdata.append('isfp_document', fileName);
    stageChangeapi(formdata)
      .then((res) => {
        if (res.status == ENUM_API_STATUS.SUCESS_POST_API_200) {
          toastSuccess(res.data.message);
          getleads(1, true, '');
          setShow(false);
          setFileName('');
          setErrorMessage('');
        } else {
          toastError(res.data);
        }
      })
      .catch((err) => {
        setShow(false);
        toastError(err);
      })
      .finally(() => {
        setLoader(false);
        setShow(false);
      });
  };
  const handleClose = () => {
    setShow(false);
    setFileName('');
    setErrorMessage('');
  };

  const handleShowlodaer = () => {
    setLoader(true);
  };

  const handleCloseModal = () => {
    setEditModal(false);
  };
  return (
    <>
      <div className='admin-dashboard-wrapper'>
        <div className='admin-dashboard__main pb-8 px-10'>
          <div className='flex items-center'>
            <div className='breadcrumb__back-icon cursor-pointer' onClick={() => navigate(-1)}>
              <img src={arrowLeft} alt='arrow-left' />
            </div>
            <div className='breadcrumb'>
              <div className='breadcrumb__list cursor-pointer' onClick={() => navigate(-1)}>
                Leads
              </div>
              <div className='breadcrumb__list'>{leadData?.full_name}</div>
            </div>
          </div>
          <div className='lead_main_box flex -mx-4 mt-4'>
            <div className='lead-brief-card px-4'>
              <div className='lead-brief-card__header'>
                <div className='flex items-center justify-between'>
                  <h1 className='text-36 font-semibold' style={{ color: '#F2F0E4' }}>
                    {leadData?.full_name}
                  </h1>
                  <div
                    className='flex items-center justify-center cursor-pointer'
                    onClick={() => handleEditLeadModal(leadData)}
                  >
                    <img src={editWhite} alt='edit-white' />
                  </div>
                </div>
                <p className='text-white opacity8 mt-2'>
                  {
                    buildingTypeOptions?.find((option) => option?.value === leadData?.building_type)
                      ?.label
                  }
                </p>
                <select
                  className='tags m-2 p-1 rounded'
                  value={selectedStages[leadData?.enq_id] || leadData?.stages}
                  onChange={(e) => handleStageChange(leadData, e.target.value)}
                  style={{ backgroundColor: '#F2F0E4', borderColor: 'black' }}
                >
                  {leadStageOptions?.map((option) => (
                    <option key={option?.value} value={option?.value}>
                      {option?.label}
                    </option>
                  ))}
                </select>
                <div className='mt-4'>
                  <div className='flex items-center'>
                    <div className='flex items-center flex-shrink-0 w-5 h-5'>
                      <img src={emailIcon} alt='email' />
                    </div>
                    <span className='secondary-font text-white opacity7 ml-4'>
                      {leadData?.email?.length > 30
                        ? `${leadData?.email.slice(0, 30)}...`
                        : leadData?.email}
                    </span>
                  </div>
                  <div className='flex items-center mt-3'>
                    <div className='flex items-center flex-shrink-0 w-5 h-5'>
                      <img src={phoneIcon} alt='email' />
                    </div>
                    <span className='secondary-font text-white opacity7 ml-4'>
                      {leadData?.mobile_number}
                    </span>
                  </div>
                </div>
              </div>
              <div className='lead-brief-card__body'>
                <div className='pb-4' style={{ borderBottom: '1px solid #d1d5db' }}>
                  <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                    <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                      Enquiry ID
                    </label>
                    <span className='col-6 px-4 secondary-font font-semibold text-right'>
                      {leadData?.enq_id}
                    </span>
                  </div>
                  <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                    <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                      Enquiry Date
                    </label>
                    <span className='col-6 px-4 secondary-font font-semibold text-right'>
                      {formatDate(leadData?.enq_date)}
                    </span>
                  </div>
                  <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                    <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                      Consultant name
                    </label>
                    <span className='col-6 px-4 secondary-font font-semibold text-right'>
                      {leadData?.consultant_assigned?.first_name || 'Not Assigned'}
                    </span>
                  </div>
                  <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                    <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                      Country
                    </label>
                    <span className='col-6 px-4 secondary-font font-semibold text-right'>
                      {leadData?.country}
                    </span>
                  </div>
                  <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                    <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                      City
                    </label>
                    <span className='col-6 px-4 secondary-font font-semibold text-right'>
                      {leadData?.city}
                    </span>
                  </div>
                  <div className='flex items-center justify-between flex-wrap -mx-4 mb-2 mb-not-last-child'>
                    <label className='col-6 px-4 secondary-font text-secondary text-sm font-medium'>
                      Postcode
                    </label>
                    <span className='col-6 px-4 secondary-font font-semibold text-right'>
                      {leadData?.postal_code}
                    </span>
                  </div>
                </div>
                <div className='tags mt-3'>
                  {leadData?.tags?.map((tag) => (
                    <div className='tags__list'>
                      {tag.length > 30 ? `${tag.slice(0, 30)}...` : tag}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {loader ? (
              <PageLoader />
            ) : (
              <div className='px-4 w-full'>
                <div className='lead-main-info px-4'>
                  <div className='tab-btn-wrapper'>
                    <input
                      type='radio'
                      id='tabBtn1'
                      className='tab-btn__input'
                      name='tab-btn'
                      checked={activeTab === 'Lead Info'}
                      onChange={() => handleTabChange('Lead Info')}
                    />
                    <label htmlFor='tabBtn1' className='tab-btn'>
                      Lead Info
                    </label>
                    <input
                      type='radio'
                      id='tabBtn2'
                      className='tab-btn__input'
                      name='tab-btn'
                      checked={activeTab === 'Form Answers'}
                      onChange={() => handleTabChange('Form Answers')}
                    />
                    <label htmlFor='tabBtn2' className='tab-btn'>
                      Form Answers
                    </label>
                    <input
                      type='radio'
                      id='tabBtn3'
                      className='tab-btn__input'
                      name='tab-btn'
                      checked={activeTab === 'Document'}
                      onChange={() => handleTabChange('Document')}
                    />
                    <label htmlFor='tabBtn3' className='tab-btn'>
                      Documents
                    </label>
                    <input
                      type='radio'
                      id='tabBtn4'
                      className='tab-btn__input'
                      name='tab-btn'
                      checked={activeTab === 'Activity history'}
                      onChange={() => handleTabChange('Activity history')}
                    />
                    {/* <label htmlFor="tabBtn4" className="tab-btn">
                                    Activity history
                                    </label> */}
                    <input
                      type='radio'
                      id='tabBtn5'
                      className='tab-btn__input'
                      name='tab-btn'
                      checked={activeTab === 'Notes'}
                      onChange={() => handleTabChange('Notes')}
                    />
                    {/* <label htmlFor="tabBtn5" className="tab-btn">
                                    Notes
                                    </label> */}
                  </div>
                  <div className='main_view-questions'>
                    {activeTab === 'Lead Info' && <ConsultantLeadinfo leaddata={leadData} />}
                    {activeTab === 'Form Answers' && <FormAnswers leadData={leadData} />}
                    {activeTab === 'Document' && (
                      <Document
                        leadData={leadData}
                        Loading={loader}
                        setLoading={setLoader}
                        setLeadData={setLeadData}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {editModal && (
        <ConsultantAddEditLead
          editmodal={editModal}
          onClose={handleCloseModal}
          title='edit'
          setLeadData={setLeadData}
          getleads={getleads}
        />
      )}
      {show && (
        <div className='modal modal--add-lead open'>
          <div className='modal__container'>
            <div className='modal__header'>
              <h1 className='text-lg font-bold'>Please Upload File</h1>
              <div className='modal__close' onClick={handleClose}>
                <img src={close} alt='close' />
              </div>
            </div>
            <div className='modal__body'>
              <div className='dropzonepdf'>
                <input
                  type='file'
                  accept='*/*'
                  name='file_uplod'
                  id='profileUploader'
                  onChange={handleFileInput}
                  className='uploadpdf'
                />
                <div className='icon_titlepdf'>
                  <span className='icon_pdf'>
                    <FileIcon />
                  </span>
                  <p id='filename' className='test_pdf'>
                    {fileName?.name}
                  </p>
                  <span className='plane_text_pdf'>Upload File</span>
                </div>
                <aside className='upload_pdf'>{/* {activeTab === index} */}</aside>

                {errorMessage && (
                  <div className={'animated fadeIn err-msg'}>
                    <div className='error'>{errorMessage}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='modal__footer justify-end'>
              <button
                type='button'
                className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                id='sub'
                form='sub'
                type='submit'
                className='btn btn--primary btn--h40 px-6 py-2 text-base font-semibold'
                onClick={handleupload}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConsultantDetails;
