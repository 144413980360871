// import { useEffect, useState,useCallback } from "react";
// import HouseImg from "../../assets/img/house.svg"
// import HouseImg1 from "../../assets/img/commercial.svg"
// import { getQuestionnaire,submitAnswers } from "../../components/auth/core/_request";
// import { ENUM_API_STATUS } from "../../utils/_gConstant";
// import { useLocation,useNavigate } from "react-router-dom";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { useDispatch } from "react-redux";
// import Cookies from "universal-cookie";
// import { toastSuccess } from "../ui-elements/_Toastify";




// const QuestionTabs = () => {
//     const cookies = new Cookies();
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const location = useLocation();
//     const { token } = location?.state ?? '';

//     const [activeTab, setActiveTab] = useState(0);
//     const [currentStep, setCurrentStep] = useState(0);
//     const [formData, setFormData] = useState(0);
//     const [questionnaireData, setData] = useState([]);
//     const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    
   
//     const getQuestionnierData = useCallback(() => {
//         getQuestionnaire().then((r) => {
//             if (r.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
//               setData(r.data.data)
//             }else{
//                 navigate('/')
//             }
//         })
//     }, []);

//     useEffect(() => {
//         getQuestionnierData();
//     }, []);

//     /* const tabs = [
//         {
//             id: 1,
//             title: 'Basic Details',
//             content: [
//                 {
//                     qtitle: 'What is the average room height of a full floor...',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Which building shape best corresponds to the floor plan of the building?',
//                 },
//                 {
//                     qtitle: 'Is the building shaded?',
//                     qtotal: 240,
//                 },
//             ]
//         },
//         {
//             id: 2,
//             title: 'Heating & Consumption',
//             content: [
//                 {
//                     qtitle: 'Question 2',
//                 },
//                 {
//                     qtitle: 'Question 3',
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//         {
//             id: 3,
//             title: 'Roof',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//                 {
//                     qtitle: 'Question 5',
//                 },
//             ]
//         },
//         {
//             id: 4,
//             title: 'Outer Wall',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//         {
//             id: 5,
//             title: 'Windows',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//         {
//             id: 6,
//             title: 'Top Floor',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//         {
//             id: 7,
//             title: 'Bottom plate',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//         {
//             id: 8,
//             title: 'Bay Window & conservatory',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//         {
//             id: 9,
//             title: 'Front Door',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//         {
//             id: 10,
//             title: 'Photovoitaic & smart Home',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//         {
//             id: 11,
//             title: 'Location',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//         {
//             id: 12,
//             title: 'Basement',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//         {
//             id: 13,
//             title: 'Miscellaneous',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//         {
//             id: 14,
//             title: 'Uploads',
//             content: [
//                 {
//                     qtitle: 'Question 3',
//                     qtotal: 240,
//                 },
//                 {
//                     qtitle: 'Question 4',
//                 },
//             ]
//         },
//     ];
//  */
//     const formContents = [
//         {
//             id: 1,
//             question: "What is the average room height of a full floor of the building?",
//             formItems: [
//                 {
//                     id: 1,
//                     inputType: 'text',
//                     placeholder: "m",
//                     labelName: 'm',
//                 },
//             ]
//         },
//         {
//             id: 2,
//             question: "Which building shape best corresponds to the floor plan of the building?",
//             formItems: [
//                 {
//                     id: 1,
//                     inputType: 'radio',
//                     labelName: 'Residential Building',
//                     img: HouseImg,
//                 },
//                 {
//                     id: 2,
//                     inputType: 'radio',
//                     labelName: 'Commercial property',
//                     img: HouseImg1,
//                 },
//             ]
//         },
//         {
//             id: 3,
//             question: "Is the building shaded?",
//             formItems: [
//                 {
//                     id: 1,
//                     inputType: 'text',
//                     placeholder: "m",
//                     labelName: 'm',
//                 },
//             ]
//         },
//         {
//             id: 4,
//             question: "Is the building shaded?",
//             formItems: [
//                 {
//                     id: 1,
//                     img: HouseImg,
//                     inputType: 'radio',
//                     placeholder: "m",
//                     labelName: 'm',
//                 }
//             ]
//         }
//     ];

//     const handleInputChange = (e, id) => {
//         const { name, value } = e.target;
//         setFormData(prevState => ({
//             ...prevState,
//             [id]: {
//                 ...prevState[id],
//                 [name]: value
//             }
//         }));
//     };

//     const handleNext = () => {
//         /* if (currentStep < formContents.length - 1) {
//             setCurrentStep(prevStep => prevStep + 1);
//         } else {
//             console.log('Form data:', formData);
//         } */
//     };
//     const formik = useFormik({
//         initialValues: {
//             option_value: "",
//             option: "",
//             question:"",
//             is_skip:""
//         },
//         validationSchema: Yup.object({
//           option_value: Yup.string()
//             .required("Please enter answer."),
//         }),
//         onSubmit: (values, { setSubmitting }) => {
//           const data = {
//             token,
//             option_value: values.value,
//             option: values.option,
//             question: values.question,
//             is_skip:values.is_skip
//           };
    
//           submitAnswers(data).then((r) => {
//             if (r.status === ENUM_API_STATUS.SUCCESS_GET_API) {
//               // saveAuth(r.data?.access);
//               // localStorage.setItem(siteConfig.REFRESH_TOKEN, r?.data.refresh);
//               // getUserByToken(token).then(({ data: user }) => {
//               //   setCurrentUser(user);
//               //   dispatch(fetchAuthUserSuccess(user));
//               //   cookies.set("leadId", leadId, {
//               //     domain: ".ikosia.com",
//               //     path: "/",
//               //   });
//               //   cookies.set("userName", user?.first_name, {
//               //     domain: ".ikosia.com",
//               //     path: "/",
//               //   });
//               //   navigate(`/homeowner/${leadId}/`, { replace: true });
//               // });
//               localStorage.clear();
//               dispatch({ type: "USER_LOGOUT" });
//               cookies.remove("leadId", {
//                 domain: process.env.REACT_APP_DOMAIN,
//                 path: "/",
//               });
    
//               cookies.remove("userName", {
//                 domain: process.env.REACT_APP_DOMAIN,
//                 path: "/",
//               });
//               navigate("/");
//               toastSuccess("Something weng wrong!")
//             }
//           });
    
//           setSubmitting(false);
//         },
//       });

//     return (
//         <>
//             <div className="detailed_questionnaire">
//                 <div className="lasion_title">
//                     {questionnaireData.map((tab, index) => (
//                         <button
//                             key={index}
//                             className={index === activeTab ? 'active' : ''}
//                             onClick={() => setActiveTab(index)}
//                         >
//                             {tab.category}
//                         </button>
//                     ))}
//                 </div>
//                 <div className="question_list">
//                     {questionnaireData[activeTab]?.questions.map((question, index) => (
//                         <div key={index} className={`question_item ${index === currentQuestionIndex ? 'active' : ''}`}
//                         onClick={() => setCurrentQuestionIndex(index)}>
//                              <div
//                                 key={index}
//                             >
//                             <h3>{question?.text}</h3>
//                             </div>
//                             {/* {question.qtotal ? (
//                                 <p>{question.qtotal}</p>
//                             ) : (
//                                 <p>Pending</p>
//                             )} */}
//                         </div>
//                     ))}
//                 </div>
//                 <div className="form_info">
//                     <form onSubmit={formik.handleSubmit}>
//                         <p className="question_count">{currentStep + 1}/{formContents.length}</p>
//                         <div className="form_content">
//                             <p className="question_title">{questionnaireData[activeTab]?.questions[currentQuestionIndex]?.text}</p>
//                             <div className="form_group_list">
//                                 {questionnaireData[activeTab]?.questions[currentQuestionIndex]?.option.map(item => (
//                                     <div key={item.id} className="form_group">
//                                         {item?.text}
//                                         {item.kind === 1 && (
//                                             <div className="input_group">
//                                                 <input
//                                                     type="text"
//                                                     name="option_value"
//                                                     placeholder={item.placeholder}
//                                                     value={formik.values.option_value}
//                                                     onChange={formik.handleChange}
//                                                     onBlur={formik.handleBlur}
//                                                     // onChange={(e) => handleInputChange(e, item.id)}
//                                                 />
//                                                 <label htmlFor={`${formContents[currentStep].id}-${item.id}`}>{item.placeholder}</label>
//                                             </div>
//                                         )}
//                                         {item.kind === 2 && (
//                                             <div className="radio_group">
//                                                 <label htmlFor={`${formContents[currentStep].id}-${item.id}`}>
//                                                     <input
//                                                         type="radio"
//                                                         name={formContents[currentStep].id}
//                                                         id={`${formContents[currentStep].id}-${item.id}`}
//                                                         value={formik.values.option_value}
//                                                         onChange={formik.handleChange}
//                                                         // value={item?.text}
//                                                         // onChange={(e) => handleInputChange(e, formContents[currentStep].id)}
//                                                     />
//                                                     <span className="radiobox"></span>
//                                                     {item.img && (<span className="images_box"><img src={item.img} alt={item.labelName} /></span>)}
//                                                     <span className="radio_title">{item.labelName}</span>
//                                                 </label>
//                                             </div>
//                                         )}
//                                          {item.kind === 3 && (
//                                             <div className="input_group">
//                                                     <input
//                                                         type="number"
//                                                         name="option_value"
//                                                         placeholder={item.placeholder}
//                                                         value={formik.values.option_value}
//                                                         onChange={formik.handleChange}
//                                                         // value={formData[item.id] ? formData[item.id][item.text] : ''}
//                                                         // onChange={(e) => handleInputChange(e, item.id)}
//                                                     />
//                                                     <label htmlFor={`${formContents[currentStep].id}-${item.id}`}>
//                                                 </label>
//                                             </div>
//                                         )}
//                                          {item.kind === 4 && (
//                                             <div className="">
//                                                 <label htmlFor={`${formContents[currentStep].id}-${item.id}`}>
//                                                     <input
//                                                         type="file"
//                                                         name={formContents[currentStep].id}
//                                                         id={`${formContents[currentStep].id}-${item.id}`}
//                                                         // value={item.labelName}
//                                                         // onChange={(e) => handleInputChange(e, formContents[currentStep].id)}
//                                                     />
//                                                     <span className="radiobox"></span>
//                                                     {item.img && (<span className="images_box"><img src={item.img} alt={item.labelName} /></span>)}
//                                                     <span className="radio_title">{item.labelName}</span>
//                                                 </label>
//                                             </div>
//                                         )}
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>

//                         <div className="button_group">
//                             {currentStep < formContents.length - 1 && (
//                                 <button type="button" className="skip_button" >Skip</button>
//                             )}
//                             <button type="button" className="next_button">
//                                 {currentStep === formContents.length - 1 ? 'Submit' : 'Next'}
//                             </button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default QuestionTabs;
import { getQuestionnaire, submitAnswers } from "../auth/core/_request";
import { toastErrors, toastSuccess } from "../ui-elements/_Toastify";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { ENUM_API_STATUS } from "../../utils/_gConstant";
import FormValidator from "../../services/FormValidator";
import InfoIcon from "./icon/InfoIcon"
import siteConfig from "../../services/_siteConfig";
import { useNavigate } from "react-router-dom";



const QuestionTabs = () => {
    const leadId = useSelector((state) => state?.authReducer?.user?.lead_id)
    const cookies = new Cookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState(0);
    const [questionnaireData, setData] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [skipValidation, setSkipValidation] = useState(false);
    const [selectedRadioValue, setSelectedRadioValue] = useState('');
    const [followUpQuestion, setFollowUpQuestion] = useState(null);

    const [submitAnsData, setSubmitAnsData] = useState({
        saving: false,
        token: localStorage.getItem(siteConfig.ACCESS_TOKEN),
        option_value: '',
        option_values: {},
        option: '',
        question: '',
        is_skip: 0,
        follow_up_option_value: '',
        follow_up_option: ''
    });

    const initialFormData = {
        option_value: '',
        option_values: {},
        option: "",
        question: "",
        is_skip: 0,
        follow_up_option_value: '',
        follow_up_option: ''
    };

    const getQuestionnierData = useCallback(() => {
        getQuestionnaire().then((r) => {
            if (r.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
                setData(r.data.data)

                // Find the active tab and question
                const activeTabIndex = r.data.data.findIndex(tab => tab.is_active);
                setActiveTab(activeTabIndex !== -1 ? activeTabIndex : 0);

                const activeQuestionIndex = r.data.data[activeTabIndex]?.questions.findIndex(question => question.is_active);
                setCurrentQuestionIndex(activeQuestionIndex !== -1 ? activeQuestionIndex : 0);

                // const submittedOptionValue = r.data.data[activeTab]?.questions[currentQuestionIndex]?.ans;
                // console.log('optionvalue',submittedOptionValue);
                // if (submittedOptionValue !== null) {
                //     setSubmitAnsData(prevState => ({
                //         ...prevState,
                //         option_value: submittedOptionValue
                //     }));
                // }
                // const activeTabIndex = r.data.data.findIndex(tab => tab.is_active === true);
                // setActiveTab(activeTabIndex !== -1 ? activeTabIndex : 0); // Set default to first tab if no active tab found

                // Set default active options for each question
                /* const defaultActiveOptions = r.data.data.map(tab => {
                    return tab.questions.findIndex(question => question.is_active === true);
                });
                setCurrentQuestionIndex(defaultActiveOptions !== -1 ? defaultActiveOptions : 0); */


            } else {
                navigate('/')
            }
        })
    }, []);

    const resetForm = () => {
        setSubmitAnsData(initialFormData);
        setValidation(validator.valid());
        setSubmitted(false);
    };

    const handelChange = (event, item) => {
        const { name, value } = event.target;

        setSubmitAnsData((prevState) => ({
            ...prevState,
            option_values: {
                ...prevState.option_values,
                [item.id]: value // Update the value for the specific option
            },
            option_value: value,
            follow_up_option_value: value,
            option: name === 'option_value' ? item.id : prevState.option, // Update option only if it's the main question
            follow_up_option: name === 'follow_up_option_value' ? item.id : prevState.follow_up_option, // Update follow-up option only if it's follow-up question
        }));

        // if (item.follow_up_question) {
        //     setFollowUpQuestion(item.follow_up_question);
        // } else if (name === 'option_value') {
        //     setFollowUpQuestion(null);
        // }
    };
    const handleRadioChange = (event, item) => {

        setSelectedRadioValue(item.text); // Update the selected radio button value state

        const { name, value } = event.target;

        setSubmitAnsData((prevState) => ({
            ...prevState,
            option_values: {
                ...prevState.option_values,
                [item.id]: value // Update the value for the specific option
            },
            option_value: value,
            follow_up_option_value: value,
            option: name === 'option_value' ? item.id : prevState.option, // Update option only if it's the main question
            follow_up_option: name === 'follow_up_option_value' ? item.id : prevState.follow_up_option, // Update follow-up option only if it's follow-up question
        }));

        // Check if the selected option has a follow-up question
        // if (item.follow_up_question) {
        //     setFollowUpQuestion(item.follow_up_question);
        // } else if (name === 'option_value') {
        //     setFollowUpQuestion(null);
        // }
        if (name === 'option_value') {
            setFollowUpQuestion(null);
        }
    };
    const validator = new FormValidator([
        {
            field: "option_value",
            method: (value) => {
                const isRequired = questionnaireData[activeTab]?.questions[currentQuestionIndex]?.is_required;
                const isSkip = submitAnsData.is_skip === 1;

                // Check if the value is required and not skipped
                if (isRequired && !isSkip) {
                    return !value; // Validation fails if value is empty
                }
                return true; // Skip validation if is_skip is 1 or is_required is false
            },
            validWhen: false,
            message: "Please enter an answer.",
        },
    ]);

    const [submitted, setSubmitted] = useState(false);
    const [validation, setValidation] = useState(validator.valid());

    let checkValidation = submitted ? validator.validate(submitAnsData) : validation;

    const handleQuestionClick = (questionIndex) => {
        setCurrentQuestionIndex(questionIndex);
        setSubmitAnsData(prevState => ({
            ...prevState,
            is_skip: 0 // Set is_skip to 0
        }));

        setFollowUpQuestion(null); // Reset follow-up question on question change
        // setSkipValidation(false);
        // console.log(questionIndex);
        // // Update input value based on selected question's submitted value
        // const submittedOptionValue = questionnaireData[activeTab]?.questions[questionIndex]?.ans;
        // console.log(submittedOptionValue);
        // if (submittedOptionValue !== null) {
        //     setSubmitAnsData(prevState => ({
        //         ...prevState,
        //         option_value: submittedOptionValue
        //     }));
        // }
    };

    const handleCategoryClick = (categoryIndex) => {
        const activeQuestionIndex = questionnaireData[categoryIndex]?.questions.findIndex(question => question.is_active);
        setActiveTab(categoryIndex);
        setCurrentQuestionIndex(activeQuestionIndex !== -1 ? activeQuestionIndex : 0);
        setSubmitAnsData(prevState => ({
            ...prevState,
            is_skip: 0 // Set is_skip to 0
        }));

        setFollowUpQuestion(null); // Reset follow-up question on question change
    };

    const handleSkip = () => {
        const optionId = questionnaireData[activeTab]?.questions[currentQuestionIndex]?.option[0]?.id;
        setSubmitAnsData(prevState => ({
            ...prevState,
            is_skip: 1, // Set is_skip to 1 when skip button is clicked
            option: optionId, // Ensure the option id is set when skipping
            question: questionnaireData[activeTab]?.questions[currentQuestionIndex]?.id,
        }));
        setSkipValidation(true);
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // console.log('isRequierd',questionnaireData[activeTab]?.questions[currentQuestionIndex]?.is_required);
    //     // console.log('isSkipped: ',submitAnsData.is_skip);
    //     if(submitAnsData.is_skip !== 1){
    //         setSkipValidation(false);
    //     }
    //     // console.log('skipp',skipValidation);
    //     // console.log(questionnaireData[activeTab]?.questions[currentQuestionIndex]?.is_required);
    //     let validation;
    //     if (submitAnsData.is_skip === 1 || questionnaireData[activeTab]?.questions[currentQuestionIndex]?.is_required === false) {
    //         // If skip button is clicked, set validation to valid
    //         validation = { isValid: true, ...validator.valid() };
    //         setSkipValidation(true);
    //     }else {
    //         // Otherwise, perform validation
    //         validation = validator.validate(submitAnsData);
    //     }
    //     // const validation = validator.validate(submitAnsData);

    //     setValidation(validation);
    //     setSubmitted(true);
    //     if (validation.isValid) {
    //         // const selectedOptionId = questionnaireData[activeTab]?.questions[currentQuestionIndex]?.option.find(option => option.kind !== submitAnsData.option_value)?.id;
    //         const data = {
    //             token: localStorage.getItem(siteConfig.ACCESS_TOKEN),
    //             option_value: submitAnsData.option_value,
    //             option: submitAnsData.option,
    //             question: questionnaireData[activeTab]?.questions[currentQuestionIndex]?.id,
    //             is_skip: submitAnsData.is_skip
    //         };
    //         if(submitAnsData.option_value !== ''){
    //             submitAnswers(data).then((r) => {
    //                 if (r.status === ENUM_API_STATUS.SUCCESS_GET_API) {
    //                     const newIndex = currentQuestionIndex + 1;
    //                     // setCurrentQuestionIndex(newIndex);
    //                     handleQuestionClick(newIndex);
    //                     resetForm();
    //                     getQuestionnierData();
    //                     toastSuccess("Answer saved successfully.")
    //                     if (activeTab === questionnaireData.length - 1 && currentQuestionIndex === questionnaireData[activeTab]?.questions?.length - 1) {
    //                         navigate(`/homeowner/${leadId}`);
    //                     } else if(currentQuestionIndex === questionnaireData[activeTab]?.questions?.length - 1){
    //                         setActiveTab(activeTab + 1);
    //                         handleQuestionClick(0);
    //                     }
    //                 }else{
    //                     toastErrors(r?.data)
    //                 }
    //             });
    //         }else{ 
    //             const newIndex = currentQuestionIndex + 1;
    //             // setCurrentQuestionIndex(newIndex);
    //             handleQuestionClick(newIndex);
    //             resetForm();
    //             getQuestionnierData();
    //             /* console.log('activeTab: ',activeTab);
    //             console.log('questionLength: ',questionnaireData.length);
    //             console.log('currentIndex',currentQuestionIndex);
    //             console.log('Question currentIndex',questionnaireData[activeTab]?.questions?.length); */
    //             if (activeTab === questionnaireData.length - 1 && currentQuestionIndex === questionnaireData[activeTab]?.questions?.length - 1) {
    //                 navigate(`/homeowner/${leadId}`);
    //             } else if(currentQuestionIndex === questionnaireData[activeTab]?.questions?.length - 1){
    //                 setActiveTab(activeTab + 1);
    //                 handleQuestionClick(0);
    //             }
    //         }
    //     }
    // };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (submitAnsData.is_skip !== 1) {
            setSkipValidation(false);
        }

        let validation;
        if (submitAnsData.is_skip === 1 || questionnaireData[activeTab]?.questions[currentQuestionIndex]?.is_required === false) {
            validation = { isValid: true, ...validator.valid() };
            setSkipValidation(true);
        } else {
            validation = validator.validate(submitAnsData);
        }

        setValidation(validation);
        setSubmitted(true);

        if (validation.isValid) {
            if (followUpQuestion) {
                // Handle follow-up question submission
                const followUpData = {
                    token: localStorage.getItem(siteConfig.ACCESS_TOKEN),
                    option_value: submitAnsData.follow_up_option_value,
                    option: submitAnsData.follow_up_option,
                    question: followUpQuestion.id,
                    is_skip: submitAnsData.is_skip
                };

                if (submitAnsData.follow_up_option_value !== '') {
                    submitAnswers(followUpData).then((r) => {
                        if (r.status === ENUM_API_STATUS.SUCCESS_GET_API) {
                            toastSuccess("Follow-up answer saved successfully.");
                            setFollowUpQuestion(null); // Clear follow-up question after submission
                            const newIndex = currentQuestionIndex + 1;
                            handleQuestionClick(newIndex);
                            resetForm();
                            getQuestionnierData();
                            if (activeTab === questionnaireData.length - 1 && newIndex === questionnaireData[activeTab]?.questions?.length) {
                                navigate(`/homeowner/${leadId}`);
                            } else if (newIndex === questionnaireData[activeTab]?.questions?.length) {
                                setActiveTab(activeTab + 1);
                                handleQuestionClick(0);
                            }
                        } else {
                            toastErrors(r?.data);
                        }
                    });
                }
            } else {
                // Handle main question submission
                const data = {
                    token: localStorage.getItem(siteConfig.ACCESS_TOKEN),
                    option_value: submitAnsData.option_values[submitAnsData.option] ?? submitAnsData.option_value,
                    option: submitAnsData.option,
                    question: questionnaireData[activeTab]?.questions[currentQuestionIndex]?.id,
                    is_skip: submitAnsData.is_skip
                };

                if (submitAnsData.option_value !== '') {
                    submitAnswers(data).then((r) => {
                        if (r.status === ENUM_API_STATUS.SUCCESS_GET_API) {
                            toastSuccess("Answer saved successfully.");

                            // Check if there is a follow-up question
                            const selectedOption = questionnaireData[activeTab]?.questions[currentQuestionIndex]?.option.find(option => option.id === submitAnsData.option);
                            if (selectedOption?.follow_up_question) {
                                console.log(selectedOption.follow_up_question);
                                setFollowUpQuestion(selectedOption.follow_up_question);
                                 // Set the follow-up answer in submitAnsData
                                setSubmitAnsData((prevState) => ({
                                    ...prevState,
                                    follow_up_option_value: selectedOption.follow_up_question.ans,
                                }));
                            } else {
                                // No follow-up question, proceed to next question or tab
                                const newIndex = currentQuestionIndex + 1;
                                handleQuestionClick(newIndex);
                                resetForm();
                                getQuestionnierData();
                                if (activeTab === questionnaireData.length - 1 && newIndex === questionnaireData[activeTab]?.questions?.length) {
                                    navigate(`/homeowner/${leadId}`);
                                } else if (newIndex === questionnaireData[activeTab]?.questions?.length) {
                                    setActiveTab(activeTab + 1);
                                    handleQuestionClick(0);
                                }
                            }
                        } else {
                            toastErrors(r?.data);
                        }
                    });
                } else {
                    // Empty answer, proceed to next question or tab
                    const newIndex = currentQuestionIndex + 1;
                    handleQuestionClick(newIndex);
                    resetForm();
                    getQuestionnierData();
                    if (activeTab === questionnaireData.length - 1 && newIndex === questionnaireData[activeTab]?.questions?.length) {
                        navigate(`/homeowner/${leadId}`);
                    } else if (newIndex === questionnaireData[activeTab]?.questions?.length) {
                        setActiveTab(activeTab + 1);
                        handleQuestionClick(0);
                    }
                }
            }
        }
    };


    useEffect(() => {
        getQuestionnierData();
    }, [getQuestionnierData]);

    useEffect(() => {
        // Check if there is a previously submitted answer for the current question
        const submittedOptionValue = questionnaireData[activeTab]?.questions[currentQuestionIndex]?.ans;
        const optionId = questionnaireData[activeTab]?.questions[currentQuestionIndex]?.option[0]?.id; //default first selected option id

        if (submittedOptionValue !== null) {
            setSubmitAnsData(prevState => ({
                ...prevState,
                option_value: submittedOptionValue,
                question: questionnaireData[activeTab]?.questions[currentQuestionIndex]?.id,
                option: optionId
            }));

            // Retain follow-up question state if exists
            const selectedOption = questionnaireData[activeTab]?.questions[currentQuestionIndex]?.option.find(option => option.text === submittedOptionValue);
            if (selectedOption?.follow_up_question && selectedOption?.follow_up_question?.ans != '') {
                setFollowUpQuestion(selectedOption?.follow_up_question);
                setSubmitAnsData((prevState) => ({
                    ...prevState,
                    follow_up_option_value: selectedOption?.follow_up_question?.ans,
                }));
            }
        }
    }, [activeTab, currentQuestionIndex, questionnaireData]);

    return (
        <>
            <div className="detailed_questionnaire">
                <div className="lasion_title">
                    {questionnaireData.map((tab, index) => (
                        <button
                            key={index}
                            className={index === activeTab ? 'active' : ''}
                            onClick={() => handleCategoryClick(index)}
                        >
                            {tab.category}
                        </button>
                    ))}
                </div>
                <div className="question_list">
                    {questionnaireData[activeTab]?.questions.map((question, index) => (
                        <div key={index} className={`question_item ${index === currentQuestionIndex ? 'active' : ''}`}>
                            <button
                                key={index}
                                className={index === currentQuestionIndex ? 'active' : ''}
                                onClick={() => handleQuestionClick(index)}
                            >
                                {question?.text}
                            </button>
                            {question.ans ? (
                                <p>{question.ans}</p>
                            ) : (
                                <p>Pending</p>
                            )}
                        </div>
                    ))}
                </div>
                {/* {console.log(questionnaireData[activeTab]?.questions[currentQuestionIndex]?.ans)} */}
                <div className="form_info">
                    <form onSubmit={handleSubmit} >
                        <p className="question_count">{currentQuestionIndex + 1}/{questionnaireData[activeTab]?.questions?.length}</p>
                        <div className="form_content">
                            <p className="question_title">{questionnaireData[activeTab]?.questions[currentQuestionIndex]?.text}
                                {/* Info Button for Placeholder */}
                                {questionnaireData[activeTab]?.questions[currentQuestionIndex]?.placeholder && (
                                    <div className="info_button_wrapper">
                                        <span className="info_button tooltip"
                                            data-tooltip={questionnaireData[activeTab]?.questions[currentQuestionIndex]?.placeholder}
                                            data-tooltip-pos="up"
                                            data-tooltip-length="medium"
                                        >
                                            <InfoIcon />
                                        </span>
                                        {/* <span className="info_tooltip">{questionnaireData[activeTab]?.questions[currentQuestionIndex]?.placeholder}</span> */}
                                    </div>
                                )}
                            </p>

                            <div className="form_group_list">
                                {questionnaireData[activeTab]?.questions[currentQuestionIndex]?.option.map(item => (
                                    <div key={item.id} className={`form_group ${questionnaireData[activeTab]?.questions[currentQuestionIndex]?.hint_image || questionnaireData[activeTab]?.questions[currentQuestionIndex]?.hint ? 'with_hint_image' : ''}`}>
                                        <input type="hidden" name="option" id="option" value={item?.id}></input>
                                        {item.kind === 1 && (
                                            <>
                                                <div className="input_group">
                                                    <input
                                                        type="text"
                                                        onChange={(event) => handelChange(event, item)}
                                                        name="option_value"
                                                        value={submitAnsData.option_values[item.id] ?? (submitAnsData.option === item.id ? submitAnsData.option_value : "")}
                                                    />
                                                    <label htmlFor={`${questionnaireData[activeTab]?.id}-${item.id}`}>{item.placeholder}</label>
                                                </div>
                                                {/* {!skipValidation && (
                                            <div className={ checkValidation.option_value.isInvalid ? "animated fadeIn err-msg" : "" } >
                                                <div className="error">
                                                    {checkValidation.option_value.message}
                                                </div>
                                            </div>
                                            )} */}
                                            </>
                                        )}
                                        {item.kind === 2 && (
                                            <>
                                                <div className="radio_group">
                                                    <label htmlFor={`${questionnaireData[activeTab]?.id}-${item.id}`}>
                                                        <input
                                                            type="radio"
                                                            name="option_value"
                                                            id={`${questionnaireData[activeTab]?.id}-${item.id}`}
                                                            checked={submitAnsData.option_value === item.text}
                                                            value={item.text}
                                                            onChange={(event) => handleRadioChange(event, item)}
                                                        />
                                                        <span className="radiobox"></span>
                                                        {item.image_url && (<span className="images_box"><img src={item.image_url} alt={item.text} /></span>)}
                                                        <span className="radio_title">{item.text}</span>
                                                    </label>
                                                </div>
                                            </>
                                        )}
                                        {item.kind === 3 && (
                                            <>
                                                <div className="input_group">
                                                    <input
                                                        type="number"
                                                        name="option_value"
                                                        placeholder={item.placeholder}
                                                        value={submitAnsData.option_values[item.id] ?? (submitAnsData.option === item.id ? submitAnsData.option_value : "")}
                                                        onChange={(event) => handelChange(event, item)}
                                                        id={`${questionnaireData[activeTab]?.id}-${item.id}`}
                                                    />
                                                    <label htmlFor={`${questionnaireData[activeTab]?.id}-${item.id}`}></label>
                                                </div>
                                            </>
                                        )}
                                        {item.kind === 4 && (
                                            <>
                                                <div className="">
                                                    <label htmlFor={`${questionnaireData[activeTab]?.id}-${item.id}`}>
                                                        <input
                                                            type="file"
                                                            name="option_value"
                                                            id={`${questionnaireData[activeTab]?.id}-${item.id}`}
                                                        />
                                                        {item.img && (<span className="images_box"><img src={item.img} alt={item.labelName} /></span>)}
                                                        <span className="radio_title">{item.labelName}</span>
                                                    </label>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                            {(questionnaireData[activeTab]?.questions[currentQuestionIndex]?.hint || questionnaireData[activeTab]?.questions[currentQuestionIndex]?.hint_image) && (
                                <div className={`hint_infomation ${(questionnaireData[activeTab]?.questions[currentQuestionIndex]?.hint && questionnaireData[activeTab]?.questions[currentQuestionIndex]?.hint_image) ? 'with_hint_and_hint_image' : ''
                                    }`}>
                                    <div className="hint_infomation_row">
                                        {questionnaireData[activeTab]?.questions[currentQuestionIndex]?.hint &&
                                            <div className="question_hint"><div className="question_hint_inner"><span>Hint:</span><p>{questionnaireData[activeTab]?.questions[currentQuestionIndex]?.hint}</p></div></div>}

                                        {questionnaireData[activeTab]?.questions[currentQuestionIndex]?.hint_image && (
                                            <div className="question_hint_image">
                                                <div className="question_hint_image_inner">
                                                    <p>Hint Image:</p>
                                                    <div className="image_box">
                                                        <img src={questionnaireData[activeTab]?.questions[currentQuestionIndex]?.hint_image} alt="Hint" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {!skipValidation && (
                                <div className={checkValidation.option_value.isInvalid ? "animated fadeIn err-msg" : ""} >
                                    <div className="error">
                                        {checkValidation.option_value.message}
                                    </div>
                                </div>
                            )}

                            {followUpQuestion && (
                                <div className="follow-up-question">
                                    <p className="question_title">
                                        {followUpQuestion.text}
                                        {followUpQuestion?.placeholder && (
                                            <div className="info_button_wrapper">
                                                <span className="info_button tooltip"
                                                    data-tooltip={followUpQuestion?.placeholder}
                                                    data-tooltip-pos="up"
                                                    data-tooltip-length="medium"
                                                >
                                                    <InfoIcon />
                                                </span>
                                                {/* <span className="info_tooltip">{questionnaireData[activeTab]?.questions[currentQuestionIndex]?.placeholder}</span> */}
                                            </div>
                                        )}
                                    </p>
                                    <div className="form_group_list">
                                        {followUpQuestion.option.map(followUpItem => (
                                            <div key={followUpItem.id} className="form_group">
                                                {followUpItem.kind === 1 && (
                                                    <div className="input_group">
                                                        <input
                                                            type="text"
                                                            onChange={(event) => handelChange(event, followUpItem)}
                                                            name="follow_up_option_value"
                                                            // value={followUpQuestion.ans ?? submitAnsData.follow_up_option_value}
                                                            value={submitAnsData.follow_up_option_value ?? ""}
                                                        />
                                                        <label htmlFor={`${followUpQuestion.id}-${followUpItem.id}`}>{followUpItem.placeholder}</label>
                                                    </div>
                                                )}
                                                {followUpItem.kind === 2 && (
                                                    <div className="radio_group">
                                                        <label htmlFor={`${followUpQuestion.id}-${followUpItem.id}`}>
                                                            <input
                                                                type="radio"
                                                                name="follow_up_option_value"
                                                                id={`${followUpQuestion.id}-${followUpItem.id}`}
                                                                checked={submitAnsData.follow_up_option_value === followUpItem.text}
                                                                value={followUpItem.text}
                                                                onChange={(event) => handleRadioChange(event, followUpItem)}
                                                            />
                                                            <span className="radiobox"></span>
                                                            <span className="radio_title">{followUpItem.text}</span>
                                                        </label>
                                                    </div>
                                                )}
                                                {followUpItem.kind === 3 && (
                                                    <div className="input_group">
                                                        <input
                                                            type="number"
                                                            name="follow_up_option_value"
                                                            value={submitAnsData.follow_up_option_value ?? ""}
                                                            placeholder={followUpItem.placeholder}
                                                            onChange={(event) => handelChange(event, followUpItem)}
                                                            id={`${followUpQuestion.id}-${followUpItem.id}`}
                                                        />
                                                        <label htmlFor={`${followUpQuestion.id}-${followUpItem.id}`}></label>
                                                    </div>
                                                )}
                                                {followUpItem.kind === 4 && (
                                                    <div className="input_group">
                                                        <input
                                                            type="file"
                                                            name="follow_up_option_value"
                                                            placeholder={followUpItem.placeholder}
                                                            id={`${followUpQuestion.id}-${followUpItem.id}`}
                                                        />
                                                        {followUpQuestion.img && (<span className="images_box"><img src={followUpQuestion.img} alt={followUpQuestion.text} /></span>)}
                                                        <label htmlFor={`${followUpQuestion.id}-${followUpItem.id}`}></label>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div class="hint_infomation with_hint_and_hint_image">
                                        <div class="hint_infomation_row">
                                        {followUpQuestion?.hint &&
                                            <div className="question_hint"><div className="question_hint_inner"><span>Hint:</span><p>{followUpQuestion?.hint}</p></div></div>}

                                        {followUpQuestion?.hint_image && (
                                            <div className="question_hint_image">
                                                <div className="question_hint_image_inner">
                                                    <p>Hint Image:</p>
                                                    <div className="image_box">
                                                        <img src={followUpQuestion?.hint_image} alt="Hint" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>

                        <div className="button_group">
                            {questionnaireData[activeTab]?.questions[currentQuestionIndex]?.is_required === false && (
                                <button type="submit" className="skip_button" onClick={handleSkip}>Skip</button>
                            )}
                            <button type="submit" className="next_button" >
                                {currentQuestionIndex === questionnaireData[activeTab]?.questions?.length - 1 ? 'Submit' : 'Next'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default QuestionTabs;
