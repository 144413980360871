import React, { useCallback, useEffect, useState } from "react";
import { getUploadQuestionnaire, uploadQuestionnaire } from "../auth/core/_request";
import { toastError, toastErrors, toastSuccess } from "../ui-elements/_Toastify";
import DownloadIcon from "./icon/DownloadIcon";
import DownloadImg from "../../assets/img/commercial.svg";
import { ENUM_API_STATUS } from "../../utils/_gConstant";
import FileIcon from "./icon/FileIcon";
import FormValidator from "../../services/FormValidator";
import siteConfig from "../../services/_siteConfig";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const UploadTab = ({ fileData, getdocuments, styled, filelength , id, selectedDocument=0}) => {
  const leadId = useSelector((state) => state?.authReducer?.user?.lead_id)
  const navigate = useNavigate();
  const params = useParams();
  const lead_id = params?.leadId ? params?.leadId : id; 
  const [activeTab, setActiveTab] = useState(0);
  console.log('activeTab :', activeTab);
  const [uploadQuestionnaireData, setData] = useState([]);
  const [skipValidation, setSkipValidation] = useState(false);
  const [filename,setFilename] = useState('')
  const [userData, SetUserData] = useState({
    saving: false,
    token: localStorage.getItem(siteConfig.ACCESS_TOKEN),
    option_value: "",
    option: "",
    question: "",
    is_skip: 0,
  });

  useEffect(()=>{
    if(selectedDocument!=false){
      setActiveTab(selectedDocument);
    }else{
      setActiveTab(fileData?.length);
    }
  },[selectedDocument]);

  const styledata =
    fileData &&
    fileData?.uploads && fileData?.uploads.length> 0 && fileData?.uploads[activeTab]?.questions?.some(
      (question) => question.file !== null && question.file !== undefined
    );
  const style = styledata
    ? {
        position: "relative",
        top: "11%",
        right: "45%",
      }
    : {
        position: "relative",
        left: "28%",
      };

  //GET UPLOAD QUETIONNAIRE DATA
  const getUploadQuestionnierData = useCallback(() => {
    if (fileData && fileData?.uploads?.length > 0) {
      setData(fileData);
      const activeTabIndex = fileData?.uploads?.findIndex(
        (item) => item.is_active === true
      );
      setActiveTab(activeTabIndex !== -1 ? activeTabIndex : 0); // Set default to first item if no active item found
    } else {
      getUploadQuestionnaire().then((r) => {
        if (r.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
          setData(r.data.data);
          const activeTabIndex = r.data.data.findIndex(
            (item) => item.is_active === true
          );
          setActiveTab(activeTabIndex !== -1 ? activeTabIndex : 0); // Set default to first item if no active item found
        } else {
          navigate("/");
        }
      });
    }
  }, []);

  const validator = new FormValidator([
    {
      field: "option_value",
      method: (value) => {
        const isRequired =
        uploadQuestionnaireData && uploadQuestionnaireData.length > 0 ? uploadQuestionnaireData[activeTab]?.questions[0]?.is_required : uploadQuestionnaireData?.uploads && uploadQuestionnaireData?.uploads?.length > 0 && uploadQuestionnaireData?.uploads[activeTab]?.questions[0]?.is_required 
        const isSkip = userData.is_skip === 1;

        // Check if the value is required and not skipped
        if (isRequired && !isSkip) {
          return !value; // Validation fails if value is empty
        }
        return true; // Skip validation if is_skip is 1 or is_required is false
      },
      validWhen: false,
      message: "Please upload an image.",
    },
  ]);
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(validator.valid());
  // const [checkValidation, setCheckValidation] = useState(submitted ? validator.validate(userData) : validation);

  let checkValidation = submitted ? validator.validate(userData) : validation;
  const handleSubmit = (e) => {
    e.preventDefault();
    const tabDataDefaultSelected = uploadQuestionnaireData.length > 0 ? uploadQuestionnaireData[activeTab] :uploadQuestionnaireData?.uploads && uploadQuestionnaireData?.uploads?.length > 0 && uploadQuestionnaireData?.uploads[activeTab];
    console.log('tabDataDefaultSelected :', tabDataDefaultSelected);

        if(userData.is_skip !== 1){
      setSkipValidation(false);
    }
    let validation;
        if (userData.is_skip === 1 || tabDataDefaultSelected?.questions[0]?.is_required === false) {
      validation = { isValid: true, ...validator.valid() };
      setSkipValidation(true);
        }else {
      validation = validator.validate(userData);
    }
    // const validation = validator.validate(userData);
        
    setValidation(validation);
    setSubmitted(true);
    var finaldata;
    if (validation.isValid) {
      if (fileData) {
        finaldata = {
          // token: userData.token,
          option_value: userData.option_value,
          option:
            userData.option !== ""
              ? userData.option
              : tabDataDefaultSelected?.questions[0]?.option[0]?.id,
          question:
            userData.question !== ""
              ? userData.question
              : tabDataDefaultSelected?.questions[0]?.id,
          is_skip: userData.is_skip,
          lead_id: lead_id,
        };
      } else {
        finaldata = {
          // token: userData.token,
          option_value: userData.option_value,
          option:
            userData.option !== ""
              ? userData.option
              : tabDataDefaultSelected?.questions[0]?.option[0]?.id,
          question:
            userData.question !== ""
              ? userData.question
              : tabDataDefaultSelected?.questions[0]?.id,
          is_skip: userData.is_skip,
        };
      }

      // Validate required fields before moving to the next item
      /* const tabData = uploadQuestionnaireData[activeTab];
        const requiredFields = tabData.questions.filter(question => question.is_required);
        const isFieldsFilled = requiredFields.every(question => userData.get(question.id));

        if (!isFieldsFilled) {
            // Display error message or handle validation as per your UI/UX
            toastErrors("Please upload image");
            console.log("Please fill in all required fields.");
            return;
        } */
      if (userData.option_value !== "") {
        uploadQuestionnaire(finaldata).then((r) => {
          if (r.status === ENUM_API_STATUS.SUCCESS_GET_API) {
            toastSuccess("File uploaded successfully.");
            if (activeTab === uploadQuestionnaireData.length > 0 ? uploadQuestionnaireData?.length - 1 :uploadQuestionnaireData?.uploads?.length - 1 && !fileData?.uploads) {
              navigate(`/homeowner/${leadId}`);
            } else {
              setActiveTab(activeTab + 1);
              if (fileData?.uploads) {
                getdocuments();
              } else {
                getUploadQuestionnierData();
              }
            }
            // handleCategoryClick()
          } else {
            toastError(r?.data);
          }
        });
      } else {
        if (activeTab === uploadQuestionnaireData.length > 0 ? uploadQuestionnaireData?.length - 1 :uploadQuestionnaireData?.uploads?.length - 1 && !fileData?.uploads) {
          navigate(`/homeowner/${leadId}`);
        } else {
          setActiveTab(activeTab + 1);
          if (fileData?.uploads) {
            getdocuments();
          } else {
            getUploadQuestionnierData();
          }
        }
      }
    }
  };
 
    const handleImageInput = (event,item) => {
    const { name } = event.target;
    let file = event.target.files[0];
    let fileType = file.type;
    let fileCheck = fileType.match(/image\/[A-Za-z]*/g);
    console.log(fileCheck, "Testing Log");
    
    if (fileCheck && fileCheck.length > 0) {
      let reader = new FileReader();
      reader.onloadend = function () {
        let base64String = reader.result;
        setFilename(file.name)
        SetUserData((prevState) => ({ ...prevState, [name]: base64String }));
        // document.getElementById(name).textContent = file.name;
        // document.getElementById(name + "_preview").src = reader.result;
      }.bind(this);
      reader.readAsDataURL(file);
    } else {
          SetUserData((prevState) => ({ ...prevState, [name + "_uploading"]: false }));
          toastErrors(["Please upload image files only."])
    }
  };

    const handleCategoryClick = (categoryIndex,item) =>{
      console.log('categoryIndex :', categoryIndex);
      console.log('item :', item);
      setActiveTab(categoryIndex);
        SetUserData((prevState) => ({ ...prevState, question:item?.questions?.[0]?.id,option:item?.questions?.[0]?.option?.[0]?.id }));

  };
  const handleSkip = () => {
    setActiveTab(activeTab + 1);
    const tabDataDefaultSelected = uploadQuestionnaireData?.length > 0 ?  uploadQuestionnaireData[activeTab]: uploadQuestionnaireData?.uploads && uploadQuestionnaireData?.uploads?.length > 0 && uploadQuestionnaireData?.uploads[activeTab];

        SetUserData(prevState => ({
      ...prevState,
      is_skip: 1, // Set is_skip to 1 when skip button is clicked
      option: tabDataDefaultSelected?.questions[0]?.option[0]?.id, // Ensure the option id is set when skipping
      question: tabDataDefaultSelected?.questions[0]?.id,
    }));
    setSkipValidation(true);
  };

  useEffect(() => {
    getUploadQuestionnierData();
  }, [getUploadQuestionnierData]);

  // console.log("FileDatagadaadgadagd", fileData[activeTab])
  return (
    <div className={fileData?.isfp_document ? "upload_tab ifsp_tab" :styledata ? "upload_tab new_tab" : fileData?.uploads && fileData?.uploads?.length > 0  && fileData?.uploads[activeTab]?.questions.file == null   ?  "new_flex"  :"upload_tab" } >
      {uploadQuestionnaireData.length> 0  && (
        <div className="upload_question_list">
          {uploadQuestionnaireData.length > 0 ? uploadQuestionnaireData?.map((item, index) => (
            <button
              key={index}
              className={
                activeTab === index
                  ? "upquestion_button active"
                  : "upquestion_button" 
              }
              onClick={() => handleCategoryClick(index, item)}
            >
              {item.category}
            </button>
          )):uploadQuestionnaireData?.uploads?.length > 0 && uploadQuestionnaireData?.uploads.map((item, index) => (
            <button
              key={index}
              className={
                activeTab === index
                  ? "upquestion_button active"
                  : "upquestion_button" 
              }
              onClick={() => handleCategoryClick(index, item)}
            >
              {item.category}
            </button>
          ))}
        </div>
      )}
      <div className="upload_question_content">
        <Form onSubmit={handleSubmit}>
          <div className="upload_tab_list">
            {uploadQuestionnaireData.length > 0 ? 
            uploadQuestionnaireData?.map((item, index) => (
              // item.is_active
              <div
                className="upquestion_con_item"
                key={index}
                style={{ display: activeTab === index ? "block" : "none" }}
              >
                {/* <br></br>
                            question: {item.questions[0].id}<br></br>
                            option: {item.questions[0].option[0].id} */}
                <h4>
                  {!fileData?.uploads && item?.questions?.[0]?.ans && item?.questions?.[0]?.ans !== null ? (
                    <a
                    // href={
                    //   siteConfig.BASE_URL +
                    //   "/api/backend/questionnaire/uploads/images/" +
                    //   item.questions[0].ans
                    // }
                      href={
                        item.questions[0].file_full_path
                      }
                      target="_blank"
                    >
                      {" "}
                      Uploaded : {item.questions[0].ans}
                    </a>
                  ) : (
                    ""
                  )}

                  {fileData?.uploads && filename}
                </h4>

                <input
                  type="hidden"
                  value={item?.questions?.[0]?.id}
                  name="question"
                  id={`question_${item?.id}`}
                />
                <input
                  type="hidden"
                  value={item?.questions?.[0]?.option?.[0]?.id}
                  name="option"
                  id={`option_${item?.id}`}
                />
                <div className="dropzoneimage">
                  {/*  {...getRootProps()} */}
                  {/* <input {...getInputProps()} /> */}
                  {/* {userData["option_value"] ? (
                                        <Image
                                        alt="Profile"
                                        src={siteConfig.BASE_URL + userData.option_value}
                                        className="cover"
                                        id="option_value_preview"
                                    />
                                ) : ( */}
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      name="option_value"
                      id="profileUploader"
                      onChange={(e) => handleImageInput(e, item)}
                      className="uploadImg"
                      src=""
                    />
                    <div className="icon_titleimg">
                      <span className="icon">
                        <FileIcon />
                      </span>
                      <p>{item?.placeholder}</p>
                      <p id="filename">{item?.category}</p>
                      <span className="plane_text">
                        JPG,PNG or PDF, file size no more than 10MB
                      </span>
                    </div>
                    <aside className="upload_image">
                      {activeTab === index}
                    </aside>
                    {/* {userData.option_value !== '' ? (
                                <Image
                                    alt="Profile"
                                    className="cover"
                                    id="option_value_preview"
                                />
                                ) : ( 
                                    <></>
                                )} */}
                  </>
                  {/* )} */}
                  {!skipValidation && (
                    <div
                      className={
                        checkValidation?.option_value?.isInvalid
                          ? "animated fadeIn err-msg"
                          : ""
                      }
                    >
                      <div className="error">
                        {checkValidation?.option_value?.message}
                      </div>
                    </div>
                  )}
                </div>
                <a
                  className="download-icon"
                  href={DownloadImg}
                  download="download"
                >
                  <span className="icon">
                    <DownloadIcon />
                  </span>
                  Download sample file
                </a>
                <div className="button_group">
                  {!fileData?.uploads && item?.questions?.[0]?.is_required === false && (
                    <button
                      type="button"
                      className="skip_button"
                      onClick={handleSkip}
                    >
                      Skip
                    </button>
                  )}
                  {!fileData?.uploads &&
                  activeTab < uploadQuestionnaireData?.uploads?.length - 1 ? (
                    <button type="submit" className="next_button">
                      Next
                    </button>
                  ) : (
                    <button type="submit" className="next_button">
                      {" "}
                      Finish
                    </button>
                  )}
                </div>
              </div>
            ))
            : 
            
            uploadQuestionnaireData?.uploads?.length > 0 && 
            uploadQuestionnaireData?.uploads.map((item, index) => (
              // item.is_active
              <div
                className="upquestion_con_item"
                key={index}
                style={{ display: activeTab === index ? "block" : "none" }}
              >
                {/* <br></br>
                            question: {item.questions[0].id}<br></br>
                            option: {item.questions[0].option[0].id} */}
                <h4>
                  {!fileData?.uploads && item.questions[0].ans !== null ? (
                    <a
                      // href={
                      //   siteConfig.BASE_URL +
                      //   "/api/backend/questionnaire/uploads/images/" +
                      //   item.questions[0].ans
                      // }
                      href={
                        item.questions[0].file_full_path
                      }
                      target="_blank"
                    >
                      {" "}
                      Uploaded : {item.questions[0].ans}
                    </a>
                  ) : (
                    ""
                  )}

                  {fileData?.uploads && filename}
                </h4>

                <input
                  type="hidden"
                  value={item?.questions?.[0]?.id}
                  name="question"
                  id={`question_${item?.id}`}
                />
                <input
                  type="hidden"
                  value={item?.questions?.[0]?.option?.[0]?.id}
                  name="option"
                  id={`option_${item?.id}`}
                />
                <div className="dropzoneimage">
                  {/*  {...getRootProps()} */}
                  {/* <input {...getInputProps()} /> */}
                  {/* {userData["option_value"] ? (
                                        <Image
                                        alt="Profile"
                                        src={siteConfig.BASE_URL + userData.option_value}
                                        className="cover"
                                        id="option_value_preview"
                                    />
                                ) : ( */}
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      name="option_value"
                      id="profileUploader"
                      onChange={(e) => handleImageInput(e, item)}
                      className="uploadImg"
                      src=""
                    />
                    <div className="icon_titleimg">
                      <span className="icon">
                        <FileIcon />
                      </span>
                      <p>{item?.placeholder}</p>
                      <p id="filename">{item?.category}</p>
                      <span className="plane_text">
                        JPG,PNG or PDF, file size no more than 10MB
                      </span>
                    </div>
                    <aside className="upload_image">
                      {activeTab === index}
                    </aside>
                    {/* {userData.option_value !== '' ? (
                                <Image
                                    alt="Profile"
                                    className="cover"
                                    id="option_value_preview"
                                />
                                ) : ( 
                                    <></>
                                )} */}
                  </>
                  {/* )} */}
                  {!skipValidation && (
                    <div
                      className={
                        checkValidation?.option_value?.isInvalid
                          ? "animated fadeIn err-msg"
                          : ""
                      }
                    >
                      <div className="error">
                        {checkValidation?.option_value?.message}
                      </div>
                    </div>
                  )}
                </div>
                <a
                  className="download-icon"
                  href={DownloadImg}
                  download="download"
                >
                  <span className="icon">
                    <DownloadIcon />
                  </span>
                  Download sample file
                </a>
                <div className="button_group">
                  {!fileData?.uploads && item?.questions?.[0]?.is_required === false && (
                    <button
                      type="button"
                      className="skip_button" 
                      onClick={handleSkip}
                    >
                      Skip
                    </button>
                  )}
                  {!fileData?.uploads &&
                  activeTab < uploadQuestionnaireData?.uploads?.length - 1 ? (
                    <button type="submit" className="next_button">
                      Next
                    </button>
                  ) : (
                    <button type="submit" className="next_button">
                      {" "}
                      Finish
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UploadTab;
