import { Navigate, Route, Routes } from 'react-router-dom';
import ConLeadListing from '@/pages/Consultant/ConLeadListing';
import ConsultantDetails from '@/pages/Consultant/ConsultantDetails';
import ConsultantHeader from '@/components/consultant/ConsultantHeader';
import PropTypes from 'prop-types';
import { UserRoles } from '@/utils/_gConstant';
import { useSelector } from 'react-redux';

const ConsultantRoute = () => {
  const userRole = useSelector((state) => state.authReducer?.user?.role);
  if (![UserRoles.CONSULTANT].includes(userRole)) {
    return <Navigate to='/' />;
  }

  return (
    <Routes>
      <Route path='/' element={<ConsultantHeader title='App | IKOSIA' />}>
        <Route path='leads/' element={<ConLeadListing title='App | IKOSIA' />} index />
        <Route path='leads/details/:leadId/' element={<ConsultantDetails title='App | IKOSIA' />} />
        <Route path='/' element={<Navigate to='leads/' replace />} />
      </Route>
    </Routes>
  );
};

ConsultantRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

export default ConsultantRoute;
