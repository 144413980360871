import React from 'react'

const Formcomponent = ({
    as,
    type,
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    htmlFor,
    id,
    checked
  }) => {
    const renderInput = () => {
        switch (as?.kind) {
          case 1:
            return (
              <div className="input_group">
                <input
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <label
                  htmlFor={htmlFor}
                >
                  {placeholder}
                </label>
              </div>
            );
          case 2:
            return (
              <div className="radio_group">
                <label
                  htmlFor={htmlFor}
                >
                  <input
                    type={type}
                    name={name}
                    id={id}
                    checked={
                       checked
                    }
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  <span className="radiobox"></span>
                  {as?.image_url && (
                    <span className="images_box">
                      <img
                        src={as?.image_url}
                        alt={as?.text}
                      />
                    </span>
                  )}
                  <span className="radio_title">
                    {as?.text}
                  </span>
                </label>
              </div>
            );
          case 3:
            return (
              <div className="input_group">
                <input
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  id={id}
                />
                <label
                  htmlFor={htmlFor}
                ></label>
              </div>
            );
          case 4:
            return (
              <div>
                <label
                  htmlFor={htmlFor}
                >
                  <input
                    type={type}
                    name={name}
                    id={id}
                  />
                  {as?.img && (
                    <span className="images_box">
                      <img
                        src={as?.img}
                        alt={as?.labelName}
                      />
                    </span>
                  )}
                  <span className="radio_title">
                    {as?.labelName}
                  </span>
                </label>
              </div>
            );
          default:
            return null;
        }
      };
    
      return <div className="form_group">{renderInput()}</div>;
    };


export default Formcomponent