import docFile from '../../assets/icons/doc-file.svg';
import downloadIcon from '../../assets/icons/download.svg';
import pdfFile from '../../assets/icons/pdf-file.svg';
import trashIcon from '../../assets/icons/trash.svg';
import { useNavigate } from "react-router-dom";

import React, { useCallback, useEffect, useState } from 'react';
import DeleteDocument from "./DeleteDocument";
import { ENUM_API_STATUS } from "../../utils/_gConstant";
import { getDoc } from "../../components/auth/core/_request";

import PageLoader from "../../components/dashboard/PageLoader";
import UploadTab from "../../components/dashboard/UploadTabs";

const Document = ({ leadData, Loading ,setLoading, handleShowlodaer, setLeadData}) => {
  const navigate = useNavigate();
  const id = leadData?.id;
  const [loader, setLoader] = useState(false);
  const [filelength] = useState();
  const [fileData, setFileData] = useState([]);
  const [selectisfpdocument, setIspfdocument] = useState(false);
  const getdocuments = useCallback(() => {
    setLoader(true);
    getDoc(id).then((r) => {
      if (r.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
        setFileData(r.data.data);
        setLoader(false);
      } else {
        navigate("/");
        setLoader(false);
      }
    });
  }, [id, navigate]);

  useEffect(() => {
    getdocuments();
  }, [getdocuments]);
  const [selectedDocument, setSelectedDocument] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDownload = (filePath) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
    const fileExtension = filePath.split(".").pop().toLowerCase();
    if (imageExtensions.includes(fileExtension)) {
      const link = document.createElement("a");
      link.href = filePath;
      link.download = filePath.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(filePath, "_blank");
    }
  };

  const getFileIcon = (filePath) => {
    const extension = filePath?.split(".").pop();
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
        return filePath;
      case "pdf":
        return pdfFile;
      case "doc":
      case "docx":
        return docFile;
      default:
        return "";
    }
  };

  const openDeleteModal = (doc) => {
    setIsDeleteModalOpen(doc);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

 

  return (
    <>
      {loader ? (
        // eslint-disable-next-line react/style-prop-object
        <PageLoader style="apply" className="document_class_loader" />
      ) : (
        <>
          <div className="maintab_title flex items-center justify-between">
            <h2 className="text-lg font-semibold">All Documents</h2>
          </div>
          <div className="admin_questions documents_question">
            <ul className="questions_main">
              {fileData &&
                fileData?.uploads &&
                fileData?.uploads.length > 0 &&
                fileData?.uploads?.map((item, index) => (
                  <li
                    key={item.id}
                    className={`questions ${
                      selectedDocument === index ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedDocument(index);
                      setIspfdocument(false);
                    }}
                  >
                    {item.category}
                  </li>
                ))}

              {fileData?.isfp_document && (
                <li
                  onClick={() => {
                    setIspfdocument(true);
                    setSelectedDocument(false);
                  }}
                  className={`questions ${selectisfpdocument ? "active" : ""}`}
                >
                  iSFP Document
                </li>
              )}
            </ul>
            <div className='admin_documents_upload'>
              {fileData &&
                fileData?.uploads &&
                fileData?.uploads?.length > 0 &&
                fileData?.uploads[selectedDocument] &&
                fileData?.uploads[selectedDocument]?.questions.map(
                  // eslint-disable-next-line array-callback-return
                  (item, index) => {
                    const fileName = item?.file?.file_full_path
                      .split("/")
                      .pop();
                    const fileIcon = getFileIcon(item?.file?.file_full_path);
                    if (selectedDocument === index && item?.file) {
                      return (
                        <div
                          className={
                            fileData?.isfp_document
                              ? "admin_formsnswers_list new_document"
                              : "admin_formsnswers_list"
                          }
                          // style={{ minWidth: "75%" }}
                        >
                          <ul key={index} className="documents-list">
                            <li key={item.id} className="documents-item">
                              <div className="documents-info">
                                <div className="docs-icon">
                                  <img src={fileIcon} alt={fileName} />
                                </div>
                                <div className="documents-info-wrapper">
                                  <h3 className="docs_name">{fileName}</h3>
                                  <div className="size_date_ineer">
                                    <span className="docs-size">
                                      {item?.file?.file_size}
                                    </span>
                                    <span className="docs-date">
                                      {item?.file?.creation_date}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="action_icon">
                                <button
                                  className="file_download"
                                  onClick={() =>
                                    handleDownload(item?.file?.file_full_path)
                                  }
                                >
                                  <img src={downloadIcon} alt="downloadIcon" />
                                </button>
                                <button
                                  className="delete_button"
                                  onClick={() => openDeleteModal(item)}
                                >
                                  <img src={trashIcon} alt="deleteIcon" />
                                </button>
                              </div>
                            </li>
                          </ul>
                        </div>
                      );
                    }
                  }
                )}
              {fileData.isfp_document && selectisfpdocument && (
                <div className="admin_formsnswers_list new_document upload_isfp">
                  <ul className="documents-list">
                    <li className="documents-item new_isfp_upload">
                      <div className="documents-info">
                        <div className="docs-icon">
                          <img
                            src={getFileIcon(
                              fileData.isfp_document?.file_full_path
                            )}
                            alt={fileData.isfp_document?.file_full_path
                              ?.split("/")
                              .pop()}
                          />
                        </div>
                        <div className="documents-info-wrapper">
                          <h3 className="docs_name">
                            {fileData.isfp_document?.file_full_path
                              ?.split("/")
                              .pop()}
                          </h3>
                          <div className="size_date_ineer">
                            <span className="docs-size">
                              {fileData?.isfp_document?.file_size}
                            </span>
                            <span className="docs-date">
                              {fileData?.isfp_document?.creation_date}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="action_icon">
                        <button
                          className="file_download"
                          onClick={() =>
                            handleDownload(
                              fileData.isfp_document?.file_full_path
                            )
                          }
                        >
                          <img src={downloadIcon} alt="downloadIcon" />
                        </button>
                        <button
                          className="delete_button"
                          onClick={() => openDeleteModal(fileData)}
                        >
                          <img src={trashIcon} alt="deleteIcon" />
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
               <UploadTab
              fileData={fileData}
              styled="apply"
              getdocuments={getdocuments}
              filelength={filelength}
              selectedDocument={selectedDocument}
              id={id}
            />
            </div>
           
          </div>
          {isDeleteModalOpen && (
            <DeleteDocument
            setshow={setLoading}
            setLeadData={setLeadData}
              open={isDeleteModalOpen}
              handleShowlodaer={handleShowlodaer}
              getdocuments={getdocuments}
              closeModal={closeDeleteModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default Document;
