const UserIcon = ({ light }) => {
    return (
        <>
            {light ? (<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1017_1230)">
                    <path d="M14.6693 16.5V14.8333C14.6693 13.9493 14.3181 13.1014 13.693 12.4763C13.0678 11.8512 12.22 11.5 11.3359 11.5H4.66927C3.78522 11.5 2.93737 11.8512 2.31225 12.4763C1.68713 13.1014 1.33594 13.9493 1.33594 14.8333V16.5" stroke="#00BD1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.9974 8.16667C9.83835 8.16667 11.3307 6.67428 11.3307 4.83333C11.3307 2.99238 9.83835 1.5 7.9974 1.5C6.15645 1.5 4.66406 2.99238 4.66406 4.83333C4.66406 6.67428 6.15645 8.16667 7.9974 8.16667Z" stroke="#00BD1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_1017_1230">
                        <rect width="16" height="18" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            ) : (
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1017_1230)">
                        <path d="M14.6693 16.5V14.8333C14.6693 13.9493 14.3181 13.1014 13.693 12.4763C13.0678 11.8512 12.22 11.5 11.3359 11.5H4.66927C3.78522 11.5 2.93737 11.8512 2.31225 12.4763C1.68713 13.1014 1.33594 13.9493 1.33594 14.8333V16.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.9974 8.16667C9.83835 8.16667 11.3307 6.67428 11.3307 4.83333C11.3307 2.99238 9.83835 1.5 7.9974 1.5C6.15645 1.5 4.66406 2.99238 4.66406 4.83333C4.66406 6.67428 6.15645 8.16667 7.9974 8.16667Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1017_1230">
                            <rect width="16" height="18" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            )}
        </>
    );
}

export default UserIcon;