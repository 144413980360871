import '../../../assets/styles/scss/pages/dp-dashboard.scss';

import { ENUM_API_STATUS, ENUM_DP_USER_STATUS } from '@/utils/_gConstant';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../components/Header';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import activityIcon from '../../../assets/icons/activity-icon.svg';
import alertInfo from '../../../assets/icons/alert-info.svg';
import alertInfoError from '../../../assets/icons/alert-info-red.svg';
import arrowLeft from '../../../assets/icons/chevron-left.svg';
import cancelLeft from '../../../assets/icons/cancel-left.svg';
import { getIndividualDpUserData } from '../store/request';
import { getInitials } from '@/utils/_gFunctions/_handleGetInitials';
import lineChart from '../../../assets/icons/line-chart-up.svg';
import picChart from '../../../assets/icons/pie-chart.svg';
import { resetDPUser } from '../store/_actions';
import { useTranslation } from 'react-i18next';

const DPUsersProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const DpUserData = useSelector((state) => state?.dpuserDetails?.details);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const getDPuserData = async () => {
    const res = await getIndividualDpUserData(DpUserData?.id);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
      setData(res?.data);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getDPuserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dashbaordData = [
    {
      id: 1,
      icon: activityIcon,
      title: t('open_orders'),
      count: data?.open_orders?.value,
    },
    {
      id: 2,
      icon: alertInfo,
      title: t('signature_missing'),
      count: data?.signature_missing?.value,
    },
    {
      id: 3,
      icon: alertInfoError,
      title: t('signature_missing_team'),
      count: data?.signature_missing_team?.value,
    },
    {
      id: 4,
      icon: alertInfoError,
      title: t('correction_required'),
      count: data?.correction_required?.value,
    },
    {
      id: 5,
      icon: cancelLeft,
      title: t('closed_orders'),
      count: data?.cancel_rate_total_sales?.value,
    },
    {
      id: 6,
      icon: lineChart,
      title: t('total_sales'),
      count: data?.total_sales?.value,
    },
    {
      id: 7,
      icon: lineChart,
      title: t('daily_turnover_own'),
      count: data?.daily_turnover_own?.value,
    },
    {
      id: 8,
      icon: lineChart,
      title: t('daily_turnover_team'),
      count: data?.daily_turnover_business?.value,
    },
    {
      id: 10,
      icon: picChart,
      title: t('cancel_rate_total_sales'),
      count: data?.cancel_rate_total_sales?.value,
    },
  ];

  return (
    <section className='page-bg'>
      <Header />
      <Sidebar />
      {isLoading && (
        <div className='loader-wrapper'>
          <div className='loader'></div>
        </div>
      )}
      {!isLoading && (
        <main className='page-main'>
          <div className='max-container'>
            <Link to='/dp/users/' onClick={() => dispatch(resetDPUser())}>
              <div className='flex items-center mt-8 cursor-pointer'>
                <img src={arrowLeft} alt='arrow-left' />
                <span className='roboto-font text-black font-regular ml-5'>{t('Go back')}</span>
              </div>
            </Link>

            <div className='dp-users-profile__card bg-white rounded-10 p-6 mt-5'>
              <div className='flex items-start'>
                <figure
                  className='dp-users-profile__pic flex items-center justify-center flex-shrink-0 rounded-full'
                  style={{
                    backgroundColor: '#FF767B',
                    border: '1px solid #F3F2EC',
                  }}
                >
                  <p className='text-white text-32 font-large'>
                    {getInitials(DpUserData?.first_name)}
                  </p>
                </figure>
                <div className='ml-4'>
                  <h2 className='text-32 font-medium'>
                    {DpUserData?.first_name} {DpUserData?.last_name}
                  </h2>
                  <div className='flex items-start flex-col sm-flex-row mt-3 -mx-5'>
                    <div className='md-col-6 px-5'>
                      {DpUserData.status === ENUM_DP_USER_STATUS.ACTIVE ? (
                        <span className='roboto-font text-base text-green'>Active</span>
                      ) : DpUserData.status === ENUM_DP_USER_STATUS.PENDING ? (
                        <span className='roboto-font text-base text-warning '>Pending</span>
                      ) : DpUserData.status === ENUM_DP_USER_STATUS.INACTIVE ? (
                        <span className='roboto-font text-base text-red'>Inactive</span>
                      ) : null}
                      <p className='roboto-font text-base mt-2'>{DpUserData?.email}</p>
                      <p className='roboto-font text-base mt-2'>
                        {DpUserData?.no_of_clients} clients
                      </p>
                    </div>
                    <div className='md-col-6 px-5 mt-4 sm-mt-0'></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-10'>
              <h3 className='text-xl font-medium'>KPI's</h3>
              <ul className='flex flex-wrap xl-flex-no-wrap -mx-2 mt-2'>
                {dashbaordData?.map((item, index) => (
                  <li className='card-list px-2 my-2'>
                    <div className='h-full p-4 bg-white rounded-10'>
                      <div className='flex items-center'>
                        <img src={item.icon} alt='activity-icon' />
                        <span className='roboto-font text-sm font-light ml-3'>{item.title}</span>
                      </div>
                      <div className='text-green text-2xl lg-text-36 text-right mt-8'>
                        {item.count}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </main>
      )}
    </section>
  );
};

export default DPUsersProfile;
