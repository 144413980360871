import React, { useEffect, useState } from 'react';
import Header from "./dashboard/Headre";
import PageLoader from "./dashboard/PageLoader";

const Layout = ({ children }) => {
    const [loading, setLoading] = useState(true); 
    useEffect(() => {
        setTimeout(() => {
            setLoading(false); 
        }, 2000); 
    }, []);
    return (
        <div className="deas_leayout">
            {loading && <PageLoader />} 
            <Header />
            <div className="main-dash_content">
               {children} 
            </div>
        </div>
    );
};

export default Layout;
