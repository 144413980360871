import "react-datepicker/dist/react-datepicker.css";
import {
  AssignConsultantGetRequest,
  tagsAllGetRequest,
} from "../../pages/Admin/_request";
import { City, Country } from "country-state-city";
import React, { 
  useEffect,
  useState, 
} from "react";
import DatePicker from "react-datepicker";
import { ENUM_API_STATUS } from "../../utils/_gConstant";
import Loader from "../common/Loader";
import Select from "react-select";
import close from "../../assets/icons/close.svg";
import dayjs from "dayjs";
import { editLead } from "../../pages/Admin/_request";
import { editListViewLead } from "../../pages/Admin/store/action";
import { toastSuccess } from "../ui-elements/_Toastify";
import { useDispatch } from "react-redux";

const ConsultantAddEditLead = (props) => {
  const dispatch = useDispatch();
  const { onClose, title, editmodal, setLeadData, getleads } = props;
  console.log("Propdfdjfdfjdsd", editmodal);
  const [formData, setFormData] = useState({
    stages: editmodal?.stages || editmodal?.stageId,
    first_name: editmodal?.first_name ? editmodal.first_name : "",
    last_name: editmodal?.last_name ? editmodal?.last_name : "",
    enquiry_date: editmodal?.enq_date
      ? dayjs(editmodal?.enq_date).toDate()
      : null,
    country: editmodal?.country
      ? {
          label: editmodal?.country,
          value: editmodal?.country,
        }
      : null,
    mobile_number: editmodal?.mobile_number ? editmodal?.mobile_number : "",
    city: editmodal?.city
      ? {
          label: editmodal?.city,
          value: editmodal?.city,
        }
      : null,
    email: editmodal?.email ? editmodal?.email : "",
    postal_code: editmodal?.postal_code ? editmodal?.postal_code : "",
    consultant_id: editmodal?.consultant_assigned
      ? {
          label: `${editmodal?.consultant_assigned?.first_name} ${editmodal?.consultant_assigned?.last_name}`,
          value: editmodal?.consultant_assigned?.id,
        }
      : null,
    distributor_partner: editmodal?.distributor_partner
      ? editmodal?.distributor_partner
      : "",
    distributor_agent: editmodal?.distributor_agent
      ? editmodal?.distributor_agent
      : "",
    tags: editmodal?.tags?.map((tag) => {
      return {
        label: tag,
        value: tag,
      };
    }),
  });
  const [loader, setLoader] = useState(false);
  const [consultantData, setConsultantData] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const getAssignConsultant = async () => {
    const res = await AssignConsultantGetRequest();
    console.log("jhjhjlhjfgfgfgf;gf", res);
    if (res?.status === ENUM_API_STATUS.SUCCESS_GET_API) {
      setConsultantData(res?.data);
    }
  };
  const gettagList = async () => {
    const res = await tagsAllGetRequest();
    if (res?.status == ENUM_API_STATUS?.SUCCESS_GET_API) {
      setTagList(res?.data);
    }
  };

  useEffect(() => {
    getAssignConsultant();
    gettagList();
  }, []);

  useEffect(() => {
    const fetchCityOptions = async () => {
      try {
        if (formData.country) {
          const selectedCountry = Country.getAllCountries()?.find(
            (country) => country.name === formData.country.label
          );

          if (selectedCountry) {
            const cities = City?.getCitiesOfCountry(selectedCountry.isoCode);

            const cityOptions = cities?.map((city) => ({
              label: city.name,
              value: city.name,
            }));

            setCityOptions(cityOptions);

            const currentCityOption = cityOptions?.find(
              (option) => option.value === formData.city?.value
            );
            console.log("jkjkjjk", currentCityOption);
          } else {
            console.log("Invalid country name");
          }
        } else {
          setCityOptions([]);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
        setCityOptions([]);
      } 
    };

    fetchCityOptions();
  }, [formData]);

  console.log("jkjkjkjkfgfgjfgjfgjfgfjgfg", cityOptions);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Validate the field
    const error = validateField(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({ ...prev, enquiry_date: date }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prev) => ({ ...prev, [name]: selectedOption }));
  };

  const handleMultiSelectChange = (selectedOptions) => {
    setFormData((prev) => ({ ...prev, tags: selectedOptions }));
  };

  const handleSave = async () => {
    setLoader(true)
    // Validate all fields
    const newErrors = {
      first_name: validateField("first_name", formData.first_name),
      last_name: validateField("last_name", formData.last_name),
      mobile_number: validateField("mobile_number", formData.mobile_number),
      email: validateField("email", formData.email),
    };

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      return;
    }

    const cityLabel = formData?.city?.label || null;
    const countryLabel = formData?.country?.label || null;
    const tagsArray = Array.isArray(formData.tags)
      ? formData.tags.map((tag) => tag.value)
      : Array.isArray(formData.tags?.value)
      ? formData.tags.value
      : [];
    const consultantIdValue = formData.consultant_id
      ? formData.consultant_id.value
      : null;

    const value = {
      ...formData,
      city: cityLabel,
      country: countryLabel,
      tags: tagsArray,
      enq_date: dayjs(formData.enquiry_date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      consultant_id: consultantIdValue,
    };

     const res = await editLead(editmodal?.id, value);
     if (res?.status === ENUM_API_STATUS.SUCCESS_PATCH_API) {
       setLeadData(res.data);
       dispatch(
         editListViewLead({ ...value, consultant_id: consultantIdValue })
       );
       getleads(1, true, "");
        
       toastSuccess("Lead has been updated successfully!");
       onClose()
     }

  
   
    console.log("Form data submitted:", value);
  
}

  const handleClose = () => {
    onClose();
  };
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
  });
  const validateField = (name, value) => {
    switch (name) {
      case "first_name":
        return value.trim() === "" ? "First name is required" : "";
      case "last_name":
        return value.trim() === "" ? "Last name is required" : "";
      case "mobile_number":
        return !/^\+?\d{10,15}$/.test(value)
          ? "Mobile number is not valid"
          : "";
      case "email":
        return !/\S+@\S+\.\S+/.test(value) ? "Email is not valid" : "";
      default:
        return "";
    }
  };
  return (
    <>
      <div className="modal modal--add-lead open">
        <div className="modal__container">
          <div className="modal__header">
            <h1 className="model_title text-lg font-bold">
              {title === "edit" ? "Edit Lead" : "Add New Lead"}
            </h1>
            <div className="modal__close" onClick={handleClose}>
              <img src={close} alt="close" />
            </div>
          </div>
          <div className="modal__body">
            <div className="flex flex-wrap -mx-2">
              <div className="col-6 px-2 mb-4">
                <label className="input-field-label primary-font">
                  First name of the Lead
                </label>
                <input
                  type="text"
                  name="first_name"
                  className="input-field input-field--regular input-field--h40 border2 text-sm w-full lato"
                  placeholder="First Name of the Lead"
                  value={formData.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && (
                  <div className="error-message">{errors.first_name}</div>
                )}
              </div>
              <div className="col-6 px-2 mb-4">
                <label className="input-field-label primary-font">
                  Enquiry Date
                </label>
                <DatePicker
                  className="input-field input-field--regular input-field--h40 border2 text-sm w-full lato"
                  dateFormat="dd-MMM-yyyy"
                  selected={formData.enquiry_date}
                  onChange={handleDateChange}
                />
              </div>
              <div className="col-6 px-2 mb-4">
                <label className="input-field-label primary-font">
                  Last name of the Lead
                </label>
                <input
                  type="text"
                  name="last_name"
                  className="input-field input-field--regular input-field--h40 border2 text-sm w-full lato"
                  placeholder="Last Name of the Lead"
                  value={formData.last_name}
                  onChange={handleChange}
                />
                {errors.last_name && (
                  <div className="error-message">{errors.last_name}</div>
                )}
              </div>
              <div className="col-6 px-2 mb-4">
                <label className="input-field-label primary-font">
                  Country
                </label>
                <Select
                  name="country"
                  className="input-field--regular input-field--h40 border2 text-sm w-full lato"
                  placeholder="Select Country"
                  maxMenuHeight={150}
                  options={Country?.getAllCountries()?.map((country) => ({
                    label: country.name,
                    value: country.name,
                    isoCode: country.isoCode, // Add isoCode or any other required properties
                  }))}
                  value={formData.country}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="col-6 px-2 mb-4">
                <label className="input-field-label primary-font">
                  Mobile Number
                </label>
                <input
                  type="text"
                  name="mobile_number"
                  className="input-field input-field--regular input-field--h40 border2 text-sm w-full lato"
                  placeholder="+56 00 00 00 00"
                  value={formData.mobile_number}
                  onChange={handleChange}
                />
                {errors.mobile_number && (
                  <div className="error-message">{errors.mobile_number}</div>
                )}
              </div>
              <div className="col-6 px-2 mb-4">
                <label className="input-field-label primary-font">City</label>
                <Select
                  name="city"
                  className="input-field--regular input-field--h40 border2 text-sm w-full lato"
                  placeholder="Select City"
                  maxMenuHeight={150}
                  options={cityOptions}
                  value={formData.city}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="col-6 px-2 mb-4">
                <label className="input-field-label primary-font">
                  Email ID
                </label>
                <input
                  type="text"
                  name="email"
                  className="input-field input-field--regular input-field--h40 border2 text-sm w-full lato"
                  placeholder="abcd@gmail.com"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <div className="error-message">{errors.email}</div>
                )}
              </div>
              <div className="col-6 px-2 mb-4">
                <label className="input-field-label primary-font">
                  Post Code
                </label>
                <input
                  type="text"
                  name="postal_code"
                  className="input-field input-field--regular input-field--h40 border2 text-sm w-full lato"
                  placeholder="560102"
                  value={formData.postal_code}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6 px-2 mb-4">
                <label className="input-field-label primary-font">
                  Distribution Partner
                </label>
                <input
                  type="text"
                  name="distributor_partner"
                  value={formData.distributor_partner}
                  onChange={handleChange}
                  className="input-field input-field--regular input-field--h40 border2 text-sm w-full lato"
                  placeholder="Enter Distribution Partner"
                />
              </div>
              <div className="col-6 px-2 mb-4">
                <label className="input-field-label primary-font">
                  Distribution Agent
                </label>
                <input
                  type="text"
                  name="distributor_agent"
                  value={formData.distributor_agent}
                  onChange={handleChange}
                  className="input-field input-field--regular input-field--h40 border2 text-sm w-full lato"
                  placeholder="Enter Distribution Agent"
                />
              </div>
              <div className="col-6 px-2 mb-4">
                <label className="input-field-label primary-font">
                  Consultant
                </label>
                <Select
                  name="consultant_id"
                  className=""
                  placeholder="Select Consultant"
                  maxMenuHeight={100}
                  // options={consultantOptions}
                  options={consultantData?.map((consultant) => ({
                    label: `${consultant.first_name} ${consultant.last_name} - ${consultant.location}`,
                    value: consultant.id,
                  }))}
                  value={formData.consultant_id}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="col-12 px-2 mb-4">
                <label className="input-field-label primary-font">Tags</label>
                <Select
                  isMulti
                  name="tags"
                  className="input-field--regular input-field--h40 border2 text-sm w-full lato"
                  placeholder="Select Tags"
                  maxMenuHeight={150}
                  options={tagList?.map((tag) => ({ label: tag, value: tag }))}
                  // options={tagOptions}
                  value={formData.tags}
                  onChange={handleMultiSelectChange}
                />
              </div>
            </div>
          </div>
          <div className="modal__footer justify-end">
            <button
              type="button"
              className="btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn--primary btn--h40 px-10 py-2 text-base font-semibold relative"
              onClick={handleSave}
              disabled={loader}
            >
              {loader ? <Loader white size="xs" /> : "Save"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConsultantAddEditLead;
