const DownloadIcon = () => {
    return (
        <>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.66699 10.6363L2.66699 13.095C2.66699 13.4676 2.80747 13.825 3.05752 14.0885C3.30756 14.352 3.6467 14.5 4.00033 14.5H12.0003C12.3539 14.5 12.6931 14.352 12.9431 14.0885C13.1932 13.825 13.3337 13.4676 13.3337 13.095V10.6363M8.00107 2.5V10.4616M8.00107 10.4616L11.0487 7.41953M8.00107 10.4616L4.95345 7.41953" stroke="#00BD1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </>
    );
}

export default DownloadIcon;