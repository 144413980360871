export const globalConstant = Object.freeze({
  INTERNAL_SERVER_ERROR: 'Internal Server Error',
  UNAUTHORIZED: 'Unauthorized',
  NOT_FOUND: 'Not Found',
  INVALID_CREDENTIALS: 'Invalid credentials',
  USER_NOT_FOUND: 'User not found!',
  USER_ALREADY_PRESENT: 'User already present',
  DATA_NOT_SAVED: 'Data not saved!',
});

export const ENUM_API_STATUS = Object.freeze({
  SUCCESS_GET_API: 200,
  SUCCESS_POST_API: 201,
  SUCCESS_POST_API_200: 200,
  SUCCESS_PATCH_API: 200,
  SUCCESS_DELETE_API: 204,
  SUCCESS_PUT_API: 200,
  REDIRECT_302: 302,
  ERROR_400_BAD_REQ: 400,
  ERROR_401_BAD_REQ: 401,
  ERROR_403_BAD_REQ: 403,
  ERROR_404_BAD_REQ: 404,
  ERROR: 'error',
});

export const ENUM_DP_STATUS = Object.freeze({
  PENDING: 1,
  ACCEPTED: 2,
});

export const ENUM_DP_USER_STATUS = Object.freeze({
  PENDING: 1,
  ACTIVE: 2,
  INACTIVE: 3,
});

export const BuildingType = Object.freeze({
  UNKNOWN: 0,
  RESIDENTIAL: 1,
  COMMERCIAL: 2,
});

// Enum for LeadStage
export const LeadStage = Object.freeze({
  NEW_LEADS: 1,
  VISIT_PLANNED:2,
  VISITED:3,
  ISFP_INPROCESS: 4,
  ISFP_DONE:5,
  ISSUES: 6
});
export const LeadDPStage = Object.freeze({
  NEW_LEADS: 1,
  CALL_SCHEDULED:2,
  CONTACTED:3,
  CONTRACT_SIGNED: 4,
  CONSULTED_ASSIGNED:5,
  ROADMAP_READY: 6
});
export const Permissions = Object.freeze({
  ADD_BACKOPS_ADMIN: 'add_backops_admin',
  ADD_BACKOPS_USER: 'add_backops_user',
  ADD_DISTRIBUTOR: 'add_distributor',
  ADD_ENERGY_CONSULTANT: 'add_energy_consultant',
  ADD_LEAD: 'add_lead',
  ASSIGN_CONSULTANT_LEAD: 'assign_consultant_lead',
  ASSIGN_USER_LEAD: 'assign_user_lead',
  CHANGE_BACKOPS_ADMIN: 'change_backops_admin',
  CHANGE_BACKOPS_USER: 'change_backops_user',
  CHANGE_BACKOPS_MASTER: 'change_backops_master',
  CHANGE_DISTRIBUTOR: 'change_distributor',
  CHANGE_ENERGY_CONSULTANT: 'change_energy_consultant',
  CHANGE_LEAD: 'change_lead',
  DELETE_BACKOPS_ADMIN: 'delete_backops_admin',
  DELETE_BACKOPS_USER: 'delete_backops_user',
  DELETE_DISTRIBUTOR: 'delete_distributor',
  DELETE_ENERGY_CONSULTANT: 'delete_energy_consultant',
  DELETE_LEAD: 'delete_lead',
  MANAGE_TAGS: 'manage_tags',
  UPDATE_STAGE_LEAD: 'update_stage_lead',
  VIEW_BACKOPS_ADMIN: 'view_backops_admin',
  VIEW_BACKOPS_USER: 'view_backops_user',
  VIEW_DISTRIBUTOR: 'view_distributor',
  VIEW_ENERGY_CONSULTANT: 'view_energy_consultant',
  VIEW_LEAD: 'view_lead',
  ADD_CHILD_DP: 'add_child_distributor',
  DELETE_DP_MASTER: 'delete_dp_master',
  DELETE_DP_ADMIN: 'delete_dp_admin',
  DELETE_DP_USER: 'delete_dp_user',
  CHANGE_DP_ADMIN: 'change_dp_admin',
  CHANGE_DP_MASTER: 'change_dp_master',
  CHANGE_DP_USER: 'change_dp_user',
});

export const UserRoles = Object.freeze({
  DJANGO_ADMIN: 'admin',
  OPS_ADMIN: 'backops_admin',
  OPS_USER: 'backops_User',
  HOME_OWNER: 'home_owner',
  CONSULTANT: 'energy_consultant',
});

export const DPuserRoles = Object.freeze({
  DP_MASTER: 'dp_master',
  DP_ADMIN: 'dp_admin',
  DP_USER: 'dp_user',
});

export const DPuserRolesOptions = [
  { value: DPuserRoles.DP_ADMIN, label: 'DP Admin' },
  { value: DPuserRoles.DP_USER, label: 'DP User' },
];

export const SurveyTypes = Object.freeze({
  AREA: 0,
  FLOORS: 1,
  SHAPE: 2,
  WWR: 3,
  FLOOR_HEIGHT: 4,
  ZIP_CODE: 5,
  ADIABATIC_OPTION: 6,
  SETPOINT_TEMPERATURE: 7,
  SYSTEM: 8,
  CONSTRUCTED_YEAR :9,
})

// Arrays for options
export const surveyTypeOptions = [
  {value: SurveyTypes.AREA, label: 'area'},
  {value: SurveyTypes.FLOORS, label: 'floors'},
  {value: SurveyTypes.SHAPE, label: 'shape'},
  {value: SurveyTypes.WWR, label: 'percent-wall-windows'},
  {value: SurveyTypes.FLOOR_HEIGHT, label: 'floor-height'},
  {value: SurveyTypes.ZIP_CODE, label: 'zip-code'},
  {value: SurveyTypes.ADIABATIC_OPTION, label: 'adiabatic-option'},
  {value: SurveyTypes.SETPOINT_TEMPERATURE, label: 'setpoint-temperature'},
  {value: SurveyTypes.SYSTEM, label: 'heating-system'},
  {value: SurveyTypes.CONSTRUCTED_YEAR, label: 'year'}
]

export const buildingTypeOptions = [
  { value: BuildingType.UNKNOWN, label: "Unknown" },
  { value: BuildingType.RESIDENTIAL, label: "Residential" },
  { value: BuildingType.COMMERCIAL, label: "Commercial" },
];

export const leadStageOptions = [
  { value: LeadStage.NEW_LEADS, label: "New Lead" },
  { value: LeadStage.VISIT_PLANNED, label: "Visited Planned" },
  { value: LeadStage.VISITED, label: "Visited" },
  { value: LeadStage.ISFP_INPROCESS, label: "iSFP Inprogess" },
  { value: LeadStage.ISFP_DONE, label: "iSFP Done" },
  { value: LeadStage.ISSUES, label: "Issues" },
];

export const UserRolesOptions = [
  { value: UserRoles.OPS_ADMIN, label: "Operational Admin"},
  { value: UserRoles.OPS_USER, label: "Operational User"}
]

export const leadStageColors = {
  [LeadStage.NEW_LEADS]: {
    color: "#AD28C3",
    backgroundColor: "rgba(189, 14, 202, 0.2)",
  },
  [LeadStage.VISIT_PLANNED]: {
    color: "#2239B5",
    backgroundColor: "rgba(26, 58, 188, 0.2)",
  },
  [LeadStage.VISITED]: {
    color: "#0688A4",
    backgroundColor: "rgba(0, 218, 255, 0.2)",
  },
  [LeadStage.ISFP_INPROCESS]: {
    color: "#C80000",
    backgroundColor: "rgba(252, 97, 40, 0.2)",
  },
  [LeadStage.ISFP_DONE]: {
    color: "#4254F2",
    backgroundColor: "rgba(66, 84, 242, 0.19)",
  },
  [LeadStage.ISSUES]: {
    color: "#1F7B10",
    backgroundColor: "rgba(107, 173, 91, 0.2)",
  },
  // Add more stages as needed
};

// Alternatively, objects for options
const buildingTypeOptionsObj = {
  [BuildingType.UNKNOWN]: "Unknown",
  [BuildingType.RESIDENTIAL]: "Residential",
  [BuildingType.COMMERCIAL]: "Commercial",
};

export const leadStageOptionsObj = {
  [LeadStage.NEW_LEADS]: "New Lead",
  [LeadStage.VISIT_PLANNED]: "Visited Planned",
  [LeadStage.VISITED]: "Visited",
  [LeadStage.ISFP_INPROCESS]: "iSFP Inprogess",
  [LeadStage.ISFP_DONE]: "iSFP Done",
  [LeadStage.ISSUES]: "Issues",
};

export const emptyProxyObject = new Proxy(
  {},
  {
    get: () => {
      return emptyProxyObject;
    },
  }
);

export const isEmpty = (val) => {
  if (val === emptyProxyObject) return true;
  if (val === undefined) return true;
  if (
    typeof val == 'function' ||
    typeof val == 'number' ||
    typeof val == 'boolean' ||
    Object.prototype.toString.call(val) === '[object Date]'
  )
    return false;
  if (val == null || val.length === 0)
    // null or 0 length array
    return true;
  if (typeof val == 'object') if (Object.keys(val).length === 0) return true;
  return false;
};

export const CLIENT_TYPE = [
  { label: 'Private', value: 'B2C' },
  { label: 'Business', value: 'B2B' },
];

export const ENUM_LEAD_CREATED_FROM = Object.freeze({
  BACKOPS: 'backops',
  LANDING_PAGE: 'landing_page',
  DISTRIBUTOR: 'distributor',
});

export const ORDER_STATUS = {
  CREATED: 'CREATED',
  CORRECTION_REQUIRED: 'CORRECTION REQUIRED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const VALIDATION_REGEX = {
  ALPHANUMERIC: /^[a-zA-Z0-9 ]+$/,
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PHONE_NUMBER: /^\+?(\d{1,3})?[-. (]*(\d{1,4})[-. )]*(\d{1,4})[-. ]*(\d{1,9})$/,
  POSITIVE_INTEGER: /^[1-9]\d*$/,
  POSTAL_CODE: /^\d{5,6}$/,
};
