import React, { useCallback, useEffect, useState } from "react";

import close from "../../assets/icons/close.svg";
import exportIcon from "../../assets/icons/export.svg";
import siteConfig from "../../services/_siteConfig";

import { toastError, toastSuccess} from "../../components/ui-elements/_Toastify";
import Formcomponent from "../../components/common/Formcomponent";
import Loader from "../../components/common/Loader";
import PageLoader from "../../components/dashboard/PageLoader";

import { ENUM_API_STATUS } from "../../utils/_gConstant";
import { getDetail } from "../../components/auth/core/_request";
import { submitAnswers } from "../../components/auth/core/_request";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const FormAnswers = ({ leadData }) => {
  const [loader, setLoader] = useState(false);
  const [questiondata, setquestiondata] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [followUpQuestion,setFollowUpQuestion] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const leadId = useSelector((state) => state?.authReducer?.user?.lead_id)


  const [submitAnsData, setSubmitAnsData] = useState({
    saving: false,
    token: localStorage.getItem(siteConfig.ACCESS_TOKEN),
    option_value: "",
    option_values: {},
    option: "",
    question: "",
    follow_up_option_value: "",
    follow_up_option: "",
  });

  const getformdata = useCallback(() => {
    setLoader(true);
    const id = leadData?.id;
    if (id) {
      getDetail(id).then((r) => {
        if (r.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
          setquestiondata(r.data.data);
          setLoader(false);
          const activeTabIndex = r.data.data.findIndex((tab) => tab.is_active);
          setActiveTab(activeTabIndex !== -1 ? activeTabIndex : 0);

          const activeQuestionIndex = r.data.data[
            activeTabIndex
          ]?.questions.findIndex((question) => question.is_active);
          setCurrentQuestionIndex(
            activeQuestionIndex !== -1 ? activeQuestionIndex : 0
          );
        } else {
          setLoader(false);

          navigate("/");
        }
      });
    }
  }, [leadData?.id, navigate]);
  useEffect(() => {
    getformdata();
  }, [getformdata]);

  useEffect(() => {
    // Check if there is a previously submitted answer for the current question
    const submittedOptionValue =
      questiondata[activeTab]?.questions[currentQuestionIndex]?.ans;
    const optionId =
      questiondata[activeTab]?.questions[currentQuestionIndex]?.option[0]?.id; //default first selected option id
    if (submittedOptionValue !== null) {
      setSubmitAnsData((prevState) => ({
        ...prevState,
        option_value: submittedOptionValue,
        question: questiondata[activeTab]?.questions[currentQuestionIndex]?.id,
        option: optionId,
      }));
      const selectedOption = questiondata[activeTab]?.questions[
        currentQuestionIndex
      ]?.option.find((option) => option.text === submittedOptionValue);

      if (
        selectedOption?.follow_up_question &&
        selectedOption?.follow_up_question?.ans !== ""
      ) {
        setFollowUpQuestion(selectedOption?.follow_up_question);

        setSubmitAnsData((prevState) => ({
          ...prevState,
          follow_up_option_value: selectedOption?.follow_up_question?.ans,
        }));
      }

      if (questiondata[activeTab]?.questions.find((it) => it.ans)) {
        questiondata[activeTab]?.questions?.forEach((it, index) => {
          const followup = it?.option.find((it) => it?.follow_up_question);
          if (followup) {
            // console.log("hjhjhljhjl");
            // setFollowUpQuestion(questiondata[activeTab]?.questions[index]);
            // setSubmitAnsData((prevState) => ({
            //   ...prevState,
            //   follow_up_option_value: selectedOption?.follow_up_question?.ans,
            // }));
          }
        });
      }
    }
  }, [questiondata, activeTab, currentQuestionIndex, followUpQuestion]);

  const handleClose = () => {
    setShow(false);
    setSubmitAnsData({
      saving: false,
      token: localStorage.getItem(siteConfig.ACCESS_TOKEN),
      option_value: "",
      option_values: {},
      option: "",
      question: "",
      follow_up_option_value: "",
      follow_up_option: "",
    });
    setLoading(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      option_value: "",
    },
    onSubmit: (value, { setSubmitting, resetForm }) => {
      setLoading(true);

      if (submitAnsData?.follow_up_option !== "") {
        const follwupdata = {
          option_value: submitAnsData.follow_up_option_value,
          option: submitAnsData.follow_up_option,
          question: submitAnsData.question,
          is_skip: 0,
          lead_id: leadData?.id,
        };

        submitAnswers(follwupdata).then((r) => {
          if (r.status === ENUM_API_STATUS.SUCESS_POST_API_200) {
            toastSuccess("Follow-up answer saved successfully.");
            setShow(false);
            setFollowUpQuestion(null); // Clear follow-up question after submission

            resetForm();
            getformdata();
            setLoading(false);
          } else {
            toastError(r?.data);
          }
        });
      } else {
        const formdata = new FormData();

        // Conditionally append 'option_value' based on the existence of submitAnsData.option_values[submitAnsData.option]
        formdata.append(
          "option_value",
          String(submitAnsData.option_values[submitAnsData.option]) ??
            String(submitAnsData.option_value)
        );

        // Append 'option'
        formdata.append("option", String(submitAnsData.option));

        // Append 'question'
        formdata.append("question", String(submitAnsData.question));

        // Append 'is_skip'
        formdata.append("is_skip", "0");

        // Append 'lead_id'
        formdata.append("lead_id", leadData?.id);
        if (submitAnsData.option_value !== "") {
          submitAnswers(formdata)
            .then((r) => {
              if (r.status === ENUM_API_STATUS.SUCESS_POST_API_200) {
                toastSuccess("Answer saved successfully.");
                setShow(false);
                // Check if there is a follow-up question
                const selectedOption = questiondata[activeTab]?.questions[
                  currentQuestionIndex
                ]?.option.find((option) => option.id === submitAnsData.option);
                if (selectedOption?.follow_up_question) {
                  setFollowUpQuestion(selectedOption.follow_up_question);
                  // Set the follow-up answer in submitAnsData
                  setSubmitAnsData((prevState) => ({
                    ...prevState,
                    follow_up_option_value:
                      selectedOption.follow_up_question.ans,
                  }));
                } else {
                  // No follow-up question, proceed to next question or tab
                  const newIndex = currentQuestionIndex + 1;
                  // handleQuestionClick(newIndex);
                  resetForm();
                  getformdata();
                  if (
                    activeTab === questiondata.length - 1 &&
                    newIndex === questiondata[activeTab]?.questions?.length
                  ) {
                    navigate(`/homeowner/${leadId}/`);

                    
                  } else if (
                    newIndex === questiondata[activeTab]?.questions?.length
                  ) {
                    setActiveTab(activeTab + 1);
                  }
                }
              } else {
                toastError(r.data);
              }
            })
            .catch((err) => {
              console.log("Dzsdsfbsfsdfdfjdsfsd", err);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    },
  });

  const handleCategoryClick = useCallback(
    (categoryIndex) => {
      const activeQuestionIndex = questiondata[
        categoryIndex
      ]?.questions.findIndex((question) => question.is_active);
      setActiveTab(categoryIndex);
      setSubmitAnsData((prevState) => ({
        ...prevState,
        is_skip: 0, // Set is_skip to 0
      }));
      setCurrentQuestionIndex(
        activeQuestionIndex !== -1 ? activeQuestionIndex : 0
      );
      setFollowUpQuestion(null); // Reset follow-up question on question change
    },
    [questiondata, followUpQuestion]
  );
  const handelChange = (event, item, qid) => {
    const { name, value } = event.target;

    setSubmitAnsData((prevState) => ({
      ...prevState,
      option_values: {
        ...prevState.option_values,
        [item.id]: value, // Update the value for the specific option
      },
      question: qid?.id,
      option_value: value,
      follow_up_option_value: value,
      option: name === "option_value" ? item.id : prevState.option, // Update option only if it's the main question
      follow_up_option:
        name === "follow_up_option_value"
          ? item.id
          : prevState.follow_up_option, // Update follow-up option only if it's follow-up question
    }));
  };
  const handleRadioChange = (event, item, qid) => {
    const { name, value } = event.target;
    if (value) {
      setShow(true);
    }
    setSubmitAnsData((prevState) => ({
      ...prevState,
      option_values: {
        ...prevState.option_values,
        [item.id]: value, // Update or add the selected value for the specific option
      },
      question: qid?.id,
      option_value: value,
      follow_up_option_value: value,
      option: name === `question-${qid?.id}` ? item?.id : prevState.option,
      follow_up_option:
        name === "follow_up_option_value"
          ? item.id
          : prevState.follow_up_option,
    }));

    if (name === "option_value") {
      setFollowUpQuestion(null);
    }
  };
  // console.log("SubmitData ussds", submitAnsData);

  const handleBlur = (e, item) => {
    if (formik.values.option_value || formik.values.follow_up_option_value) {
      setShow(true);
    }
  };
  const keysToExport = [
    "id",
    "category",
    "question",
    "answer",

    "followUpQuestion",
    "followUpAnswer",
  ];
  const headers = {
    id: "ID",
    category: "Category Name",
    question: "Question Name",
    answer: "Answer",
    followUpQuestion: "Follow Up Question",
    followUpAnswer: "Follow Up Answer",
  };

  const extractData = useCallback((data) => {
    return data.flatMap((it) =>
      it.questions.map((question) => {
        const followUpQuestion = question?.option.find(
          (opt) => opt?.follow_up_question
        )?.follow_up_question;
        return {
          id: it.id,
          category: it.category,
          question: question.text,
          answer: question.ans,
          followUpQuestion: followUpQuestion ? followUpQuestion.text : "",
          followUpAnswer: followUpQuestion ? followUpQuestion.ans : "",
        };
      })
    );
  }, []);

  const filteredData = extractData(questiondata);

  const convertToCSV = (arr, keys, headers) => {
    const header = keys.map((key) => headers[key]).join(",");
    const rows = arr.map((obj) =>
      keys
        .map((key) => {
          let value =
            obj[key] !== null && obj[key] !== undefined ? obj[key] : "";
          // Replace \r\n or \n with a space to keep the text in the same cell
          value = value.replace(/[\r\n]+/g, " ");
          // Ensure there's no space between : and the following parentheses
          value = value.replace(/:\s*\(/g, ":(");
          return `"${value}"`; // Enclose the value in quotes to handle commas
        })
        .join(",")
    );
    return [header].concat(rows).join("\n");
  };

  const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handleDownload = () => {
    const csvData = convertToCSV(filteredData, keysToExport, headers);
    downloadCSV(
      csvData,
      `${leadData?.full_name}_${leadData?.id}_formanswers.csv`
    );
  };
  
  return (
    <>
      <div className="maintab_title flex items-center justify-between">
        <h2 className="text-lg font-semibold">
          View all the answers collected from this user from all the
          questionnaire
        </h2>
        <button className="button_export" onClick={handleDownload}>
          <span className="icon">
            <img src={exportIcon} alt="import-icon" />
          </span>
          Export
        </button>
      </div>
      {loader ? (
        // eslint-disable-next-line react/style-prop-object
        <PageLoader style="apply" className="new_class_loader" />
      ) : (
        <>
          <div className="admin_questions">
            <ul className="questions_main">
              {questiondata.map((q, index) => (
                <li
                  key={q.id}
                  className={`questions ${index === activeTab ? "active" : ""}`}
                  onClick={() => handleCategoryClick(index, q)}
                >
                  {q?.category}
                </li>
              ))}
            </ul>
            <div className="admin_formsnswers_list">
              <form onSubmit={formik.handleSubmit} id="sub">
                {questiondata && (
                  <ul className="formsnswers-list">
                    {questiondata[activeTab]?.questions?.length > 0 &&
                      questiondata[activeTab]?.questions.map((fa, index) => (
                        <li key={fa.id} className="question">
                          <p className="question_title font-bold">{fa.text}</p>
                          <div className="form_group_list">
                            {fa?.option.map((as, index) => (
                              <div className="form_group" key={index}>
                                {as.kind === 1 && (
                                  <Formcomponent
                                    as={as}
                                    type="text"
                                    name={`option_value`}
                                    placeholder={fa?.placeholder}
                                    value={
                                      submitAnsData.option_values[as.id] ??
                                      (submitAnsData.option === as.id
                                        ? submitAnsData.option_value
                                        : fa?.ans)
                                    }
                                    // value={fa?.ans}

                                    onChange={(event) => {
                                      formik.handleChange(event);
                                      handelChange(event, as, fa);
                                    }}
                                    onBlur={(event) => handleBlur(event, as)}
                                    htmlFor={`${questiondata[activeTab]?.id}-${as?.id}`}
                                  />
                                )}

                                {as.kind === 2 && (
                                  <Formcomponent
                                    as={as}
                                    type={"radio"}
                                    name={`question-${fa.id}`}
                                    htmlFor={`${questiondata[activeTab]?.id}-${as.id}`}
                                    id={`${questiondata[activeTab]?.id}-${as?.id}`}
                                    checked={
                                      submitAnsData.question === fa.id &&
                                      submitAnsData.option === as.option
                                        ? submitAnsData.option_value === as.text
                                        : fa.ans === as.text
                                    }
                                    value={as.text}
                                    onChange={(event) => {
                                      handleRadioChange(event, as, fa);
                                      formik.handleChange(event);
                                    }}
                                    onBlur={(event) => handleBlur(event, as)}
                                  />
                                )}

                                {as.kind === 3 && (
                                  <Formcomponent
                                    as={as}
                                    type="number"
                                    name={`option_value`}
                                    placeholder={fa?.placeholder}
                                    value={
                                      submitAnsData.option_values[as.id] ??
                                      (submitAnsData.option === as.id
                                        ? submitAnsData.option_value
                                        : fa?.ans)
                                    }
                                    // value={fa?.ans}
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                        handelChange(event, as, fa);
                                    }}
                                    onBlur={(event) => handleBlur(event, as)}
                                    id={`${questiondata[activeTab]?.id}-${as?.id}`}
                                    htmlFor={`${questiondata[activeTab]?.id}-${as?.id}`}
                                  />
                                )}

                                {as.kind === 4 && (
                                  <Formcomponent
                                    as={as}
                                    type="file"
                                    name="option_value"
                                    id={`${questiondata[activeTab]?.id}-${as?.id}`}
                                    htmlFor={`${questiondata[activeTab]?.id}-${as?.id}`}
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                          {/* Follow up question */}
                          <div className="form_follow-question">
                            {fa?.option?.map((as, index) => {
                              return (
                                <div className="form_group_list">
                                  {as?.follow_up_question &&
                                    as.follow_up_question?.option.map((it) => {
                                      return (
                                        <div
                                          style={{
                                            borderLeft: "solid #00d100 3px",
                                            paddingLeft: "13px",
                                            width:"100%",
                                          }}
                                        >
                                          <p className="question_title font-bold">
                                            {as?.follow_up_question?.text}
                                          </p>
                                          {it.kind === 1 && (
                                            <Formcomponent
                                              as={it}
                                              type="text"
                                              onChange={(event) => {
                                                formik.handleChange(event);
                                                handelChange(
                                                  event,
                                                  it,
                                                  as.follow_up_question
                                                );
                                              }}
                                              onBlur={(event) =>
                                                handleBlur(event, it)
                                              }
                                              name="follow_up_option_value"
                                              value={
                                                submitAnsData.option_values[
                                                  it.id
                                                ] ??
                                                (submitAnsData.follow_up_option ===
                                                it.id
                                                  ? submitAnsData.option_value
                                                  : as.follow_up_question?.ans)
                                              }
                                              htmlFor={`${as.follow_up_question?.id}-${it.id}`}
                                              placeholder={it?.placeholder}
                                            />
                                          )}

                                          {it.kind === 2 && (
                                            <Formcomponent
                                              as={it}
                                              type={"radio"}
                                              name={`question-${as?.follow_up_question.id}`}
                                              htmlFor={`${as.follow_up_question?.id}-${it.id}`}
                                              id={`${as.follow_up_question?.id}-${it.id}`}
                                              checked={
                                                submitAnsData.question === as.follow_up_question?.id &&
                                                submitAnsData.option ===
                                                  it.option
                                                  ? submitAnsData.option_value ===
                                                    it?.text
                                                  : as?.follow_up_question
                                                      ?.ans === it?.text
                                              }
                                              value={it.text}
                                              onChange={(event) => {
                                                handleRadioChange(
                                                  event,
                                                  it,
                                                  as.follow_up_question
                                                );
                                                formik.handleChange(event);
                                              }}
                                              onBlur={(event) =>
                                                handleBlur(event, it)
                                              }
                                            />
                                          )}

                                          {it.kind === 3 && (
                                            <Formcomponent
                                              as={it}
                                              type="number"
                                              name={`follow_up_option_value`}
                                              placeholder={
                                                as.follow_up_question
                                                  .placeholder
                                              }
                                              value={
                                                submitAnsData.option_values[
                                                  it.id
                                                ] ??
                                                (submitAnsData.option === it.id
                                                  ? submitAnsData.option_value
                                                  : as?.follow_up_question?.ans)
                                              }
                                              onChange={(event) => {
                                                  formik.handleChange(event);
                                                  handelChange(
                                                    event,
                                                    it,
                                                    as.follow_up_question
                                                  );
                                              }}
                                              onBlur={(event) =>
                                                handleBlur(event, it)
                                              }
                                              htmlFor={`${as.follow_up_question?.id}-${it.id}`}
                                              id={`${as.follow_up_question?.id}-${it.id}`}
                                            />
                                          )}
                                          {it.kind === 4 && (
                                            <Formcomponent
                                              as={it}
                                              type="file"
                                              name="follow_up_option_value"
                                              htmlFor={`${as.follow_up_question?.id}-${it.id}`}
                                              id={`${as.follow_up_question?.id}-${it.id}`}
                                            />
                                          )}
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                          </div>
                        </li>
                      ))}

                    {/* <button className="save_button">Save</button> */}
                  </ul>
                )}
              </form>
            </div>
            {show && (
              <div className="modal modal--add-lead open">
                <div className="modal__container">
                  <div className="modal__header">
                    <h1 className="text-lg font-bold">Save Changes</h1>
                    <div className="modal__close" onClick={handleClose}>
                      <img src={close} alt="close" />
                    </div>
                  </div>
                  <div className="modal__body">
                    <p className="input-field-label text-base">
                      Are you sure you want to save this changes?
                    </p>
                  </div>
                  <div className="modal__footer justify-end">
                    <button
                      type="button"
                      className="btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      id="sub"
                      form="sub"
                      type="submit"
                      // className="btn btn--primary btn--h40 px-6 py-2 text-base font-semibold"
                      className="btn btn--primary btn--h40 px-10 py-2 text-base relative"
                      onClick={formik.handleSubmit}
                      disabled={loading}
                    >
                      {loading ? <Loader white size="xs" /> : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FormAnswers;
