import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import siteConfig from '../../../services/_siteConfig';

import { useDispatch, useSelector } from 'react-redux';

const Sidebar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const ref_code = localStorage.getItem(siteConfig.REFERRAL_CODE);

    const handleMenuClick = () => {
        document.body.classList.remove('menu-open');
    };

    return (
        <div className='side-bar-main'>
            <ul>
                <li>
                    <Link to='/dp/dashboard' className=''>
                        <div className=''>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 2H3C2.44771 2 2 2.44771 2 3V9C2 9.5523 2.44771 10 3 10H9C9.5523 10 10 9.5523 10 9V3C10 2.44771 9.5523 2 9 2Z" stroke="white" s stroke-width="2" stroke-linejoin="round" />
                                <path d="M9 14H3C2.44771 14 2 14.4477 2 15V21C2 21.5523 2.44771 22 3 22H9C9.5523 22 10 21.5523 10 21V15C10 14.4477 9.5523 14 9 14Z" stroke="white" s stroke-width="2" stroke-linejoin="round" />
                                <path d="M21 2H15C14.4477 2 14 2.44771 14 3V9C14 9.5523 14.4477 10 15 10H21C21.5523 10 22 9.5523 22 9V3C22 2.44771 21.5523 2 21 2Z" stroke="white" s stroke-width="2" stroke-linejoin="round" />
                                <path d="M14 14H22" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18 18H22" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14 22H22" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li>
                    <Link target='_blank' to={`${process.env.REACT_APP_REFERRAL_URL}survey/true/0/${ref_code}`} className=''>
                        <div className=''>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 1.99997V5.99995" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M19.0706 4.92871L16.2422 7.75712" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22 12H18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M19.0706 19.0706L16.2422 16.2422" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 22V18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.92871 19.0706L7.75716 16.2422" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2 12H6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.92871 4.92871L7.75716 7.75712" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.17383 2.76074L8.93918 4.6085" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.76074 8.17285L4.6085 8.93825" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.76074 15.8269L4.6085 15.0615" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.17383 21.2384L8.93918 19.3906" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.8269 21.2384L15.0615 19.3906" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M21.2384 15.8269L19.3906 15.0615" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M21.2384 8.17285L19.3906 8.93825" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.8269 2.76074L15.0615 4.6085" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>
                        <span>EnergieCheck</span>
                    </Link>
                </li>
                <li>
                    <Link to='/dp/user-referral' className=''>
                        <div className=''>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.5 8C17.1193 8 16 6.8807 16 5.5C16 4.11929 17.1193 3 18.5 3C19.8807 3 21 4.11929 21 5.5C21 6.8807 19.8807 8 18.5 8Z" stroke="white"  stroke-width="2" stroke-miterlimit="2" />
                                <path d="M6 6C4.89543 6 4 5.10455 4 4C4 2.89543 4.89543 2 6 2C7.10455 2 8 2.89543 8 4C8 5.10455 7.10455 6 6 6Z" stroke="white"  stroke-width="2" stroke-miterlimit="2" />
                                <path d="M13 19.5L16 17V14C16 12.267 17 11 18.5 11C20 11 21 12.267 21 14V16.4186V21" stroke="white"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 16.5L9 14V12C9 10.267 8 9 6.5 9C5 9 4 10.267 4 12V13.4186V21" stroke="white"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>
                        <span>Referral link</span>
                    </Link>
                </li>
                {/* <li>
                    <Link to='' className=''>
                        <div className=''>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 10C14.2091 10 16 8.20914 16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6C8 8.20914 9.79086 10 12 10Z" stroke="white"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M21 22C21 17.0294 16.9706 13 12 13C7.02945 13 3 17.0294 3 22" stroke="white"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>
                        <span>Profil</span>
                    </Link>
                </li> */}
                <li className='yellow'>
                    <Link to='/dp/users' className=''>
                        <div className=''>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.5 14C5.60455 14 6.5 13.1046 6.5 12C6.5 10.8954 5.60455 10 4.5 10C3.39543 10 2.5 10.8954 2.5 12C2.5 13.1046 3.39543 14 4.5 14Z" stroke="#FDDB2D"  stroke-width="2" stroke-linejoin="round" />
                                <path d="M21.5 4C22.0523 4 22.5 3.55229 22.5 3C22.5 2.44771 22.0523 2 21.5 2C20.9477 2 20.5 2.44771 20.5 3C20.5 3.55229 20.9477 4 21.5 4Z" stroke="#FDDB2D"  stroke-width="2" stroke-linejoin="round" />
                                <path d="M21.5 13C22.0523 13 22.5 12.5523 22.5 12C22.5 11.4477 22.0523 11 21.5 11C20.9477 11 20.5 11.4477 20.5 12C20.5 12.5523 20.9477 13 21.5 13Z" stroke="#FDDB2D"  stroke-width="2" stroke-linejoin="round" />
                                <path d="M21.5 22C22.0523 22 22.5 21.5523 22.5 21C22.5 20.4477 22.0523 20 21.5 20C20.9477 20 20.5 20.4477 20.5 21C20.5 21.5523 20.9477 22 21.5 22Z" stroke="#FDDB2D"  stroke-width="2" stroke-linejoin="round" />
                                <path d="M16.5 3H10.5V21H16.5" stroke="#FDDB2D"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.5 12H16.5" stroke="#FDDB2D"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>
                        <span>Nutzerverwaltung</span>
                    </Link>
                </li>
                <li className='logout-link'>
                    <Link to='/' className='' onClick={() => {
                        localStorage.clear();
                        navigate('/');
                        dispatch({ type: 'USER_LOGOUT' });
                    }}>
                        <div className=''>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" fill="#002D3C"  />
                                <path d="M15.2198 20.4L18.9257 20.4C19.4873 20.4 20.026 20.1787 20.4231 19.7849C20.8202 19.3911 21.0433 18.8569 21.0433 18.3L21.0433 5.69999C21.0433 5.14304 20.8202 4.6089 20.4231 4.21507C20.026 3.82124 19.4873 3.59999 18.9257 3.59999L15.2198 3.59999M14.9566 12L2.95662 12M2.95662 12L7.54177 16.8M2.95662 12L7.54177 7.19999" stroke="#FF0000"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>
                        <span>Logout</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
