import { useCallback, useEffect,useState } from "react";
import { ENUM_API_STATUS } from "../../utils/_gConstant";
import { Link } from "react-router-dom";
import { getDashboardData } from "../../components/auth/core/_request";
import { useNavigate } from "react-router-dom";

import ArrowIcon from "../../components/dashboard/icon/ArrowIcon";
import ChevronDwonIcon from "../../components/dashboard/icon/ChevronDwonIcon";
import ChevronUpIcon from "../../components/dashboard/icon/ChevronUpIcon";
import Layout from "../../components/Layout"

const Deshboard = (props) => {
    document.title = props.title;

    const navigate = useNavigate();
    const [isContentVisible, setContentVisible] = useState(false);
    const toggleContent = () => {
        setContentVisible(!isContentVisible);
    };
    const [dashboardData, setData] = useState([]);

    const getDashboardDetails = useCallback(() => {
        getDashboardData().then((r) => {
            console.log(r.data);
            if (r.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
              setData(r.data.data)
            }else{
                navigate('/')
            }
        })
    }, [navigate]);

    useEffect(() => {
        getDashboardDetails();
    }, [getDashboardDetails]);
    
    const answeredPercentage = (dashboardData.total_category_answered / dashboardData.total_categories) * 100;
    const questionPercentage = (dashboardData.total_questions_answered / dashboardData.total_questions) * 100;
    const uploadedPercentage = (dashboardData.total_uploads_file / dashboardData.total_uploads) * 100;
    return (
        <Layout>
            <div className="main_page_title">
                <h1 className="page_title">Preparing for your Energy Consultation</h1>
            </div>

            <div className="question_info_box">
                <div className="card_box">
                    <p className="question_totle">{dashboardData.percentage_questions_answered} / <span className="totel-full">100%</span></p>
                    <div className="progress_bar_list">
                        <div className="progress_bar_item">
                            <div className="progress_box_inner">
                                <p className="progress_title">Sections completed</p>
                                <div className="progress_info">
                                    <div className="progress_bar">
                                        <div className="progress-grey" style={{ height: '4px', width: `${answeredPercentage}%` }}></div>
                                    </div>
                                </div>
                            </div>
                            <p className="counter_number"><span>{dashboardData.total_category_answered}</span>/{dashboardData.total_categories}</p>
                        </div>
                        <div className="progress_bar_item">
                            <div className="progress_box_inner">
                                <p className="progress_title">Questions completed</p>
                                <div className="progress_info">
                                    <div className="progress_bar">
                                        <div className="progress-grey" style={{ height: '4px', width: `${questionPercentage}%` }}></div>
                                    </div>
                                </div>
                            </div>
                            <p className="counter_number"><span>{dashboardData.total_questions_answered}</span>/{dashboardData.total_questions}</p>
                        </div>
                    </div>
                    <Link className="button_link" to="/detailed-questionnaire">Questionnaire <span className="icon"><ArrowIcon /></span></Link>
                </div>
                <div className="card_box">
                    <p className="question_totle">{dashboardData.percentage_uploads_file} / <span className="totel-half">100%</span></p>
                    <div className="progress_bar_list">
                        <div className="progress_bar_item">
                            <div className="progress_box_inner">
                                <p className="progress_title">No files uploaded</p>
                                <div className="progress_info">
                                    <div className="progress_bar">
                                        <div className="progress-grey" style={{ height: '4px', width: `${uploadedPercentage}%` }}></div>
                                    </div>
                                </div>
                            </div>
                            <p className="counter_number"><span>{dashboardData.total_uploads_file}</span>/{dashboardData.total_uploads}</p>
                        </div>
                    </div>
                    <Link className="button_link" to="/uploads">Upload Files <span className="icon"><ArrowIcon /></span></Link>
                </div>
                <div className="card_box">
                    <p className="question_totle">03 / <span className="totel-nomore">0%</span></p>
                    <Link className="button_link">Book Appointment <span className="icon"><ArrowIcon /></span></Link>
                </div>
            </div>

            <div className="why_we_need_this">
                <button className="why_we_buttton" onClick={toggleContent}>Why we need this <span>{isContentVisible ? <ChevronUpIcon /> : <ChevronDwonIcon />}</span></button>

                {isContentVisible && (<div className="why_we_content">
                    <p>Your input is crucial in helping us create a personalized plan for optimizing your energy consumption. Before the onsite energy consultation, there are three key stages in this process:</p>
                    <ul>
                        <li>Completing this detailed questionnaire.</li>
                        <li>Uploading a few essential documents.</li>
                        <li>Booking an appointment.</li>
                    </ul>
                    <p>This form is organized into <b>13 sections, with approximately 60 questions</b> in total. You have the flexibility to skip some questions if you prefer, but providing answers to as many as possible will ensure the most accurate roadmap.</p>
                    <p>Feel free to complete the form at your own pace, and rest assured that your progress is saved, allowing you to pick up where you left off if needed.</p>
                </div>)}
            </div>
        </Layout>
    );
};

export default Deshboard;