import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { LeadDPStage } from '@/utils/_gConstant';
import filter from '../../../assets/icons/filter.svg';
import { useTranslation } from 'react-i18next';

const DPLeadFilters = ({ filterValues, setFilterValues }) => {
  const { t } = useTranslation();
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false);
  const [isFilterOptionsDropdownVisible, setIsFilterOptionsDropdownVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');
  const [filterValuesOptions, setFilterValuesOptions] = useState([]);

  const filterKeys = useMemo(() => {
    return [
      { key: 'stage', label: t('filters.stage.stage') },
      { key: 'kpi', label: 'KPI' }
    ];
  }, [t]);

  const KPIOptions = useMemo(() => [
    { key: 'open_orders', label: t('filters.kpi.open_orders') },
    { key: 'signature_missing', label: t('filters.kpi.signature_missing') },
    { key: 'signature_missing_team', label: t('filters.kpi.signature_missing_team') },
    { key: 'action_required', label: t('filters.kpi.action_required') },
    { key: 'closed_orders', label: t('filters.kpi.closed_orders') },
    { key: 'cancellation_rate', label: t('filters.kpi.cancellation_rate') }
  ], [t]);

  // const StageOptions = useMemo(() => [
  //   { key: LeadStage.NEW_LEADS, label: t('filters.stage.new_lead') },
  //   { key: LeadStage.CALL_SCHEDULED, label: t('filters.stage.call_scheduled') },
  //   { key: LeadStage.CONTACTED, label: t('filters.stage.contacted') },
  //   { key: LeadStage.CONTRACT_SIGNED, label: t('filters.stage.contract_signed') },
  //   { key: LeadStage.CONSULTED_ASSIGNED, label: t('filters.stage.consulted_assigned') },
  //   { key: LeadStage.ROADMAP_READY, label: t('filters.stage.roadmap_ready') }
  // ], [t]);

  const StageOptions = useMemo(() => [
    { key: LeadDPStage.NEW_LEADS, label: t('filters.stage.new_lead') },
    { key: LeadDPStage.CALL_SCHEDULED, label: t('filters.stage.call_scheduled') },
    { key: LeadDPStage.CONTACTED, label: t('filters.stage.contacted') },
    { key: LeadDPStage.CONTRACT_SIGNED, label: t('filters.stage.contract_signed') },
    { key: LeadDPStage.CONSULTED_ASSIGNED, label: t('filters.stage.consulted_assigned') },
    { key: LeadDPStage.ROADMAP_READY, label: t('filters.stage.roadmap_ready') }
  ], [t]);

  const hasFilter = useCallback(
    (value = null) => {
      if (value != null)
        return filterValues.some((item) => item.key === selectedKey && item.value === value);
      else return filterValues.some((item) => item.key === selectedKey);
    },
    [filterValues, selectedKey]
  );

  const changeFilter = useCallback(
    (key, value, label) => {
      if (hasFilter(value)) {
        setFilterValues((prevState) => {
          return prevState.filter((item) => item.key !== key || item.value !== value);
        });
      } else if (hasFilter()) {
        setFilterValues((prevState) => {
          const filteredState = prevState.filter((item) => item.key !== key);
          return [...filteredState, { key, value, label }];
        });
      } else {
        setFilterValues((prevState) => {
          return [...prevState, { key, value, label }];
        });
      }
    },
    [setFilterValues, hasFilter]
  );

  const toggleFilterDropdown = () => {
    if (!isFilterDropdownVisible && !isFilterOptionsDropdownVisible) {
      setIsFilterDropdownVisible(true);
      setSelectedKey('');
    } else {
      setIsFilterDropdownVisible(false);
      setIsFilterOptionsDropdownVisible(false);
      setFilterValuesOptions([]);
    }
  };

  const toggleFilterValuesDropdown = (key) => {
    setSelectedKey(key);
    setIsFilterDropdownVisible(false);
    if (key === 'kpi') {
      setFilterValuesOptions(KPIOptions);
    }
    if (key === 'stage') {
      setFilterValuesOptions(StageOptions);
    }
    setIsFilterOptionsDropdownVisible(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('#filter-values-dropdown')) {
        setIsFilterOptionsDropdownVisible(false);
        setFilterValuesOptions([]);
      }
      if (!event.target.closest('#filter-dropdown')) {
        setIsFilterDropdownVisible(false);
      }
    };
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className='relative ml-2' id='filter-dropdown'>
      <button className='btn btn--h40 px-6 py-2 font-semibold' onClick={toggleFilterDropdown}>
        <img src={filter} alt='filter-icon' className='mr-3' />
        {t('filters.filters_button')}
      </button>
      <ul
        className={`dropdown-menu md ${isFilterDropdownVisible ? 'show' : ''}`}
        style={{ minWidth: 200 }}
        id={'filter-dropdown'}
      >
        {filterKeys.map((option) => (
          <li
            key={option.key}
            className='dropdown-menu__list'
            onClick={() => toggleFilterValuesDropdown(option.key)}
          >
            {option.label}
          </li>
        ))}
      </ul>

      <ul
        className={`dropdown-menu overflow-y-auto${isFilterOptionsDropdownVisible ? ' show' : ''}`}
        id='filter-values-dropdown'
        style={{ width: 225, maxHeight: 245 }}
      >
        {filterValuesOptions.map((option, index) => (
          <li className='dropdown-menu__list px-4' key={index}>
            <div className='custom-checkbox md'>
              <input
                type='checkbox'
                id={`name${index}`}
                className='custom-checkbox__input'
                checked={hasFilter(option.key)}
                onChange={() => {
                  changeFilter(selectedKey, option.key, option.label);
                }}
              />
              <label htmlFor={`name${index}`} className='custom-checkbox__label'>
                <code className='custom-checkbox__label__box'></code>
                <span className='text-secondary text-sm font-semibold ml-2'>
                  {option.label}
                </span>
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DPLeadFilters;
