import { Link, useNavigate } from "react-router-dom";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { leadStageColors, leadStageOptions } from "../../utils/_gConstant";
import ConsultantAddEditLead from "../../components/consultant/ConsultantAddEditLead";
import { ENUM_API_STATUS } from "../../utils/_gConstant";
import FileIcon from "../../components/dashboard/icon/FileIcon";
import LeadFilter from "../Admin/LeadFilter";
import { LeadListViewGetRequest } from "../Admin/_request";
import PageLoader from "../../components/dashboard/PageLoader";
import arrowGroup from "../../assets/icons/arrow-group.svg";
import editIcon from "../../assets/icons/edit.svg";
import { exportConsultantInFileRequest } from "../Admin/_request";
import exportIcon from "../../assets/icons/export.svg";
import eyeIcon from "../../assets/icons/eye-outline.svg";
import { formatDate } from "../../utils/_gFunctions/_handleDateFormat";
import { stageChangeapi } from "../Admin/_request";
import { useSearchParams } from "react-router-dom";

import {
  toastError,
  toastSuccess,
} from "../../components/ui-elements/_Toastify";
import close from "../../assets/icons/close.svg";
import loaderd from "../../assets/icons/loading.gif";

const ConLeadListing = () => {
  const navigate = useNavigate();
  const [showSort, setShowSort] = useState(false);
  const [sortValue, setSortValue] = useState("Newest first");
  const [editModal, setEditModal] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const leadsSection = useRef(null);
  const [nextPage, setNextPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedStages, setSelectedStages] = useState({});
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [tablelodaer, setTableloader] = useState(false);
  const handleEditLeadModal = (item) => {
    setEditModal(item);
  };

  const handleCloseModal = ()=>{
    setEditModal(false)
  }
console.log("Edit Lead Modal", editModal)
  const handleClose = () => {
    setShow(false);
    setFileName("");
    setErrorMessage("");
  };
  const setLeadListData = useCallback(
    (leadDatas) => {
      const sort_data = (data) => {
        return data.sort((a, b) => {
          if (sortValue === "Newest first") {
            return new Date(b.enq_date) - new Date(a.enq_date);
          } else {
            return new Date(a.enq_date) - new Date(b.enq_date);
          }
        });
      };
      if (typeof leadDatas === "function") {
        setLeadData((data) => {
          const oldData = [...data];
          return sort_data(leadDatas(oldData));
        });
      } else {
        setLeadData(sort_data(leadDatas));
      }
    },
    [sortValue]
  );
  const setSortingValue = useCallback(
    (value) => {
      setSortValue(value);
      setShowSort(false);
    },
    [setSortValue, setShowSort]
  );

  const getleads = useCallback(
    (page, reset = false, searchd) => {
      if (page !== null) {
        setTableloader(true);

        LeadListViewGetRequest({ page, page_size: 20, search: searchd }, true)
          .then((res) => {
            if (res?.status === ENUM_API_STATUS.SUCCESS_GET_API) {
              if (!reset) {
                setLeadData((leads) => [...leads, ...res?.data?.results]);
              } else {
                setLeadData(res?.data?.results);
              }
              // setTotalLeads(res?.data?.count);
              setNextPage(res?.data?.next);
            }
          })
          .catch((err) => {
            console.log("Erredreir", err);
          })
          .finally(() => {
            setTableloader(false);
          });
      }
    },
    [search]
  );

  useEffect(() => {
    if (sortValue === null) return;

    setLeadListData((data) => {
      const oldData = [...data];
      return oldData;
    });
  }, [sortValue]);

  useEffect(() => {
    if (search) {
      getleads(1, true, search);
    } else {
      getleads(1, true, "");
    }
  }, [search]);

  // const handleExportButtonClick = useCallback(async() => {
  //   // const filterParams = objectToQueryString({
  //   //   ...filterSelectedObject,
  //   //   search: search,
  //   // });
  //   // console.log("Filter", filterParams)
  //   // window.open(
  //   //   `${siteConfig.BASE_URL}/api/leads/export/?${filterParams}`,
  //   //   "_blank"
  //   // );

  // }, [search]);

  const filterSelectedValue = useMemo(() => {
    const filterValue = searchParams.get("filter");
    if (filterValue) {
      return filterValue.split(",");
    }
    return [];
  }, [searchParams]);

  useEffect(() => {
    const scrollHandler = () => {
      if (
        leadsSection.current.scrollTop + leadsSection.current.clientHeight >=
        leadsSection.current.scrollHeight
      ) {
        getleads(nextPage);
      }
    };
    leadsSection.current?.addEventListener("scroll", scrollHandler);
    return () => {
      if (leadsSection.current !== null)
        leadsSection.current?.removeEventListener("scroll", scrollHandler);
    };
  }, [getleads, nextPage]);

  const convertToCSV = (arr, keys, head) => {
    const header = keys.map((key) => head[key]).join(",");
    const rows = arr.map((obj) =>
      keys
        .map((key) =>
          obj[key] !== null && obj[key] !== undefined ? obj[key] : ""
        )
        .join(",")
    );
    return [header].concat(rows).join("\n");
  };

  const keysToExport = [
    "first_name",
    "last_name",
    "enq_id",
    "email",
    "mobile_number",
    "postal_code",
    "city",
    "country",
    "stages",
  ];
  const headers = {
    first_name: "First Name",
    last_name: "Last Name",
    enq_id: "Enquiry ID",
    email: "Email",
    mobile_number: "Mobile Number",
    postal_code: "Postal Code",
    city: "City",
    country: "Country",
    stages: "Stage",
  };
  const handleExportButtonClick = () => {
    const csvData = convertToCSV(leadData, keysToExport, headers);
    downloadCSV(csvData, "leadsdata.csv");
  };

  const filterSelectedObject = useMemo(() => {
    const object = {};
    filterSelectedValue.forEach((filter) => {
      const [key, value] = filter.split("-");
      if (object.hasOwnProperty(key)) {
        object[key].push(value);
      } else {
        object[key] = [value];
      }
    });
    return object;
  }, [filterSelectedValue]);
  const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  const handleStageChange = async (item, newStage) => {
    setLoader(true)
    if (newStage == 5) {
      setShow(item);
    } else {
      setTableloader(true);

      const data = {
        lead_id: item?.id,
        stage: newStage,
      };
      stageChangeapi(data)
        .then((res) => {
          if (res.status == ENUM_API_STATUS.SUCESS_POST_API_200) {
            toastSuccess(res.data.message);
            getleads(1, true, search);
          } else {
            toastError(res.data);
          }
        })
        .catch((err) => {
          console.log("hjhjhjhkjk");
          toastError(err);
        })
        .finally(() => {
          setTableloader(false);
          setLoader(false)
        });
    }
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size;

      // Check if the file is an image
      if (fileType.startsWith("image/")) {
        setErrorMessage(
          "Images are not allowed. Please upload other file types."
        );
        setFileName("");
        return;
      }

      // Check if the file size is not more than 10MB
      if (fileSize > 10 * 1024 * 1024) {
        setErrorMessage("File size should not exceed 10MB.");
        setFileName("");
        return;
      }

      // If both checks pass, reset error message and set the file name
      setErrorMessage("");
      setFileName(file);
    }
  };

  const handleupload = () => {
    if (!fileName) {
      setErrorMessage("Please upload a PDF file.");
      return;
    }
    const formdata = new FormData();
    formdata.append("lead_id", show?.id);
    formdata.append("stage", 5);
    formdata.append("isfp_document", fileName);

    setTableloader(true);
    stageChangeapi(formdata)
      .then((res) => {
        if (res.status == ENUM_API_STATUS.SUCESS_POST_API_200) {
          toastSuccess(res.data.message);
          getleads(1, true, search);
          setShow(false);
          setFileName("");
          setErrorMessage("");
        } else {
          toastError(res.data);
        }
      })
      .catch((err) => {
        console.log("hjhjhjhkjk");
        toastError(err);
      })
      .finally(() => {
        setTableloader(false);
        setLoader(false)
      });
  };

  return (
    <>
      <div className="filter_consultant flex items-center w-full px-9">
        <h1 className="con_page_title text-36 font-semibold ">Leads</h1>
        <div className="flex items-center ml-9">
          <div className="relative">
            <input
              type="text"
              className="input-field input-field--search input-field--h40 input-field--md lato"
              style={{ maxWidth: 282, width: "100%" }}
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {/* <LeadFilter filterSelectedValue={filterSelectedValue} /> */}
          <div className="sort-dropdown relative ml-2" id="sort-dropdown">
            <button
              className="btn btn--h40 px-6 py-2 font-semibold"
              onClick={() => setShowSort((show) => !show)}
            >
              <img src={arrowGroup} alt="arrow-group-icon" className="mr-3" />
              {sortValue}
            </button>
            <ul className={`dropdown-menu md ${showSort && "show"}`}>
              <li
                className="dropdown-menu__list"
                onClick={() => {
                  setSortingValue("Newest first");
                }}
              >
                {" "}
                Newest first
              </li>
              <li
                className="dropdown-menu__list"
                onClick={() => {
                  setSortingValue("Oldest first");
                }}
              >
                {" "}
                Oldest first
              </li>
            </ul>
          </div>
        </div>
        <div className="flex items-center ml-auto">
          {/* <button
                className="btn btn--secondary-brand btn--h42 btn--md font-bold"
                // onClick={() => toggleFileUploadModal()}
              >
                <img src={importIcon} alt="import-icon" className="mr-3" />
                Import
              </button> */}
          <div>
            <button
              className="btn btn--secondary-brand btn--h42 btn--md ml-4 font-bold"
              onClick={handleExportButtonClick}
            >
              <img src={exportIcon} alt="import-icon" className="mr-3" />
              Export
            </button>
          </div>
        </div>
      </div>
      {loader ? (
        <PageLoader />
      ) : (
        <>
          <div
            className="consultant_leadtable px-10 mt-6 pb-8 overflow-y-auto"
            ref={leadsSection}
          >
            <table className="table-styles" style={{ maxHeight: "100%" }}>
              <thead>
                <tr>
                  <td>Enq Id</td>
                  <td>Name</td>
                  <td>Mobile</td>
                  <td>Email</td>
                  <td>Postcode</td>
                  <td>
                    <div className="secondary-font flex items-center text-sm font-bold">
                      Enquiry Date
                    </div>
                  </td>
                  <td>Stage</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {leadData &&
                  leadData?.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/consultant/leads/details/${item?.id}`)
                          }
                        >
                          {item.enq_id ? item.enq_id : "-"}
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/consultant/leads/details/${item?.id}`)
                          }
                        >
                          <div className="primary-font text-lg font-medium">
                            {item.first_name} {item.last_name}
                          </div>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/consultant/leads/details/${item?.id}`)
                          }
                        >
                          {item.mobile_number ? item.mobile_number : "-"}
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/consultant/leads/details/${item?.id}`)
                          }
                        >
                          {item.email ? item.email : "-"}
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/consultant/leads/details/${item?.id}`)
                          }
                        >
                          {item.postal_code ? item.postal_code : "-"}
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/consultant/leads/details/${item?.id}`)
                          }
                        >
                          {item.enq_date ? formatDate(item.enq_date) : "-"}
                        </td>
                        <td>
                          {/* {item.stages ? (
                            <div
                              className="tags"
                              style={leadStageColors[item.stages] || {}}
                            >
                              {
                                leadStageOptions.find(
                                  (option) => option.value === item?.stages
                                )?.label
                              }
                            </div>
                          ) : (
                            "-"
                          )} */}
                          <select
                            className="tags"
                            value={selectedStages[item.enq_id] || item.stages}
                            onChange={(e) =>
                              handleStageChange(item, e.target.value)
                            }
                            style={leadStageColors[item.stages] || {}}
                          >
                            {leadStageOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                // className="tags"
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <div className="flex items-center justify-end">
                            <div
                              className="flex items-center w-5 h-5 cursor-pointer"
                              onClick={()=>handleEditLeadModal(item)}
                            >
                              <img src={editIcon} alt="icon-edit" />
                            </div>
                            <div
                              state={item}
                              onClick={() =>
                                navigate(`/consultant/leads/details/${item?.id}`)}
                              className="flex items-center w-5 h-5 ml-1 cursor-pointer"
                            >
                              <img src={eyeIcon} alt="icon-eye" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}

                {nextPage != null || tablelodaer ? (
                  <img
                    src={loaderd}
                    alt="loader"
                    style={{ width: "25px", height: "25px", margin: "10px" }}
                  />
                ) : null}
              </tbody>
            </table>
          </div>
        </>
      )}
      {show && (
        <div className="modal modal--add-lead open">
          <div className="modal__container">
            <div className="modal__header">
              <h1 className="text-lg font-bold">Please Upload File</h1>
              <div className="modal__close" onClick={handleClose}>
                <img src={close} alt="close" />
              </div>
            </div>
            <div className="modal__body">
              <div className="dropzonepdf">
                <input
                  type="file"
                  accept="*/*"
                  name="file_uplod"
                  id="profileUploader"
                  onChange={handleFileInput}
                  className="uploadpdf"
                />
                <div className="icon_titlepdf">
                  <span className="icon_pdf">
                    <FileIcon />
                  </span>
                  <p id="filename" className="test_pdf">
                    {fileName?.name}
                  </p>
                  <span className="plane_text_pdf">Upload File</span>
                </div>
                <aside className="upload_pdf">
                  {/* {activeTab === index} */}
                </aside>

                {errorMessage && (
                  <div className={"animated fadeIn err-msg"}>
                    <div className="error">{errorMessage}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal__footer justify-end">
              <button
                type="button"
                className="btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                id="sub"
                form="sub"
                type="submit"
                className="btn btn--primary btn--h40 px-6 py-2 text-base font-semibold"
                onClick={handleupload}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {editModal && (
        <ConsultantAddEditLead onClose={handleCloseModal} title="edit" editmodal={editModal}  setLeadData={setEditModal} getleads={getleads}/>
      )}
    </>
  );
};

export default ConLeadListing;
