import ArrowLeftIcon from "../../components/dashboard/icon/ArrowLeftIcon";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import QuestionTabs from "../../components/dashboard/QuestionTabs";
import { useSelector } from "react-redux";

const QuestionDetailed = () => {
    const leadId = useSelector((state) => state?.authReducer?.user?.lead_id)
    return (
        <Layout>
            <div className="main_page_title">
                <Link to={`/homeowner/${leadId}`} className="back_button"><span><ArrowLeftIcon /></span>Dashboard</Link>
                <h1 className="page_title">Detailed Questionnaire</h1>
            </div>
            <QuestionTabs />
        </Layout>
    );
}

export default QuestionDetailed;