const ArrowLeftIcon = () => {
    return (
        <>
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.617188 7.11621C0.128906 7.60449 0.128906 8.39746 0.617188 8.88574L6.86719 15.1357C7.35547 15.624 8.14844 15.624 8.63672 15.1357C9.125 14.6475 9.125 13.8545 8.63672 13.3662L3.26953 7.99902L8.63281 2.63184C9.1211 2.14355 9.1211 1.35059 8.63281 0.862305C8.14453 0.374023 7.35156 0.374023 6.86328 0.862305L0.613281 7.1123L0.617188 7.11621Z" fill="#9CA3AF" />
            </svg>
        </>
    );
};

export default ArrowLeftIcon;