
import React, { useState } from "react";
import { deleteisfpfile, deletfile } from "../../components/auth/core/_request";
import { toastError, toastSuccess } from "../../components/ui-elements/_Toastify";

import { ENUM_API_STATUS } from "../../utils/_gConstant";
import Loader from "../../components/common/Loader";

import close from "../../assets/icons/close.svg";
import { getListDetailsGetRequest } from "./_request";
import trashIcon from "../../assets/icons/trash-lg.svg";
import { useParams } from "react-router-dom";

const DeleteDocument = ({ closeModal, open, getdocuments, load, setshow , handleShowlodaer, setLeadData}) => {
  const [loader, setLoader] = useState(false);
  const leadId = useParams()?.leadId;
  const getLeadData = async () => {

    const res = await getListDetailsGetRequest(leadId);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
      // setshow(false)
      setLeadData(res.data)
      return res;
    }
  };

  const handleDelete = () => {
    setLoader(true);
    if (open?.isfp_document) {
      const id = leadId;
      if (id && open?.isfp_document) {
        // setshow(true)
        deleteisfpfile(id)
          .then((data) => {
            if (data.status === ENUM_API_STATUS.SUCCESS_GET_API) {
              toastSuccess(data?.data.message);
              closeModal();
              getdocuments();
              getLeadData();
            }
          })
          .catch((error) => {
            console.log("fgfgfgfdkgfjgfdgfd", error);
          })
          .finally(() => {
            setLoader(false);
            // setshow(false)
          });
      }
    } else {
      const id = open?.ans_id;
      deletfile(id)
        .then((data) => {
          if (data.status === ENUM_API_STATUS.SUCCESS_DELETE_API) {
            toastSuccess("Document Deleted Successfully");
            closeModal();
            getdocuments();
            getLeadData();
          }
        })
        .catch((error) => {
          toastError(error?.message?.data)
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  return (
    <section className={`modal modal--delete-user ${handleDelete && "open"}`}>
      <div className="modal__container" style={{ width: 550 }}>
        <div className="modal__header">
          <h1 className="text-lg font-bold">Delete Document?</h1>
          <div className="modal__close" onClick={closeModal}>
            <img src={close} alt="close" />
          </div>
        </div>
        <div className="modal__body" style={{ backgroundColor: "#FFF8F8" }}>
          <div className="flex flex-col items-center">
            <img src={trashIcon} alt="trash" />
            <p className="font-medium mt-5">
              Do you want to delete&nbsp;? If yes, click 'delete.'
            </p>
          </div>
        </div>
        <div className="modal__footer justify-end">
          <button
            className="btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="btn btn--red btn--h40 px-10 py-2 text-base relative"
            onClick={handleDelete}
            disabled={loader}
          >
            {loader ? <Loader white size="xs" /> : "Delete"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default DeleteDocument;
