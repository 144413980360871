const ChevronDwonIcon = () => {
    return (
        <>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.26417 5.44292C5.11772 5.58937 4.88032 5.58937 4.73387 5.44292L0.910615 1.61967C0.76417 1.47322 0.76417 1.23582 0.910615 1.08937L1.08739 0.912572C1.23384 0.766122 1.47127 0.766122 1.61772 0.912572L4.99902 4.29387L8.38032 0.912572C8.52677 0.766122 8.76418 0.766122 8.91063 0.912572L9.08743 1.08937C9.23388 1.23582 9.23388 1.47322 9.08743 1.61967L5.26417 5.44292Z" fill="#9CA3AF" />
            </svg>
        </>
    );
};

export default ChevronDwonIcon;