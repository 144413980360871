import 'react-datepicker/dist/react-datepicker.css';

import * as Yup from 'yup';

import {
  AssignConsultantGetRequest,
  addNewLeadRequest,
  editLead,
  tagsAllGetRequest,
} from './_request';
import { City, Country } from 'country-state-city';
import React, { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import { ENUM_API_STATUS } from '../../utils/_gConstant';
import Loader from "../../components/common/Loader";
import Select from 'react-select';
import close from '../../assets/icons/close.svg';
import dayjs from 'dayjs';
import { editListViewLead } from './store/action';
import { toastSuccess } from '../../components/ui-elements/_Toastify';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

const AddEditLead = (props) => {
  const { modalData, onClose, addLead, updateLead, setSortValue, handleClearAll } = props;
  const dispatch = useDispatch();
  const [consultantData, setConsultantData] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs(modalData?.lead?.enq_date).toDate() || new Date()
  );
  const [cityOptions, setCityOptions] = useState([]);

  const getAssignConsultant = async () => {
    const res = await AssignConsultantGetRequest();
    if (res?.status === ENUM_API_STATUS.SUCCESS_GET_API) {
      setConsultantData(res?.data);
    }
  };
  const gettagList = async () => {
    const res = await tagsAllGetRequest();
    if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
      setTagList(res?.data);
    }
  };
  useEffect(() => {
    getAssignConsultant();
    gettagList();
  }, []);

  const handleClose = () => {
    onClose();
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    mobile_number: Yup.string().required('Mobile number is required'),
    email: Yup.string().email('Invalid email format'),
  });

  const formik = useFormik({
    initialValues: {
      stages: modalData?.stages || modalData?.stageId || modalData?.lead.stage,
      first_name: modalData?.first_name || modalData?.lead?.first_name || '',
      last_name: modalData?.last_name || modalData?.lead?.last_name || '',
      mobile_number: modalData?.mobile_number || modalData?.lead?.mobile_number || '',
      email: modalData?.email || modalData?.lead?.email || '',
      tags:
        modalData?.tags?.map((tag) => {
          return {
            label: tag,
            value: tag,
          };
        }) ||
        modalData?.lead?.tags?.map((tag) => {
          return {
            label: tag,
            value: tag,
          };
        }),
      city:
        modalData?.city || modalData?.lead?.city
          ? {
              label: modalData?.city || modalData?.lead?.city,
              value: modalData?.city || modalData?.lead?.city,
            }
          : null,
      country:
        modalData?.country || modalData?.lead?.country
          ? {
              label: modalData?.country || modalData?.lead?.country,
              value: modalData?.country || modalData?.lead?.country,
            }
          : null,
      consultant_id:
        modalData?.consultant_assigned || modalData?.lead?.consultant_assigned
          ? {
              label: `${
                modalData?.consultant_assigned?.first_name ||
                modalData?.lead?.consultant_assigned?.first_name
              } ${
                modalData?.consultant_assigned?.last_name ||
                modalData?.lead?.consultant_assigned?.last_name
              }`,
              value: modalData?.consultant_assigned?.id || modalData?.lead?.consultant_assigned?.id,
            }
          : null,
      enq_date:
        dayjs(modalData?.lead?.enq_date).format('YYYY-MM-DDTHH:mm:ss[Z]') ||
        dayjs(modalData?.enq_date).format('YYYY-MM-DDTHH:mm:ss[Z]') ||
        new Date(),
      postal_code: modalData?.postal_code || modalData?.lead?.postal_code || '',
      distributor_agent:
        modalData?.distributor_agent ||
        modalData?.lead?.distributor_agent ||
        "",
      distributor_partner:
        modalData?.distributor_partner ||
        modalData?.lead?.distributor_partner ||
        "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoader(true);
      const { city, country, tags, ...restvalues } = values;
      const cityLabel = city?.label || null;
      const countryLabel = country?.label || null;
      const tagsArray = Array.isArray(tags)
        ? tags.map((tag) => tag.value)
        : Array.isArray(tags?.value)
          ? tags.value
          : [];
      values = {
        ...restvalues,
        city: cityLabel,
        country: countryLabel,
        tags: tagsArray,
      };
      const consultantIdValue = values.consultant_id ? values.consultant_id.value : null;

      if (modalData?.lead || modalData?.id) {
        const res = await editLead(modalData?.id || modalData?.lead?.id, {
          ...values,
          consultant_id: consultantIdValue,
        });

        if (res?.status === ENUM_API_STATUS.SUCCESS_PATCH_API) {
          if (updateLead !== undefined) updateLead(res?.data);
          dispatch(editListViewLead({ ...values, consultant_id: consultantIdValue }));
          toastSuccess('Lead has been updated successfully!');
          handleClose();
          setSortValue('Newest first');
          handleClearAll();
        }
        setLoader(false);
      } else {
        const res = await addNewLeadRequest({
          ...values,
          consultant_id: consultantIdValue,
        });

        if (res?.status === ENUM_API_STATUS.SUCCESS_POST_API) {
          addLead(res?.data);
          toastSuccess('The lead has been created successfully!');
          handleClose();
          setSortValue('Newest first');
          handleClearAll();
        }
        setLoader(false);
      }
    },
  });

  useEffect(() => {
    const fetchCityOptions = async () => {
      try {
        if (formik.values.country) {
          const selectedCountry = Country.getAllCountries()?.find(
            (country) => country.name === formik.values.country.label
          );

          if (selectedCountry) {
            const cities = City?.getCitiesOfCountry(selectedCountry.isoCode);

            const cityOptions = cities?.map((city) => ({
              label: city.name,
              value: city.name,
            }));

            setCityOptions(cityOptions);

            const currentCityOption = cityOptions?.find(
              (option) => option.value === formik.values.city?.value
            );

            if (!currentCityOption) {
              formik.setFieldValue('city', null);
            }
          } else {
            console.log('Invalid country name');
          }
        } else {
          setCityOptions([]);
        }
      } catch (error) {
        console.error('Error fetching cities:', error);
        setCityOptions([]);
      }
    };

    fetchCityOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country, formik.values.city, formik?.setFieldValue]);
  return (
    <div className='modal modal--add-lead open'>
      <div className='modal__container'>
        <div className='modal__header'>
          {modalData?.id || modalData?.lead?.id ? (
            <h1 className='text-lg font-bold'>Edit Lead</h1>
          ) : (
            <h1 className='text-lg font-bold'>Add New Lead</h1>
          )}
          <div className='modal__close' onClick={handleClose}>
            <img src={close} alt='close' />
          </div>
        </div>
        <div className='modal__body'>
          <div className='flex flex-wrap -mx-2'>
            <div className='col-6 px-2 mb-4'>
              <label className='input-field-label primary-font'>First name of the Lead</label>
              <input
                type='text'
                name='first_name'
                value={formik.values.first_name}
                onChange={formik.handleChange}
                className='input-field input-field--regular input-field--h40 border2 text-sm w-full lato'
                placeholder='First Name of the Lead'
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <div className='error-message'>{formik.errors.first_name}</div>
              )}
            </div>

            <div className='col-6 px-2 mb-4'>
              <label className='input-field-label primary-font'>Enquiry Date</label>
              <DatePicker
                className='input-field input-field--regular input-field--h40 border2 text-sm w-full lato'
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  const formattedDate = date
                    ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]')
                    : dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]');
                  formik.setFieldValue('enq_date', formattedDate);
                }}
                dateFormat='dd-MMM-yyyy'
              />
            </div>
            <div className='col-6 px-2 mb-4'>
              <label className='input-field-label primary-font'>Last name of the Lead</label>
              <input
                type='text'
                name='last_name'
                value={formik.values.last_name}
                onChange={formik.handleChange}
                className='input-field input-field--regular input-field--h40 border2 text-sm w-full lato'
                placeholder='Last Name of the Lead'
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <div className='error-message'>{formik.errors.last_name}</div>
              )}
            </div>
            <div className='col-6 px-2 mb-4'>
              <label className='input-field-label primary-font'>Country</label>
              <Select
                name='country'
                className='input-field--regular input-field--h40 border2 text-sm w-full lato'
                placeholder='Select Country'
                options={Country?.getAllCountries()?.map((country) => ({
                  label: country.name,
                  value: country.name,
                  isoCode: country.isoCode, // Add isoCode or any other required properties
                }))}
                maxMenuHeight={150}
                value={formik.values.country}
                onChange={(selectedOption) => formik.setFieldValue('country', selectedOption)}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className='col-6 px-2 mb-4'>
              <label className='input-field-label primary-font'>Mobile Number</label>
              <input
                type='text'
                name='mobile_number'
                value={formik.values.mobile_number}
                onChange={formik.handleChange}
                className='input-field input-field--regular input-field--h40 border2 text-sm w-full lato'
                placeholder='+56 00 00 00 00'
              />
              {formik.touched.mobile_number && formik.errors.mobile_number && (
                <div className='error-message'>{formik.errors.mobile_number}</div>
              )}
            </div>
            <div className='col-6 px-2 mb-4'>
              <label className='input-field-label primary-font'>City</label>
              <Select
                name='city'
                className='input-field--regular input-field--h40 border2 text-sm w-full lato'
                placeholder='Select City'
                options={cityOptions}
                maxMenuHeight={150}
                onChange={(selectedOption) => formik.setFieldValue('city', selectedOption)}
                value={formik.values.city}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className='col-6 px-2 mb-4'>
              <label className='input-field-label primary-font'>Email ID</label>
              <input
                type='text'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                className='input-field input-field--regular input-field--h40 border2 text-sm w-full lato'
                placeholder='abcd@gmail.com'
              />
              {formik.touched.email && formik.errors.email && (
                <div className='error-message'>{formik.errors.email}</div>
              )}
            </div>

            <div className='col-6 px-2 mb-4'>
              <label className='input-field-label primary-font'>Post Code</label>
              <input
                type='text'
                name='postal_code'
                value={formik.values.postal_code}
                onChange={formik.handleChange}
                className='input-field input-field--regular input-field--h40 border2 text-sm w-full lato'
                placeholder='560102'
              />
            </div>
            <div className="col-6 px-2 mb-4">
              <label className="input-field-label primary-font">
                Distribution Partner
              </label>
              <input
                type="text"
                name="distributor_partner"
                value={formik.values.distributor_partner}
                onChange={formik.handleChange}
                className="input-field input-field--regular input-field--h40 border2 text-sm w-full lato"
                placeholder="Enter Distribution Partner"
              />
            </div>
            <div className="col-6 px-2 mb-4">
              <label className="input-field-label primary-font">
                Distribution Agent
              </label>
              <input
                type="text"
                name="distributor_agent"
                value={formik.values.distributor_agent}
                onChange={formik.handleChange}
                className="input-field input-field--regular input-field--h40 border2 text-sm w-full lato"
                placeholder="Enter Distribution Agent"
              />
            </div>
            <div className='col-6 px-2 mb-4'>
              <label className='input-field-label primary-font'>Consultant</label>
              <Select
                name='consultant_id'
                className=''
                placeholder='Select Consultant'
                options={consultantData?.map((consultant) => ({
                  label: `${consultant.first_name} ${consultant.last_name} - ${consultant.location}`,
                  value: consultant.id,
                }))}
                value={formik.values.consultant_id}
                onChange={(selectedOption) => formik.setFieldValue('consultant_id', selectedOption)}
                onBlur={formik.handleBlur}
                maxMenuHeight={100}
              />
            </div>
            <div className='col-12 px-2 mb-4'>
              <label className='input-field-label primary-font'>Tags</label>
              <Select
                isMulti
                options={tagList?.map((tag) => ({ label: tag, value: tag }))}
                value={formik.values.tags}
                onChange={(selectedOption) => formik.setFieldValue('tags', selectedOption)}
                maxMenuHeight={150}
              />
            </div>
          </div>
        </div>
        <div className='modal__footer justify-end'>
          <button
            type='button'
            className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type=''
            className='btn btn--primary btn--h40 px-10 py-2 text-base relative'
            onClick={formik.handleSubmit}
            disabled={loader}
          >
            {loader ? <Loader white size="xs" /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEditLead;
