import React from "react";
import { useNavigate } from "react-router-dom";

const ConsultantLeadinfo = ({leaddata}) => {
    return (
        <div>
            <h3 className="title-text">Basic Lead Info</h3>
            <div className="flex flex-wrap -mx-8 mt-4">
                <div className="col-4 px-8 mb-8">
                    <label className="label-text">Full Name</label>
                    <p className="desc-text">{leaddata?.full_name}</p>
                </div>
                <div className="col-4 px-8 mb-8">
                    <label className="label-text">Lead Number</label>
                    <p className="desc-text">0674</p>
                </div>
                <div className="col-4 px-8 mb-8">
                    <label className="label-text">Email</label>
                    <p className="desc-text">{leaddata?.email}</p>
                </div>
                <div className="col-4 px-8 mb-8">
                    <label className="label-text">Phone number</label>
                    <p className="desc-text">{leaddata?.mobile_number}</p>
                </div>
                <div className="col-4 px-8 mb-8">
                    <label className="label-text">Distribution Partner</label>
                    <p className="desc-text">{leaddata?.distributor_partner ? leaddata?.distributor_partner :"-"}</p>
                </div>
                <div className="col-4 px-8 mb-8">
                    <label className="label-text">Distribution Agent</label>
                    <p className="desc-text">{leaddata?.distributor_agent ? leaddata?.distributor_agent :"-"}</p>
                </div>
                {/* <div className="col-4 px-8 mb-8">
                    <label className="label-text">Location</label>
                    <p className="desc-text">{leadData?.location}</p>
                </div> */}
            </div>
        </div>
    );
};

export default ConsultantLeadinfo;
