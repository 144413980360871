const SettingsIcon = () => {
    return (
        <>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.3089 3.88472C11.9241 2.30519 9.67628 2.30519 9.29152 3.88472C9.23408 4.12207 9.12141 4.3425 8.96268 4.52808C8.80394 4.71366 8.60364 4.85914 8.37806 4.95269C8.15248 5.04623 7.90801 5.0852 7.66452 5.06641C7.42104 5.04763 7.18543 4.97162 6.97688 4.84458C5.58768 3.99812 3.99801 5.58777 4.84448 6.97694C5.39125 7.87403 4.90625 9.0445 3.88562 9.29256C2.30505 9.67631 2.30505 11.9251 3.88562 12.3078C4.12303 12.3654 4.34351 12.4781 4.52909 12.637C4.71467 12.7958 4.86012 12.9963 4.95357 13.222C5.04703 13.4477 5.08586 13.6922 5.06691 13.9358C5.04795 14.1793 4.97174 14.4149 4.84448 14.6235C3.99801 16.0126 5.58768 17.6023 6.97688 16.7558C7.1854 16.6286 7.42102 16.5524 7.66457 16.5334C7.90812 16.5144 8.1527 16.5533 8.3784 16.6467C8.6041 16.7402 8.80454 16.8856 8.96339 17.0712C9.12224 17.2568 9.23502 17.4773 9.29253 17.7147C9.67628 19.2952 11.9251 19.2952 12.3079 17.7147C12.3656 17.4774 12.4785 17.2571 12.6373 17.0716C12.7962 16.8862 12.9966 16.7408 13.2223 16.6474C13.4479 16.554 13.6924 16.5151 13.9359 16.5339C14.1793 16.5528 14.4149 16.6288 14.6235 16.7558C16.0127 17.6023 17.6024 16.0126 16.7559 14.6235C16.6289 14.4149 16.5528 14.1793 16.534 13.9358C16.5152 13.6923 16.554 13.4479 16.6475 13.2222C16.7409 12.9966 16.8862 12.7962 17.0717 12.6373C17.2572 12.4784 17.4775 12.3655 17.7148 12.3078C19.2953 11.9241 19.2953 9.67529 17.7148 9.29256C17.4774 9.23505 17.2569 9.12227 17.0713 8.96342C16.8857 8.80457 16.7403 8.60414 16.6468 8.37844C16.5534 8.15275 16.5145 7.90817 16.5335 7.66462C16.5524 7.42108 16.6286 7.18546 16.7559 6.97694C17.6024 5.58777 16.0127 3.99812 14.6235 4.84458C14.415 4.97184 14.1794 5.04804 13.9358 5.067C13.6923 5.08596 13.4477 5.04713 13.222 4.95367C12.9963 4.86021 12.7959 4.71477 12.637 4.52919C12.4781 4.34361 12.3654 4.12314 12.3079 3.88573L12.3089 3.88472Z" stroke="black" strokeWidth="1.5" />
                <path d="M12.6002 10.8001C12.6002 11.7942 11.7943 12.6001 10.8002 12.6001C9.80608 12.6001 9.00019 11.7942 9.00019 10.8001C9.00019 9.80596 9.80608 9.00007 10.8002 9.00007C11.7943 9.00007 12.6002 9.80596 12.6002 10.8001Z" stroke="black" strokeWidth="1.5" />
            </svg>
        </>
    );
};

export default SettingsIcon;