import { Link, useNavigate } from 'react-router-dom';
import { getListNotificationread, getnotifications } from '@/pages/Admin/_request';
import { useEffect, useState } from 'react';
import BellIcon from './icon/BellIcon';
import SearchIcon from './icon/SearchIcon';
import SettingsIcon from './icon/SettingsIcon';
import UserIcon from './icon/UserIcon';
import close from '../../assets/icons/close.svg';
import { leadStageColors } from '../../utils/_gConstant';
import loaderd from '../../assets/icons/loading.gif';
import logo from '../../assets/img/ikosia-logo.svg';
import { toastSuccess } from '../ui-elements/_Toastify';

const Header = () => {
  const [isSearchVisible, setSearchVisible] = useState(false);
  const [notification, setNotification] = useState([]);
  const [isnotificationDropdown, setnotificationDropdown] = useState(false);
  const [isProfileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
    toastSuccess('Logout Successfully');
  };

  const notificationDropdown = () => {
    setLoader(true);
    getnotifications()
      .then((data) => {
        if (data.data.status) {
          setNotification(data.data.data);
        }
      })
      .catch((err) => {
        console.log('Errrorkof', err);
      })
      .finally(() => {
        setLoader(false);
      });
    setnotificationDropdown(!isnotificationDropdown);
    setProfileDropdownVisible(false);
  };

  useEffect(() => {
    getnotifications()
      .then((data) => {
        if (data.data.status) {
          setNotification(data.data.data);
        }
      })
      .catch((err) => {
        console.log('Errrorkof', err);
      });
  }, []);

  const handlenotificationread = (it) => {
    const id = it.lead?.id;
    if (it) {
      getListNotificationread(it.id)
        .then((data) => {
          if (data.data.status) {
            navigate(`/consultant/leads/details/${id}`);
          }
        })
        .catch((err) => {
          console.log('fgfg', err);
        });
    }
  };

  return (
    <>
      <div className='header'>
        <div className='inner-header'>
          <Link className='logo'>
            <img src={logo} alt='logo' />
          </Link>
          <div className='search_wapper-box'>
            <div className={`search-box ${isSearchVisible ? 'visible' : ''}`}>
              <form>
                <input placeholder='Search' type='text' />
                <button type='submit' className='search_button'>
                  <SearchIcon />
                </button>
              </form>
            </div>
            <Link className='headre-button search-icon' onClick={toggleSearch}>
              <SearchIcon />
            </Link>
            <div
              className='relative flex items-center justify-center ml-3 rounded-full cursor-pointer headre-button ball-icon'
              id='notification-container'
              style={{ width: 36, height: 36, border: '1px solid #DFDFDF' }}
              onClick={notificationDropdown}
            >
              <BellIcon />
              <ul
                className={`dropdown-menu notify md w-full ${isnotificationDropdown ? 'show' : ''}`}
                style={{
                  width: 500,
                  left: 'initial',
                  right: '-30px',
                  height: 500,
                  overflow: 'auto',
                      transform: "none",
                      transition:"none",
                }}
              >
                {loader ? (
                  <img
                    src={loaderd}
                    alt='loader'
                    style={{
                      width: '30px',
                      height: '30px',
                      margin: '10px',
                      position: 'absolute',
                      /* display: flex; */
                      /* justify-content: center; */
                      /* align-items: center; */
                      top: '45%',
                      left: '45%',
                    }}
                  />
                ) : (
                  notification &&
                  notification.length > 0 &&
                  notification.map((it) => (
                    <li
                      key={it.id}
                      className='dropdown-menu__list'
                      style={{ borderBottom: '1px solid #E2E2E2', marginBottom: 0 }}
                      onClick={() => handlenotificationread(it)}
                    >
                      <div
                        className='flex items-center justify-between tags rounded p-2'
                        style={leadStageColors[it.stage]}
                      >
                        <p className='text-md truncate'>{it?.lead.full_name}</p>
                        <p className='text-md truncate'>{it?.created_at}</p>
                      </div>
                      <p className='font-bold mt-2 px-2 text-secondary text-sm truncate'>
                        {it.message}
                      </p>
                    </li>
                  ))
                )}
              </ul>
            </div>
            <Link className='headre-button setting-icon'>
              <SettingsIcon />
            </Link>
            <Link className='headre-button user-icon' onClick={() => setShow(true)}>
              <UserIcon />
            </Link>
          </div>
        </div>
      </div>
      {show && (
        <div className='modal modal--add-lead open'>
          <div className='modal__container'>
            <div className='modal__header'>
              <h1 className='text-lg font-bold'>Log Out</h1>
              <div className='modal__close' onClick={() => setShow(false)}>
                <img src={close} alt='close' />
              </div>
            </div>
            <div className='modal__body'>
              <p className='input-field-label text-base text-center'>
                Are you sure you want to Log out?
              </p>
            </div>
            <div className='modal__footer justify-end'>
              <button
                type='button'
                className='btn btn--secondary btn--h40 px-6 py-2 text-base font-semibold'
                onClick={() => setShow(false)}
              >
                No
              </button>
              <button
                id='sub'
                form='sub'
                type='submit'
                className='btn btn--primary btn--h40 px-6 py-2 text-base font-semibold'
                onClick={handleLogout}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
