import '../../assets/styles/scss/pages/admin-dashboard.scss';

import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import BellIcon from "../../components/dashboard/icon/BellIcon";
import ChangePassword from '../../components/auth/component/ChangePassword';
import MyProfile from '../../components/auth/component/MyProfile';
import PageLoader from "../../components/dashboard/PageLoader";
import { Permissions } from '../../utils/_gConstant';
import { getListNotificationread } from "./_request";
import { getnotifications } from "./_request";
import { leadStageColors } from "../../utils/_gConstant";
import loaderd from "../../assets/icons/loading.gif";
import logo from '../../assets/img/ikosia-logo.svg';
import tag from '../../assets/icons/tag.svg';
import tagColor from '../../assets/icons/tagColor.svg';
import { toastSuccess } from '../../components/ui-elements/_Toastify';
import { useAuth } from '../../components/auth/core/Auth';
import user from '../../assets/icons/user.svg';
import userColor from '../../assets/icons/user-color.svg';

const AdminDashboard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const hasPermission = useAuth().hasPermission;
  const [myProfileVisible, setMyProfileVisible] = useState(false);
  const [isnotificationDropdown, setnotificationDropdown] = useState(false);
  const [notification, setNotification] = useState([]);
  const [loader,setLoader] = useState(false);
  const toggleMyProfile = () => {
    setMyProfileVisible(!myProfileVisible);
  };
  const [isMenuDropdownVisible, setMenuDropdownVisible] = useState(false);

  const toggleMenuDropdown = () => {
    setMenuDropdownVisible(!isMenuDropdownVisible);
  };

  const [isProfileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const toggleProfileDropdown = () => {
    setProfileDropdownVisible(!isProfileDropdownVisible);
  };

  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const toggleChangePassword = () => {
    setChangePasswordVisible(!changePasswordVisible);
  };
  const notificationDropdown = () => {
    setLoader(true)
    getnotifications()
    .then((data) => {
      if (data.data.status) {
        setNotification(data.data.data);
      }
    })
    .catch((err) => {
      console.log("Errrorkof", err);
    }).finally(()=>{
      setLoader(false)
    });
    setnotificationDropdown(!isnotificationDropdown);
    setProfileDropdownVisible(false);
  };

  const pathnameToTitleMap = {
    '/admin/leads/': 'Leads',
    '/admin/consultants/': 'Energy Consultants',
    '/admin/tags/': 'Tag Management',
    '/admin/partners/': 'Distribution Partners',
    '/admin/users/': 'User Management',
  };

  const [selectedOption, setSelectedOption] = useState({ title: 'Leads' });

  const handleOptionClick = (title) => {
    setSelectedOption({ title });
    localStorage.setItem('selectedOption', JSON.stringify({ title }));
    toggleMenuDropdown();
  };

  useEffect(() => {
    document.title = props.title;

    const handleOutsideClick = (event) => {
      if (!event.target.closest('#location-dropdown')) {
        setMenuDropdownVisible(false);
      }
      if (!event.target.closest('#profile-container')) {
        setProfileDropdownVisible(false);
      }
      if (!event.target.closest("#notification-container")) {
        setnotificationDropdown(false);
        
      }
    };

    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [props.title]);

  const [loading, setLoading] = useState(true); 
    useEffect(() => {
        setTimeout(() => {
            setLoading(false); 
        }, 2000); 
    }, []);

    const handlenotificationread = (it)=>{
      const id = it.lead?.id
      if(it){
        getListNotificationread(it.id).then((data)=>{
           if(data.data.status){
              navigate(`/admin/leads/details/${id}/`)
           }
        }).catch((err)=>{
          console.log("fgfg", err)
        })
      }
    }

  useEffect(() => {
    const currentTitle = pathnameToTitleMap[location.pathname] || 'Leads';
    setSelectedOption({ title: currentTitle });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
    {loading && <PageLoader />} 
      <section className='admin-dashboard-wrapper'>
        <header className='admin-dashboard__header'>
          <div className=''>
            <img src={logo} alt='logo' />
          </div>

          <div className='flex items-center'>
            <div className='custom-select-wrapper' id='location-dropdown'>
              <input
                type='text'
                className='input-field input-field--select input-field--h40'
                placeholder=''
                onClick={toggleMenuDropdown}
                value={selectedOption.title}
                readOnly
              />
              <ul
                className={`dropdown-menu w-full ${isMenuDropdownVisible ? 'show' : ''}`}
                style={{ padding: '10px' }}
              >
                {hasPermission(Permissions.VIEW_LEAD) && (
                  <NavLink
                    className='admin-dashboard__nav__list pt-5 pl-3'
                    to={'/admin/leads/'}
                    onClick={() => handleOptionClick('Leads')}
                  >
                    <div className='admin-dashboard__nav__icon'>
                      <img
                        src={user}
                        alt='user-icon'
                        className='admin-dashboard__nav__icon__default'
                      />
                      <img
                        src={userColor}
                        alt='user-icon'
                        className='admin-dashboard__nav__icon__color'
                      />
                    </div>
                    <span className='admin-dashboard__nav__title'>Leads</span>
                  </NavLink>
                )}
                {hasPermission(Permissions.VIEW_ENERGY_CONSULTANT) && (
                  <NavLink
                    className='admin-dashboard__nav__list pt-5 px-3'
                    to={'/admin/consultants/'}
                    onClick={() => handleOptionClick('Energy Consultants')}
                  >
                    <div className='admin-dashboard__nav__icon'>
                      <img
                        src={user}
                        alt='user-icon'
                        className='admin-dashboard__nav__icon__default'
                      />
                      <img
                        src={userColor}
                        alt='user-icon'
                        className='admin-dashboard__nav__icon__color'
                      />
                    </div>
                    <span className='admin-dashboard__nav__title'>Energy Consultants</span>
                  </NavLink>
                )}
                {hasPermission(Permissions.MANAGE_TAGS) && (
                  <NavLink
                    className='admin-dashboard__nav__list pt-5 px-3'
                    to={'/admin/tags/'}
                    onClick={() => handleOptionClick('Tag Management')}
                  >
                    <div className='admin-dashboard__nav__icon'>
                      <img
                        src={tag}
                        alt='user-icon'
                        className='admin-dashboard__nav__icon__default'
                      />
                      <img
                        src={tagColor}
                        alt='user-icon'
                        className='admin-dashboard__nav__icon__color'
                      />
                    </div>
                    <span className='admin-dashboard__nav__title'>Tag Management</span>
                  </NavLink>
                )}
                {(hasPermission(Permissions.VIEW_BACKOPS_ADMIN) ||
                  hasPermission(Permissions.VIEW_BACKOPS_USER)) && (
                  <>
                    <NavLink
                      className='admin-dashboard__nav__list pt-5 px-3'
                      to={'/admin/partners/'}
                      onClick={() => handleOptionClick('Distribution Partners')}
                    >
                      <div className='admin-dashboard__nav__icon'>
                        <img
                          src={user}
                          alt='user-icon'
                          className='admin-dashboard__nav__icon__default'
                        />
                        <img
                          src={userColor}
                          alt='user-icon'
                          className='admin-dashboard__nav__icon__color'
                        />
                      </div>
                      <span className='admin-dashboard__nav__title'>Distribution Partners</span>
                    </NavLink>
                    <NavLink
                      className='admin-dashboard__nav__list py-5 px-3'
                      to={'/admin/users/'}
                      onClick={() => handleOptionClick('User Management')}
                    >
                      <div className='admin-dashboard__nav__icon'>
                        <img
                          src={user}
                          alt='user-icon'
                          className='admin-dashboard__nav__icon__default'
                        />
                        <img
                          src={userColor}
                          alt='user-icon'
                          className='admin-dashboard__nav__icon__color'
                        />
                      </div>
                      <span className='admin-dashboard__nav__title'>User Management</span>
                    </NavLink>
                  </>
                )}
              </ul>
            </div>
            <div
              className="relative flex items-center justify-center ml-3 rounded-full cursor-pointer"
              id="notification-container"
              style={{ width: 36, height: 36, border: "1px solid #DFDFDF" }}
              onClick={notificationDropdown}
            >
              <BellIcon />
              <ul
                className={`dropdown-menu notify md w-full ${
                  isnotificationDropdown ? "show" : ""
                }`}
                style={{
                  width: 500,
                  left: "initial",
                  right: "-30px",
                  height: 500,
                  overflow: "auto",
                }}
              >
                {loader ? (<img
                    src={loaderd}
                    alt="loader"
                    style={{
                      width: "30px",
                      height: "30px",
                      margin: "10px",
                      position: 'absolute',
                      top: '45%',
                      left: '45%'
                    }}
                  />) : notification &&
                  notification.length > 0 &&
                  notification.map((it) => (
                    <li
                      key={it.id}
                      className="dropdown-menu__list"
                      style={{
                        borderBottom: "1px solid #E2E2E2",
                        marginBottom: 0,
                      }}
                      onClick={()=>handlenotificationread(it)}
                      >
                      <div
                        className="flex items-center justify-between tags rounded p-2"
                        style={leadStageColors[it.stage]}
                      >
                        <p className="text-md truncate">{it?.lead.full_name}</p>
                        <p className="text-md truncate">
                          {it?.created_at}
                        </p>
                      </div>
                      <p className="font-bold mt-2 px-2 text-secondary text-sm truncate">
                        {it.message}
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
            <div
              className='relative flex items-center justify-center ml-3 rounded-full cursor-pointer'
              id='profile-container'
              style={{ width: 36, height: 36, border: '1px solid #DFDFDF' }}
              onClick={toggleProfileDropdown}
            >
              <img src={user} alt='user-icon' className='' />
              <ul
                className={`dropdown-menu md w-full ${isProfileDropdownVisible ? 'show' : ''}`}
                style={{ width: 155, left: 'initial', right: '-30px' }}
              >
                <li
                  className='dropdown-menu__list'
                  style={{ borderBottom: '1px solid #E2E2E2', marginBottom: 0 }}
                  onClick={toggleMyProfile}
                >
                  My Profile
                </li>
                <li
                  className='dropdown-menu__list'
                  style={{ borderBottom: '1px solid #E2E2E2', marginBottom: 0 }}
                  onClick={toggleChangePassword}
                >
                  Change Password
                </li>
                <li
                  className='dropdown-menu__list'
                  style={{ marginBottom: 0 }}
                  onClick={() => {
                    localStorage.clear();
                    dispatch({ type: 'USER_LOGOUT' });
                    toastSuccess('Sie wurden abgemeldet!');
                    navigate('/');
                  }}
                >
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </header>
        <Outlet />
      </section>

      {myProfileVisible && <MyProfile closeModal={toggleMyProfile} />}
      {changePasswordVisible && <ChangePassword closeModal={toggleChangePassword} />}
    </>
  );
};

export default AdminDashboard;
