const InfoIcon = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0.75C9.77497 0.75 7.59989 1.4098 5.74984 2.64597C3.89979 3.88213 2.45785 5.63914 1.60636 7.69481C0.754874 9.75048 0.532087 12.0125 0.966171 14.1948C1.40025 16.3771 2.47171 18.3816 4.04505 19.955C5.61839 21.5283 7.62295 22.5997 9.80524 23.0338C11.9875 23.4679 14.2495 23.2451 16.3052 22.3936C18.3609 21.5422 20.1179 20.1002 21.354 18.2502C22.5902 16.4001 23.25 14.225 23.25 12C23.25 9.01631 22.0647 6.15483 19.955 4.04505C17.8452 1.93526 14.9837 0.75 12 0.75ZM12 21.375C10.1458 21.375 8.33325 20.8252 6.79153 19.795C5.24982 18.7649 4.04821 17.3007 3.33863 15.5877C2.62906 13.8746 2.44341 11.9896 2.80514 10.171C3.16688 8.35246 4.05976 6.68199 5.37088 5.37087C6.682 4.05976 8.35246 3.16687 10.171 2.80514C11.9896 2.4434 13.8746 2.62906 15.5877 3.33863C17.3007 4.0482 18.7649 5.24982 19.795 6.79153C20.8252 8.33324 21.375 10.1458 21.375 12C21.375 14.4864 20.3873 16.871 18.6291 18.6291C16.871 20.3873 14.4864 21.375 12 21.375Z" fill="#231F20" />
                <path d="M12.0031 9.84507C11.7545 9.84507 11.516 9.94384 11.3402 10.1197C11.1644 10.2955 11.0656 10.5339 11.0656 10.7826V17.1576C11.0656 17.4062 11.1644 17.6447 11.3402 17.8205C11.516 17.9963 11.7545 18.0951 12.0031 18.0951C12.2518 18.0951 12.4902 17.9963 12.666 17.8205C12.8419 17.6447 12.9406 17.4062 12.9406 17.1576V10.7826C12.9406 10.5339 12.8419 10.2955 12.666 10.1197C12.4902 9.94384 12.2518 9.84507 12.0031 9.84507ZM12.0031 6.00132C11.7493 6.01086 11.5088 6.11721 11.331 6.29854C11.1532 6.47986 11.0515 6.72239 11.0469 6.97632V7.12632C11.0464 7.24864 11.0715 7.36971 11.1204 7.48183C11.1693 7.59395 11.241 7.69466 11.3309 7.77755C11.4209 7.86044 11.5271 7.9237 11.6428 7.96331C11.7585 8.00291 11.8813 8.01798 12.0031 8.00757C12.2488 7.99829 12.4819 7.89654 12.6558 7.72269C12.8296 7.54885 12.9314 7.31575 12.9406 7.07007V6.84507C12.9414 6.72592 12.9169 6.60796 12.8687 6.49896C12.8206 6.38996 12.7499 6.2924 12.6613 6.21269C12.5728 6.13298 12.4683 6.07293 12.3549 6.0365C12.2414 6.00007 12.1215 5.98808 12.0031 6.00132Z" fill="#231F20" />
            </svg>
        </>
    );
};

export default InfoIcon;