import React, { useEffect } from 'react';
import logo from "../../assets/img/ikosia-logo.svg";
const PageLoader = (prop) => {
  const style = prop.style ? {
    position: 'relative',
    right: "0%",
    bottom: '0%'
  } : {};
  
 
  return (
    <div className={`${prop.className ? `page-loader ${prop.className}` : "page-loader"}`} >
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
};


export default PageLoader;