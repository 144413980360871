import {
  handleDeleteDataFromApi,
  handleGetDataFromApi,
  handlePatchDataFromApi,
  handlePostAPIwithout_token,
  handlePostDataFromApiLogin,
  handlePostDataWithTokenFromApi,
  handleSignUpAPI,
} from '@/utils/_gFunctions/_handleAPI';
import { toastError, toastErrors, toastSuccess } from '../../ui-elements/_Toastify';

import { ENUM_API_STATUS } from '@/utils/_gConstant';
import axios from 'axios';
import siteConfig from '@/services/_siteConfig';

export async function login(email, password) {
  const res = await handlePostDataFromApiLogin(siteConfig.LOGIN, { email, password }, undefined);

  if (res?.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
    // toastSuccess("Erfolgreich eingeloggt!");
  } else {
    toastErrors(res?.data);
  }
  return res;
}

export async function getUserByToken(token) {
  const res = await axios.get(siteConfig.BASE_URL + siteConfig.GET_USER_BY_ACCESSTOKEN_URL, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export async function refreshAccessTokenPostRequest(token) {
  const res = await axios.post(siteConfig.BASE_URL + siteConfig.REFRESH_TOKEN_URL, {
    refresh: token,
  });
  return res;
}

export const signupRequest = async (body, isLoading) => {
  try {
    const res = await handleSignUpAPI(siteConfig.SIGNUP, body, isLoading);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error('Error creating ticket:', error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const resetpasswordEmailRequest = async (body, token, isLoading) => {
  try {
    const res = await handleSignUpAPI(siteConfig.FORGOT_PASSWORD_EMAIL, body, isLoading);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error('Error creating ticket:', error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const forgotpasswordEmailRequest = async (body, token, isLoading) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(siteConfig.BASE_URL + '/account/reset-password/', body, config);
    return res;
  } catch (error) {
    toastErrors(error?.response?.data);
    console.error('Error creating ticket:', error);
    throw error;
  }
};

export const changePasswordRequest = async (body, isLoading) => {
  try {
    const res = await handlePatchDataFromApi(siteConfig.CHANGE_PASSWORD, body, isLoading);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error('Error creating ticket:', error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const EditProfileRequest = async (body, isLoading) => {
  try {
    const res = await handlePatchDataFromApi(
      siteConfig.GET_USER_BY_ACCESSTOKEN_URL,
      body,
      isLoading
    );
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error('Error creating ticket:', error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const requestOtpPostRequest = async (body, isLoading) => {
  try {
    const res = await handlePostDataFromApiLogin(siteConfig.REQUEST_OTP, body, isLoading);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API_200) toastSuccess('Angefordertes OTP.');
    else toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error('Error creating ticket:', error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const verifyOtpPostRequest = async (body, isLoading) => {
  try {
    const res = await handlePostDataFromApiLogin(siteConfig.VERIFY_OTP, body, isLoading);
    if (res?.status === ENUM_API_STATUS?.SUCCESS_POST_API_200) toastSuccess('Email verified.');
    else toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error('Error creating ticket:', error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const setPasswordPostRequest = async (body, isLoading) => {
  try {
    const res = await handlePostDataFromApiLogin(siteConfig.SET_PASSWORD, body, isLoading);
    if (res?.data?.status) toastSuccess('Passwort erfolgreich gesetzt!');
    else toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error('Error creating ticket:', error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const getDashboardDataGetRequest = async (leadId, isLoading) => {
  const url = siteConfig.DASHBOARD_STATISTICS.replace('<lead-id>', leadId);
  try {
    const res = await handleGetDataFromApi(url, isLoading);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error('Error fetching data:', error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const linkValidationCheck = async (body, isLoading) => {
  try {
    const res = await handleSignUpAPI(siteConfig.URL_VALIDATION, body);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error('Error creating ticket:', error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const forgotEmailPassword = async (body, isLoading) => {
  try {
    // if (res.data.status) toastSuccess("Passwort erfolgreich gesetzt!");
    // else toastError(res.data.message)
    return await handleSignUpAPI(siteConfig.EMAIL_ID, body, isLoading);
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error('Error creating ticket:', error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const emailResetPostAPI = async (body, isLoading) => {
  try {
    // if (res.data.status) toastSuccess("Passwort erfolgreich gesetzt!");
    // else toastError(res.data.message)
    return await handleSignUpAPI(siteConfig.RESET_PASSWORD, body, isLoading);
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error('Error creating ticket:', error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const verifyEmail = async (body, isLoading) => {
  try {
    return await handlePostAPIwithout_token(siteConfig.VERIFY_EMAIL, body, isLoading);
  } catch (error) {
    console.log('Error in the API of Verify Email', error);
    throw error;
  }
};

export const distributionPartnerfetchDetailsrequest = async (body, isLoading) => {
  try {
    return await handlePostAPIwithout_token(
      `${siteConfig.DISTRIBUTION_PARTNER}users/validate-invitation/`,
      body,
      isLoading
    );
  } catch (error) {
    console.log('Error in the API of Verify Email', error);
    throw error;
  }
};

export const distributionPartnerAcceptInvitePostRequest = async (body, token, isLoading) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${siteConfig.BASE_URL}${siteConfig.DISTRIBUTION_PARTNER}users/accept-invitation/`,
      body,
      config
    );
    return res;
  } catch (error) {
    toastErrors(error?.response?.data);
    console.error('Error creating ticket:', error);
    throw error;
  }
};
//***USER QUESTIONNAIRE */
export const getQuestionnaire = async (isLoading) => {
  const url = siteConfig.USER_QUESTIONNAIRE;
  try {
    const res = await handleGetDataFromApi(url, isLoading);
    if (res.status !== ENUM_API_STATUS?.SUCCESS_POST_API_200) toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error("Error fetching data:", error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

//***ADMIN DETAIL */
export const getDetail = async (id, isLoading) => {
  const url = siteConfig.QUESTION_DETAIL;
  try {
    const res = await handleGetDataFromApi(url + "/" + id, isLoading);
    if (res.status !== ENUM_API_STATUS?.SUCCESS_POST_API_200) toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error("Error fetching data:", error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

//***GET DOCUMENTS */
export const getDoc = async (id, isLoading) => {
  const url = siteConfig.GET_DOCUMENTS;
  try {
    const res = await handleGetDataFromApi(url + "/" + id, isLoading);
    if (res.status !== ENUM_API_STATUS?.SUCCESS_POST_API_200) toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error("Error fetching data:", error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

//**DELETE DOCUMENTS */

export const deletfile = async (id, isLoading) => {
  const url = siteConfig.DELETE_DOCUMENT;
  try {
    const res = await handleDeleteDataFromApi(url + "/" + id, isLoading);
    if (res.status !== ENUM_API_STATUS?.SUCCESS_POST_API_200) toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error("Error fetching data:", error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

//**DELETE ISFP DOCUMENTS */

export const deleteisfpfile = async (id, isLoading) => {
  const url = siteConfig.ISFP_DELETE;
  try {
    const res = await handleGetDataFromApi(url + "/" + id, isLoading);
    if (res.status !== ENUM_API_STATUS?.SUCCESS_POST_API_200) toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error("Error fetching data:", error);
    throw error; // Optional: Rethrow the error to the caller
  }
};

export const submitAnswers = async (body, isLoading) => {
  const url = siteConfig.USER_SUBMIT_ANSWER;
  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem(siteConfig.ACCESS_TOKEN),
  };
  try {
    const res = await handlePostDataWithTokenFromApi(
      url,
      body,
      header,
      isLoading
    );
    if (res.status == 500) {
      toastError("Nextwork Error");
    }
    toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error("Error fetching data:", error);
    throw error; // Optional: Rethrow the error to the caller
  }
};
export const getDashboardData = async (isLoading) => {
  const url = siteConfig.USER_DASHBOARD;
  try {
    const res = await handleGetDataFromApi(url, isLoading);
      if (res.status == 500) {
        toastError("Nextwork Error");
      }
    toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error("Error fetching data:", error);
    throw error; // Optional: Rethrow the error to the caller
  }
};
/***UPLOAD */
export const getUploadQuestionnaire = async (isLoading) => {
  const url = siteConfig.USER_GET_UPLOAD_QUESTIONS;
  try {
    const res = await handleGetDataFromApi(url, isLoading);
    if (res.status !== ENUM_API_STATUS?.SUCCESS_POST_API_200) toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error("Error fetching data:", error);
    throw error; // Optional: Rethrow the error to the caller
  }
};
export const uploadQuestionnaire = async (body, isLoading) => {
  const url = siteConfig.USER_UPLOAD_QUESTIONS;
  const header = {
    "Content-Type": ["application/json", "multipart/form-data"],
    Authorization: "Bearer " + localStorage.getItem(siteConfig.ACCESS_TOKEN),
  };
  try {
    const res = await handlePostDataWithTokenFromApi(
      url,
      body,
      header,
      isLoading
    );
    if (res.status !== ENUM_API_STATUS?.SUCCESS_POST_API_200) toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error("Error fetching data:", error);
    throw error; // Optional: Rethrow the error to the caller
  }
};
export const saveLead = async (body, isLoading) => {
  const url = siteConfig.USER_SAVE_LEAD;
  const header = {
    "Content-Type": ["application/json", "multipart/form-data"],
    Authorization: "Bearer " + localStorage.getItem(siteConfig.ACCESS_TOKEN),
  };
  try {
    const res = await handlePostDataWithTokenFromApi(
      url,
      body,
      header,
      isLoading
    );
    if (res.status !== ENUM_API_STATUS?.SUCCESS_POST_API_200) toastError(res?.data?.message);
    return res;
  } catch (error) {
    // Handle any errors that occur during the API request
    console.error("Error fetching data:", error);
    throw error; // Optional: Rethrow the error to the caller
  }
};