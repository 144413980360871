const BellIcon = () => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.42099 13.802L2.79849 13.3837L2.79849 13.3837L3.42099 13.802ZM4.39013 10.6959L3.64013 10.6877V10.6959H4.39013ZM4.41048 8.81783L5.16048 8.82596V8.81783H4.41048ZM16.5835 13.8171L17.214 13.4108L17.214 13.4108L16.5835 13.8171ZM15.6509 10.6959L14.9009 10.6873V10.6959H15.6509ZM15.6712 8.91089L16.4212 8.91944V8.91089H15.6712ZM7.77745 16.75C7.36324 16.75 7.02745 17.0858 7.02745 17.5C7.02745 17.9142 7.36324 18.25 7.77745 18.25V16.75ZM12.2219 18.25C12.6361 18.25 12.9719 17.9142 12.9719 17.5C12.9719 17.0858 12.6361 16.75 12.2219 16.75V18.25ZM4.04348 14.2204C4.59698 13.3967 5.14013 12.1771 5.14013 10.6959H3.64013C3.64013 11.8067 3.2313 12.7397 2.79849 13.3837L4.04348 14.2204ZM5.14009 10.704L5.16043 8.82595L3.66052 8.80971L3.64018 10.6877L5.14009 10.704ZM17.214 13.4108C16.7989 12.7667 16.4009 11.8237 16.4009 10.6959H14.9009C14.9009 12.178 15.422 13.3993 15.9531 14.2234L17.214 13.4108ZM16.4008 10.7044L16.4212 8.91944L14.9213 8.90235L14.9009 10.6873L16.4008 10.7044ZM16.4212 8.91089C16.4212 5.04506 13.6299 1.75 9.99967 1.75V3.25C12.634 3.25 14.9212 5.69545 14.9212 8.91089H16.4212ZM16.2441 15.4375C16.8632 15.4375 17.2014 14.957 17.3225 14.6355C17.45 14.2969 17.4767 13.8185 17.214 13.4108L15.9531 14.2234C15.9208 14.1732 15.9169 14.1346 15.9164 14.1246C15.9159 14.1147 15.9172 14.1109 15.9187 14.1069C15.9194 14.1049 15.9306 14.074 15.9745 14.0351C16.0242 13.991 16.1183 13.9375 16.2441 13.9375V15.4375ZM5.16048 8.81783C5.16048 5.65379 7.4108 3.25 9.99967 3.25V1.75C6.41489 1.75 3.66048 5.0034 3.66048 8.81783H5.16048ZM3.75604 13.9375C3.8846 13.9375 3.97964 13.993 4.02858 14.0373C4.07157 14.0762 4.08154 14.106 4.08154 14.106C4.08234 14.1082 4.08343 14.1105 4.08286 14.1197C4.08231 14.1288 4.07815 14.1688 4.04348 14.2204L2.79849 13.3837C2.52336 13.7931 2.54809 14.2798 2.67258 14.6206C2.79028 14.9429 3.12704 15.4375 3.75604 15.4375V13.9375ZM16.2441 13.9375H3.75604V15.4375H16.2441V13.9375ZM7.77745 18.25H12.2219V16.75H7.77745V18.25Z" fill="black" />
            </svg>
        </>
    );
};

export default BellIcon;