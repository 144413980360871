import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { App } from '../App';
import Login from '../components/auth/component/Login';
import QuestionDetailed from "../pages/HomeOwner/QuestionDetailed";
import ResetPassword from '../components/auth/component/ResetPassword';
import ResetPasswordStep2 from '../components/auth/component/ResetPasswordStep2';
import SlugRoutes from './SlugRoutes';
// import SurveyRoutes from "./SurveyRoutes";
import TagManager from 'react-gtm-module';
import VerifyEmailPage from '../components/common/VerifyEmailPage';
import { hotjar } from 'react-hotjar';
import { useEffect } from 'react';

import Deshboard from '@/pages/HomeOwner/Dashboard';
import Uploads from "../pages/HomeOwner/Uploads";

const tagManagerArgs = {
  gtmId: 'GTM-T2W2WZXF',
};
TagManager.initialize(tagManagerArgs);
const AppRoutes = () => {
  useEffect(() => {
    hotjar.initialize(3910056, 6);
  }, []);
  window.dataLayer.push({
    event: 'pageview',
    eventProps: {
      category: 'account',
      action: 'login',
      label: '9-question done',
      value: '1',
    },
  });

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<App />} />
          <Route path='/' element={<Login title='App | IKOSIA' />} />
          <Route path='/verify-email' element={<VerifyEmailPage title='App | IKOSIA' />} />
          <Route path='/reset-password-email' element={<ResetPassword title='App | IKOSIA' />} />
          <Route path='/reset-password' element={<ResetPasswordStep2 title='App | IKOSIA' />} />
          <Route path="/detailed-questionnaire" element={<QuestionDetailed title="Detailed Questionnaire" />} />
          <Route path="/uploads" element={<Uploads title="Detailed Questionnaire" />} />
          <Route path="/questiondashboard" element={<Deshboard title="Dashboard | IKOSIA" />}
          />
          {/* <Route path="/survey/:leadId/:questionIndex" element={
              <SurveyRoutes key={window.location.pathname}>
                <HomeOwner title="App | IKOSIA" />
              </SurveyRoutes>
            }
          /> */}
        </Routes>
        <SlugRoutes />
      </BrowserRouter>
    </>
  );
};
export default AppRoutes;
