import { ENUM_API_STATUS, globalConstant } from '@/utils/_gConstant';
import { toastError, toastErrors } from '@/components/ui-elements/_Toastify';

import Cookies from 'universal-cookie';
import LocalStorageService from './_localStorageServices';
import axios from 'axios';
import siteConfig from './_siteConfig';

const cookies = new Cookies();

const api = axios.create({
  baseURL: siteConfig.BASE_URL,
});
const signUpAPI = axios.create({
  baseURL: siteConfig.BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = LocalStorageService.getAccessToken();

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },

  async function (error) {
    const originalRequest = error.config;

    if (error.response === 401) {
      toastError('some thing wrong');
      console.log(error.response.data);
      window.location.href = '/';
      LocalStorageService.removeToken();
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = LocalStorageService.getRefreshToken();
      return axios
        .post(siteConfig.BASE_URL + '/api/users/token/refresh/', {
          refresh: refreshToken,
        })
        .then((res) => {
          if (res.status === ENUM_API_STATUS?.SUCCESS_GET_API) {
            LocalStorageService.removeToken();
            LocalStorageService.setToken(res?.data?.access);
            originalRequest.headers.Authorization =
              'Bearer ' + LocalStorageService.getAccessToken();
            return axios(originalRequest);
          }
        })
        .catch(() => {
          localStorage.clear();
          cookies.remove('dashboard', {
            domain: process.env.REACT_APP_DOMAIN,
            path: '/',
          });

          cookies.remove('userName', {
            domain: process.env.REACT_APP_DOMAIN,
            path: '/',
          });
          console.log(error.response.data);
          if (error.response.data?.message) {
            toastErrors(error.response.data);
            setTimeout(() => {
              LocalStorageService.removeToken();
              window.location.href = '/';
            }, 5000);
          } else {
            toastError('some thing wrong');
            LocalStorageService.removeToken();
            window.location.href = '/';
          }
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  }
);

class ApiService {
  handleError(error) {
    if (error.response?.status === 500) {
      console.log('Internal Server Error 500');
      return { status: 500, message: 'Internal Server Error' };
    } else {
      try {
        if (error.response) return error.response;
        else if (error.request) return error.request;
        else return globalConstant.INTERNAL_SERVER_ERROR;
      } catch (err) {
        console.log(err);
      }
    }
  }

  async get(url, params = null, config = {}) {
    let res;
    await api
      .get(
        siteConfig.BASE_URL + url,
        {
          params,
          ...config,
        }
        //@ts-ignore
        // this.getHeader()
      )
      .then((data) => {
        res = data;
      })
      .catch((err) => {
        res = this.handleError(err);
      });
    return res;
  }

  async post(url, body,header) {
    let res;
    await api
      .post(siteConfig.BASE_URL + url, body,header)
      .then((data) => {
        res = data;
      })
      .catch((err) => {
        res = this.handleError(err);
      });

    return res;
  }

  async put(url, body) {
    let res;
    await api
      .put(siteConfig.BASE_URL + url, body)
      .then((data) => {
        res = data;
      })
      .catch((err) => {
        res = this.handleError(err);
      });

    return res;
  }
  async delete(url) {
    let res;
    await api
      .delete(siteConfig.BASE_URL + url)
      .then((data) => {
        res = data;
      })
      .catch((err) => {
        res = this.handleError(err);
      });

    return res;
  }
  async patch(url, body) {
    let res;
    await api
      .patch(siteConfig.BASE_URL + url, body)
      .then((data) => {
        res = data;
      })
      .catch((err) => {
        res = this.handleError(err);
      });

    return res;
  }
}

export class ApiServiceSignUP {
  handleError(error) {
    if (error.response?.status === 500) {
      console.log('Internal Server Error 500');
      return { status: 500, message: 'Internal Server Error' };
    } else {
      try {
        if (error.response) return error.response;
        else if (error.request) return error.request;
        else return globalConstant.INTERNAL_SERVER_ERROR;
      } catch (err) {
        console.log(err);
      }
    }
  }

  async get(url) {
    let res;
    await signUpAPI
      .get(
        siteConfig.BASE_URL + url
        //@ts-ignore
        // this.getHeader()
      )
      .then((data) => {
        res = data;
      })
      .catch((err) => {
        res = this.handleError(err);
      });
    return res;
  }

  async post(url, body) {
    let res;
    await signUpAPI
      .post(siteConfig.BASE_URL + url, body)
      .then((data) => {
        res = data;
      })
      .catch((err) => {
        res = this.handleError(err);
      });

    return res;
  }

  async put(url, body) {
    let res;
    await signUpAPI
      .put(siteConfig.BASE_URL + url, body)
      .then((data) => {
        res = data;
      })
      .catch((err) => {
        res = this.handleError(err);
      });

    return res;
  }
  async delete(url) {
    let res;
    await signUpAPI
      .delete(siteConfig.BASE_URL + url)
      .then((data) => {
        res = data;
      })
      .catch((err) => {
        res = this.handleError(err);
      });

    return res;
  }
  async patch(url, body) {
    let res;
    await signUpAPI
      .patch(siteConfig.BASE_URL + url, body)
      .then((data) => {
        res = data;
      })
      .catch((err) => {
        res = this.handleError(err);
      });

    return res;
  }
}

export var apiServiceSignUp = new ApiServiceSignUP();
// eslint-disable-next-line import/no-anonymous-default-export
export default new ApiService();
