const UserIcon = () => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9994 18.0001L16.9997 15.0003C16.9998 13.3434 15.6566 12.0001 13.9997 12.0001H6.00028C4.34355 12.0001 3.00046 13.343 3.00028 14.9997L2.99994 18.0001M12.9999 5.00006C12.9999 6.65691 11.6568 8.00006 9.99994 8.00006C8.34308 8.00006 6.99994 6.65691 6.99994 5.00006C6.99994 3.34321 8.34308 2.00006 9.99994 2.00006C11.6568 2.00006 12.9999 3.34321 12.9999 5.00006Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default UserIcon;