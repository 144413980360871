const ArrowIcon = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.45802 7.08064L16.98 7.08052M16.98 7.08052L16.98 15.4813M16.98 7.08052L7.08052 16.98" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default ArrowIcon;