import { Outlet, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Stepper from '../components/Stepper';
import { useTranslation } from 'react-i18next';

const steps = ['Form', 'Services', 'Confirmation'];

const stepMap = {
  '/dp/order/form': 0,
  '/dp/order/services': 1,
  '/dp/order/confirmation': 2,
};

const DPFormLayout = (props) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setActiveStep(stepMap[location.pathname]);
    document.title = props.title;
  }, [location.pathname,props.title]);

  return (
    <section className='page-bg'>
      <Header />
      <Sidebar />
      <main className='page-main'>
        <div className='max-container'>
          <div className='w-full col-6-md mx-auto mt-5'>
            <Stepper
              steps={steps.map((step) => t(`dpFormLayout.steps.${step}`))}
              activeStep={activeStep}
            />
          </div>
          <Outlet />
        </div>
      </main>
    </section>
  );
};

export default DPFormLayout;
