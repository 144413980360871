import {
  handleDeleteDataFromApi,
  handleGetDataForFile,
  handleGetDataFromApi,
  handleGetDataFromQueryParamsApi,
  handlePatchDataFromApi,
  handlePostDataFromApi,
} from '@/utils/_gFunctions/_handleAPI';
import { toastError, toastErrors } from '@/components/ui-elements/_Toastify';

import ApiService from '@/services/_apiservices';
import { ENUM_API_STATUS } from '@/utils/_gConstant';
import siteConfig from '@/services/_siteConfig';

export const objectToQueryString = (obj) => {
  return (
    Object.entries(obj)
      // eslint-disable-next-line array-callback-return
      .flatMap(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${key}=${encodeURIComponent(v)}`);
        } else if (typeof value === 'object' && value !== null) {
          const nestedQueryString = objectToQueryString(value);
          if (nestedQueryString !== '') {
            return [`${key}=${encodeURIComponent(nestedQueryString)}`];
          }
        } else {
          return [`${key}=${encodeURIComponent(value)}`];
        }
      })
      .join('&')
  );
};

export const LeadsGetRequest = async (queryParams, isLoading) => {
  const url = `${siteConfig.LEADS}?${objectToQueryString(queryParams)}`;
  try {
    const res = await handleGetDataFromApi(url, queryParams, isLoading);
    return res;
  } catch (error) {
    console.error('Error in Fetching Data', error);
  }
};

export const LeadListViewGetRequest = async (queryParams, isLoading) => {
  const url = `${siteConfig.LISTING_LEADS}?${objectToQueryString(queryParams)}`;
  try {
    const res = await handleGetDataFromApi(url, isLoading);
    return res;
  } catch (error) {
    console.log('Error in Fetching the List View', error);
  }
};

export const LeadListGet = async (queryParams, isLoading) => {
  const url = `${siteConfig.LISTING_LEADS}`;
  try {
    const res = await handleGetDataFromApi(url, isLoading);
    return res;
  } catch (error) {
    console.log("Error in Fetching the List View", error);
  }
};

export const getnotifications = async (queryParams, isLoading) => {
  const url = `${siteConfig.GET_NOTIFICATIONS}`;
  try {
    const res = await handleGetDataFromApi(url, isLoading);
    if(res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API){
      toastError(res?.data);
    }
    return res;
  } catch (error) {
    console.log("Error in Fetching the List View", error);
    return error
  }
};

export const LeadListSingleViewGetRequest = async (queryParams, stage, isLoading) => {
  const url = `${siteConfig.LEADS}?stage=${stage}&${objectToQueryString(queryParams)}`;
  try {
    const res = await handleGetDataFromApi(url, isLoading);
    return res;
  } catch (error) {
    console.log('Error in Fetching the List View', error);
  }
};

export const editLead = async (id, body, isLoading) => {
  try {
    const res = await handlePatchDataFromApi(`${siteConfig.UPDATE_LEADS + id}/`, body);
    if (res?.status !== ENUM_API_STATUS.SUCCESS_PATCH_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in Updating the Data', error);
  }
};

export const FIlterLeadsGetRequest = async (key, isLoading) => {
  try {
    const res = await handleGetDataFromQueryParamsApi(siteConfig.FILTER_VALUES, key, isLoading);
    return res;
  } catch (error) {
    console.log('Error in Fetching the List View', error);
  }
};

export const updateLeadStageRequest = async (id, stageId, isLoading) => {
  try {
    const res = await handlePatchDataFromApi(`${siteConfig.UPDATE_LEADS + id}/`, {
      stages: stageId,
    });
    return res;
  } catch (error) {
    console.error('Error in Updating the Data', error);
  }
};

export const addNewLeadRequest = async (body, isLoading) => {
  try {
    const res = await handlePostDataFromApi(siteConfig.UPDATE_LEADS, body);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_POST_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in Creating new Lead');
  }
};

export const stageChangeapi = async (body, isLoading) => {
  try {
    const res = await handlePostDataFromApi(siteConfig.STATUS_UPDATE, body);
    if(res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API){
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error("Error in Creating new Lead");
    return error
  }
};

export const getLeadFilterOptionsGetRequest = async (isLoading) => {
  try {
    const res = await handleGetDataFromApi(siteConfig.FILTER_OPTIONS);
    return res;
  } catch (error) {
    console.error('Error in fetching filter options.');
  }
};

export const getLeadFilterValuesGetRequest = async (key, search, isLoading) => {
  try {
    const res = await handleGetDataFromQueryParamsApi(siteConfig.FILTER_VALUES, { key, search });
    return res;
  } catch (error) {
    console.error('Error in fetching filter options.');
  }
};

export const AssignConsultantGetRequest = async (queryParams, isLoading) => {
  try {
    const res = await handleGetDataFromQueryParamsApi(
      siteConfig.ASSIGN_CONSULTANT_LISTING,
      queryParams,
      isLoading
    );
    return res;
  } catch (error) {
    console.log('Error in Fetching the List View', error);
  }
};

export const assignConsultantPatchRequest = async (leadId, consultantId) => {
  try {
    const res = await handlePatchDataFromApi(
      `${siteConfig.LISTING_LEADS + leadId}/assign-consultant/`,
      { consultant_id: consultantId }
    );
    return res;
  } catch (error) {
    console.log('Error in patch request of Assign consultant ', error);
  }
};

export const getListDetailsGetRequest = async (leadId, isLoading) => {
  try {
    const res = await handleGetDataFromApi(`${siteConfig.LISTING_LEADS + leadId}/`);
    return res;
  } catch (error) {
    console.error('Error in fetching filter options.');
  }
};

export const getListNotificationread = async (id, isLoading) => {
  try {
    const res = await handleGetDataFromApi(`${siteConfig.NOTIFICATION_READ}/${id}`);

    if(res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API){
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error("Error in fetching filter options.");
  }
};

export const UsersListViewGetRequest = async (queryParams, isLoading) => {
  const url = `${siteConfig.USERS}?${objectToQueryString(queryParams)}`;
  try {
    const res = await handleGetDataFromApi(url, isLoading);
    return res;
  } catch (error) {
    console.log('Error in Fetching the Users List', error);
  }
};

export const getEnergyConsultantGetRequest = async (queryParams) => {
  const url = `${siteConfig.ENERGY_CONSULTANT}?${objectToQueryString(queryParams)}`;
  try {
    const res = await handleGetDataFromApi(url);
    return res;
  } catch (error) {
    console.error('Error in fetching the energy consultant');
  }
};

export const createEnergyConsultantPostRequest = async (body, id) => {
  try {
    const url = id ? `${siteConfig.ENERGY_CONSULTANT}${id}/` : siteConfig.ENERGY_CONSULTANT;

    // Use a single API function for both POST and PATCH requests
    const apiFunction = id ? handlePatchDataFromApi : handlePostDataFromApi;

    const res = await apiFunction(url, body);

    return res;
  } catch (error) {
    console.error('Error in creating the energy consultant');
  }
};

export const createUserPostRequest = async (body) => {
  try {
    const res = await handlePostDataFromApi(siteConfig.USERS, body);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_POST_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in creating user');
  }
};

export const editUserPatchRequest = async (userId, body) => {
  const url = `${siteConfig.USERS}${userId}/`;
  try {
    const res = await handlePatchDataFromApi(url, body);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_PATCH_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in creating user');
  }
};

export const changeUserActiveStatusPatchRequest = async (userId, body) => {
  const url = siteConfig.USER_ACTIVITY_STATUS.replace('<user-id>', userId);
  try {
    const res = await handlePatchDataFromApi(url, body);
    return res;
  } catch (error) {
    console.error('Error in creating user');
  }
};

export const deleteUserDeleteRequest = async (userId, isLeadCard) => {
  const endpoint = isLeadCard ? siteConfig.DELETE_LEAD : siteConfig.SINGLE_USERS;
  const url = `${endpoint.replace('<lead_id>', userId).replace('<user-id>', userId)}`;

  try {
    return await handleDeleteDataFromApi(url);
  } catch (error) {
    console.error('Error in deleting user', error);
    throw error;
  }
};

export const DownloadSampleFileRequest = async (isLoading) => {
  try {
    const res = await handleGetDataForFile(siteConfig.EXPORT_FILE, isLoading);
    if (res?.status !== 200) {
      toastError(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in Fetching data:', error);
  }
};

export const exportConsultantInFileRequest = async (queryParams, isLoading) => {
  try {
    const res = await handleGetDataForFile(
      `${siteConfig.ENERGY_CONSULTANT_EXPORT}?${objectToQueryString(queryParams)}`
    );
    if (res?.status !== 200) {
      toastError(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in Fetching data:', error);
  }
};

export const sampleExportConsultantFileRequest = async (isLoading) => {
  try {
    const res = await handleGetDataForFile(siteConfig.SAMPLE_IMPORT_CONSULTANT, isLoading);
    if (res?.status !== 200) {
      toastError(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in Fetching data:', error);
  }
};

export const sampleExportLeadFileRequest = async (isLoading) => {
  try {
    const res = await handleGetDataForFile(siteConfig.SAMPLE_IMPORT_LEADS, isLoading);
    if (res?.status !== 200) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in Fetching data:', error);
  }
};
export const handleImportLeadFilesRequest = async (file, isLoading) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const res = await handlePostDataFromApi(siteConfig?.IMPORT_LEADS_FILE, formData, isLoading);

    if (res?.status !== ENUM_API_STATUS?.SUCCESS_POST_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in uploading file:', error);
    toastError('An error occurred during file upload');
  }
};

export const handleImportConsultantFilesRequest = async (file, isLoading) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const res = await handlePostDataFromApi(
      siteConfig?.IMPORT_CONSULTANT_FILE,
      formData,
      isLoading
    );

    if (res?.status !== ENUM_API_STATUS?.SUCCESS_POST_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in uploading file:', error);
    toastError('An error occurred during file upload');
  }
};
export const deleteEnergyConsultantRequest = async (id) => {
  try {
    const res = await handleDeleteDataFromApi(`${siteConfig?.ENERGY_CONSULTANT}${id}/`);

    return res;
  } catch (error) {
    console.error('Error in creating the energy consultant');
  }
};

export const tagsGetRequest = async (queryParams) => {
  const url = `${siteConfig.TAG_LIST}?${objectToQueryString(queryParams)}`;
  try {
    const res = await handleGetDataFromApi(url);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in fetching tags:', error);
  }
};
export const createNewTagRequest = async (body) => {
  try {
    const res = await handlePostDataFromApi(siteConfig?.TAG_LIST, body);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_POST_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in Creating new tag', error);
  }
};

export const deleteTagRequest = async (id) => {
  try {
    const res = await handleDeleteDataFromApi(`${siteConfig?.TAG_LIST}${id}/`);

    return res;
  } catch (error) {
    console.error('Error in creating the energy consultant');
  }
};

export const edittagRequest = async (id, body) => {
  try {
    const res = await handlePatchDataFromApi(`${siteConfig?.TAG_LIST}${id}/`, body);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_PATCH_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in creating the energy consultant');
  }
};

export const tagsAllGetRequest = async () => {
  try {
    const res = await handleGetDataFromApi(siteConfig?.TAG_ALL_LIST);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_GET_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in fetching tags:', error);
  }
};

export const DistributionPartnerViewGetRequest = async (queryParams, isLoading) => {
  const url = `${siteConfig.DISTRIBUTION_PARTNER}?${objectToQueryString(queryParams)}`;
  try {
    const res = await handleGetDataFromApi(url, isLoading);
    return res;
  } catch (error) {
    console.log('Error in Fetching the Users List', error);
  }
};

export const createDistributionPartnerPostRequest = async (body) => {
  try {
    const res = await handlePostDataFromApi(siteConfig.DISTRIBUTION_PARTNER, body);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_POST_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in creating user');
  }
};

export const deleteDistributionPartnerRequest = async (id) => {
  try {
    const res = await handleDeleteDataFromApi(`${siteConfig?.DISTRIBUTION_PARTNER}${id}/`);
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_DELETE_API) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in DELETE api of DP User');
  }
};

export const resendPostRequest = async (id) => {
  try {
    const res = await handlePostDataFromApi(
      `${siteConfig.DISTRIBUTION_PARTNER + id}/resend-invitation/`
    );
    if (res?.status !== ENUM_API_STATUS?.SUCCESS_POST_API_200) {
      toastErrors(res?.data);
    }
    return res;
  } catch (error) {
    console.error('Error in creating user');
  }
};

export const downloadFile = async (fileUrl) => {
  try {
    const response = await ApiService.get(fileUrl, {});

    return new Blob([response.data], { type: response.data.type });
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error; // Re-throw for handling in calling code
  }
};
