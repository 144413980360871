const FileIcon = () => {
    return (
        <>
            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.24949 5.16211V2.91211C5.24949 2.08368 5.92107 1.41211 6.74949 1.41211H20.9995C21.8279 1.41211 22.4995 2.08368 22.4995 2.91211V8.91211M1.50067 22.5884L1.50078 11.183C1.50079 10.0406 1.50036 8.4134 1.5 7.23521C1.49974 6.40656 2.17141 5.7356 3.00006 5.7356H10.1478L13.6041 9.42764H23.9995C24.8279 9.42764 25.4995 10.0992 25.4995 10.9277L25.4991 22.5885C25.499 24.2453 24.1559 25.5884 22.4991 25.5884L4.50066 25.5884C2.8438 25.5884 1.50065 24.2452 1.50067 22.5884Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default FileIcon;